@import "../../Styles";

.popup {
  color: $BLACK;
  display: flex;
  font-family: "Biotif";
  height: 100vh;
  position: absolute;
  text-align: center;

  &__container {
    bottom: 0;
    flex-direction: column;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 998;

    &--relative {
      position: relative;
    }
  }

  &__container--overlay {
    -webkit-backdrop-filter: blur(0.3rem);
    backdrop-filter: blur(0.3rem);
    background: rgba(255, 255, 255, 0.7);
    bottom: 0;
    flex-direction: column;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 998;

    @media (max-height: $SCREEN_SIZE_M) {
      bottom: 0;
      overflow-x: hidden;
      overflow-y: scroll;
      top: 0;
    }
  }

  &__container--overlay-pitch {
    -webkit-backdrop-filter: blur(0.3rem);
    backdrop-filter: blur(0.3rem);
    background: rgba(255, 255, 255, 0.7);
    bottom: 0;
    flex-direction: column;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 998;

    @media (max-height: $SCREEN_SIZE_M) {
      bottom: 0;
      overflow: hidden;
      top: 0;
    }
  }

  &__elements-container {
    background-color: $WHITE;
    border-radius: $BORDER_M;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 32rem;
    opacity: 0;
    padding: $SPACING_4XL $SPACING_4XL;
    position: relative;
    top: 55%;
    transform: translateY(-55%);
    transition: all $TRANSITION_MED;

    @include themify($themes) {
      box-shadow: themed('heavyShadow');
    }

    &--less-width {
      margin: auto;
      top: 55%;
      transform: translateY(-55%);
      width: fit-content;

      &-visible {
        opacity: 1;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--more-width {
      max-width: 55rem;
    }

    &--overflow-scrol {
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background-color: #dadada;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3a3a6e;
        border-radius: 100px;
      }
    }

    &--less-height {
      max-width: 26rem;
      padding-bottom: $SPACING_M;
      padding-top: $SPACING_XL;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      max-width: initial;

      &--less-width {
        margin: auto;
      }
    }

    @media (max-width: $SCREEN_SIZE_XS) {
      padding: $SPACING_L;

      &--less-height {
        padding-bottom: $SPACING_M;
      }
    }

    &-visible {
      opacity: $OPACITY_HIGH;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__close-icon {
    cursor: $CURSOR_POINTER_MEGOSU_3;

    padding: $SPACING_L $SPACING_L 0 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
  }

  .button--black {
    background-color: $BLACK !important;
    color: $WHITE !important;

    &:hover {
      background-color: $WHITE !important;
      border: 1px $BLACK solid !important;
      color: $BLACK !important;
    }
  }

  .button--white {
    background-color: $WHITE !important;
    border: 1px $BLACK solid !important;
    color: $BLACK !important;

    &:hover {
      background-color: $BLACK !important;
      color: $WHITE !important;
    }
  }

  .login-form__social-login {
    > span {
      background: $WHITE;
      color: $GRAY_LIGHT;
    }
  }

  .registration-form__social-login {
    > span {
      background: $WHITE;
      color: $GRAY_LIGHT;
    }
  }
}

.popup-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  &--less-width {
    position: relative;
    width: fit-content;
  }

  &__social-grid-container {
    display: grid;
    grid-template-columns: 5rem 5rem 5rem 5rem;
    justify-content: center;

    > a {
      > img {
        height: 2.2rem;
        width: 2.2rem;
      }
    }
  }

  &__social-copy-link {
    align-items: center;
    font-size: $TEXT_S;
    font-weight: $LIGHT;
    justify-content: center;
    margin-bottom: $SPACING_M;
    margin-top: 1.5rem;
    text-align: center;
  }

  &__social-url-container {
    width: 50%;
  }

  &__feature-image {
    margin-bottom: $SPACING_L;
    width: 12rem;

    &--hidden {
      display: none;
    }
  }

  &__title {
    font-size: $TEXT_3XL;
    font-weight: $REGULAR;
    margin: 0 auto 0 auto;
    max-width: $WIDTH_L;
    white-space: pre-line;

    @media (max-width: $SCREEN_SIZE_S) {
      margin: $SPACING_M auto $SPACING_XXS auto;
    }
  }

  &__subtitle {
    font-size: $TEXT_XL;
    font-weight: $MEDIUM;
    margin: $SPACING_M auto $SPACING_L auto;
    max-width: $WIDTH_L;
  }

  &__user-details {
    &-grid {
      display: flex;
      flex-direction: row;
      gap: $SPACING_4XL;
      text-align: left;

      @media (max-width: $SCREEN_SIZE_S) {
        flex-direction: column;
        gap: $SPACING_M;
      }
    }

    &-subtitle {
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      margin-top: $SPACING_M;
      text-align: left;
    }

    &-break-line {
      background-color: $GRAY_LIGHTER;
      border: none;
      height: 1px;
    }
  }

  &__user-details-title {
    font-size: $TEXT_XL;
    font-weight: $MEDIUM;
    margin: 0;
    max-width: $WIDTH_L;
  }

  &__user-details-text {
    bottom: $SPACING_L;
    font-size: $TEXT_XL;
    font-weight: $REGULAR;
    line-height: $SPACING_M;
    margin: $SPACING_3XL auto 0 auto;
    max-width: 100%;
  }

  &__text {
    font-size: $TEXT_L;
    line-height: $SPACING_M;
    margin: $SPACING_M auto 0 auto;
    max-width: 25rem;

    &-left {
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      line-height: $SPACING_XL;
      margin: $SPACING_M auto 0 auto;
      max-width: 25rem;
      text-align: left;
    }

    &-light {
      font-size: $TEXT_L;
      font-weight: $LIGHT;
      line-height: $SPACING_M;
      margin: $SPACING_XXL auto 0 auto;
      max-width: 25rem;
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: $SPACING_L;

    button {
      font-size: $TEXT_L;
      padding-left: $SPACING_L;
      padding-right: $SPACING_L;

      &:first-of-type {
        margin-right: $SPACING_XXS;

        @media (max-width: $SCREEN_SIZE_XS) {
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-left: $SPACING_XXS;

        @media (max-width: $SCREEN_SIZE_XS) {
          margin-bottom: $SPACING_XXS;
          margin-left: 0;
        }
      }
    }

    @media (max-width: $SCREEN_SIZE_XS) {
      flex-direction: column-reverse;
      width: 15rem;
    }
  }

  &__user-details-container {
    padding: $SPACING_M $SPACING_XXS;
  }

  &__user-details-list {
    margin-top: $SPACING_M;
    max-height: 25rem;
    overflow-y: scroll;

    &--no-scroll {
      max-height: 15rem;
      overflow-y: hidden;
    }
  }

  &__user-details-fade-out {
    background: white;
    background: linear-gradient(180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 1) 75%);
    bottom: $SPACING_XXL;
    display: flex;
    flex-direction: column;
    height: 8.95rem;
    position: relative;
    width: 100%;
  }
}

.popup-community {
  display: flex;

  @media (max-width: $SCREEN_SIZE_S) {
    flex-direction: column-reverse;
    justify-content: center;
  }

  &__container {
    flex: 1;
    flex-shrink: 3;

    @media (max-width: $SCREEN_SIZE_S) {
      max-height: 20rem;
    }
  }

  &__testimonial {
    display: flex;
    justify-content: flex-start;
    margin-right: $SPACING_XL;
    margin-top: $SPACING_L;

    @media (max-width: $SCREEN_SIZE_S) {
      margin-top: $SPACING_XS;
    }
  }

  &__user-image {
    border-radius: 50%;
    max-height: $SPACING_4XL;
    max-width: $SPACING_4XL;

    @media (max-width: $SCREEN_SIZE_S) {
      max-height: $SPACING_3XL;
      max-width: $SPACING_3XL;
    }
  }

  &__testimonial-text-block {
    display: inline;
    margin-left: $SPACING_L;
    overflow-wrap: break-word;
    text-align: left;
  }

  &__name-title {
    color: $THEME_PINK;
    font-size: $TEXT_M;
    font-weight: $LIGHT;

    @media (max-width: $SCREEN_SIZE_S) {
      font-size: $TEXT_S;
    }
  }

  &__paragraph {
    font-size: $TEXT_M;
    font-weight: $REGULAR;
    line-height: 1.25rem;

    @media (max-width: $SCREEN_SIZE_S) {
      font-size: $TEXT_S;
      line-height: $SPACING_M;
    }
  }

  &__divider {
    border-left: 1px solid $GRAY_LIGHT;
    height: 25rem;
    line-height: 0.1em;
    text-align: center;

    @media (max-width: $SCREEN_SIZE_S) {
      border-left: none;
      border-top: 1px solid $GRAY_LIGHT;
      height: $SPACING_M;
      margin-left: 0;
    }
  }

  &__join-title {
    font-size: $TEXT_XXL;
    margin-top: $SPACING_M;
    text-align: center;
  }

  &__list-container {
    display: flex;
    justify-content: center;
  }

  &__list {
    margin-block-end: 0;
    margin-block-start: 0;
    margin-left: $SPACING_XL;
    padding-inline-start: 0;
    text-align: left;

    > li {
      font-size: $TEXT_L;
      margin: $SPACING_M 0;
      white-space: nowrap;
    }

    > li:first-of-type {
      margin: 0;
    }

    > li:before {
      color: $BLACK;
      content: "•";
      display: inline-block;
      width: 1.5rem;
    }
  }

  &__pro-button {
    margin-top: $SPACING_XL;
    padding: $SPACING_XS $SPACING_4XL $SPACING_XS $SPACING_4XL;

    @media (max-width: $SCREEN_SIZE_S) {
      margin-top: $SPACING_S;
      padding: $SPACING_XS $SPACING_3XL $SPACING_XS $SPACING_3XL;
    }
  }

  &__pro-price {
    color: $THEME_PURPLE;
    font-size: $TEXT_L;
  }
}

.popup-company-contact {
  display: flex;

  @media (max-width: $SCREEN_SIZE_XS) {
    max-height: 90vmax;
    overflow-y: scroll;
  }

  &__container {
    flex: 1;
    flex-shrink: 3;
    width: 100%;
  }

  &__title-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-bottom: 0;

    @media (min-width: $SCREEN_SIZE_M) {
      flex-direction: row;
    }
  }

  &__title {
    font-size: $TEXT_XXL;
    margin-bottom: 0;

    @media (max-width: $SCREEN_SIZE_S) {
      text-align: left;
    }
  }

  &__date {
    font-size: $TEXT_S;
    font-weight: $LIGHT;
    line-height: $SPACING_L;
    margin-bottom: 0;
    margin-top: 0;
  }

  &__subtitle-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin-top: 0;
  }

  &__subtitle {
    font-size: $TEXT_L;
    font-weight: $LIGHT;
    margin-top: $SPACING_XXS;

    &:hover {
      cursor: $CURSOR_POINTER_MEGOSU_3;
    }
  }

  &__content-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: $SPACING_M;
  }

  &__content-title {
    font-size: $TEXT_L;
    font-weight: $REGULAR;
    margin-top: $SPACING_XXS;
  }

  &__inputs-container {
    align-items: center;
    display: grid;
    gap: $SPACING_XL;
    grid-template-columns: 1fr 1fr;
    margin-top: $SPACING_M;
    width: 100%;

    @media (max-width: $SCREEN_SIZE_M) {
      gap: $SPACING_XS;
      grid-template-columns: 1fr;
    }

    @media (max-width: $SCREEN_SIZE_XS) {
      max-width: 90%;
    }
  }

  &__person-box {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  &__icons-container {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    gap: $SPACING_S;
    margin-top: $SPACING_M;
    position: absolute;
  }

  &__input-image {
    height: 1.2rem;
    pointer-events: all;

    &:hover {
      cursor: $CURSOR_POINTER_MEGOSU_3;
    }
  }

  &__input-container {
    background-color: $GRAY_LIGHTEST;
    border: none;
    border-radius: $SPACING_XXS;
    outline: none;
    padding: $SPACING_S $SPACING_XXS;
    width: 100%;
  }

  &__input-text {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    letter-spacing: 0.01rem;
    margin: 0;
    text-align: left;

    @media (max-width: $SCREEN_SIZE_XS) {
      max-width: 10.5rem;
    }
  }

  &__notes {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    margin-bottom: $SPACING_L;
    margin-top: $SPACING_XXS;
    text-align: left;
    word-break: break-word;

    @media (max-width: $SCREEN_SIZE_M) {
      font-size: $TEXT_S;
    }
  }

  &__button-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    @media (max-width: $SCREEN_SIZE_M) {
      align-items: center;
    }
  }

  &__button {
    font-size: $TEXT_M;
    padding: $SPACING_XS $SPACING_L $SPACING_XS $SPACING_L;
  }
}