@import "../../../styles/Styles";

.project-card {
  &__content-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: $SPACING_M;
    justify-content: space-between;
    padding: $SPACING_S $SPACING_XXS;
    width: 100%;
  }

  &__archived {
    opacity: 0.5;
  }

  &__img-modal-container--admin {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__img-container--admin {
    background-color: white;
    display: flex;
    height: 100%;
    width: 100%;

    :hover {
      background-color: gray;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }
  }

  &__img-container {
    background-color: $GRAY_LIGHTEST;
    border-radius: $BORDER_M;

    padding-bottom: 114%;
    position: relative;
    width: 100%;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }
  }

  &__admin-verify-container {
    justify-content: flex-end;
    width: 100%;
  }

  &__img,
  &__img-hover {
    border-radius: $BORDER_M;

    color: transparent;
    height: 100%;
    object-fit: cover;
    position: absolute;
    transition: 0.2s;
    width: 100%;

    @include themify($themes) {
      box-shadow: 0 0 0.6rem 0 themed('boxShadowColor');
    }
  }

  &__img-hover {
    opacity: 0;
    transition: opacity 0.3s;
    transition-delay: 0.1s;

    &:hover {
      opacity: $OPACITY_HIGH;
    }
  }

  &__img-menu-open {
    cursor: default;
    filter: brightness(50%);
    transition: 0.2s;
    z-index: 1;
  }

  &__designer {
    bottom: 0;
    display: flex;
    height: 4.25rem;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &__designer::after {
    background: linear-gradient(180deg,
    rgba(41, 41, 41, 0) 7.5%,
    rgba(41, 41, 41, 0.2) 100%);
    border-radius: 0 0 $BORDER_S $BORDER_S;
    bottom: 0;
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.05);
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  &__user-name {
    bottom: -0.4rem;
    color: $WHITE;
    font-size: $TEXT_M;
    position: relative;
  }

  &__user-image-container {
    background-position: center center;
    background-size: cover;
    border: 1px solid #292929;
    border-radius: 100%;
    bottom: -0.2rem;

    height: 1.8rem;
    margin: auto $SPACING_XS auto 0.75rem;
    position: relative;
    width: 1.8rem;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }
  }

  &__title {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    font-size: $TEXT_M;
    font-weight: $REGULAR;
    margin-bottom: auto;
    margin-top: $SPACING_3XS;
    word-break: break-word;

    @include themify($themes) {
      color: themed('colorText');
    }

    @media (max-width: $SCREEN_SIZE_XS) {
      font-size: $TEXT_S;
    }
  }

  &__favorite-container {
    display: flex;
    flex-grow: 2;
    margin-left: $SPACING_XXS;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &__attributes-container {
    display: flex;
    flex-grow: 1;
    margin-bottom: auto;

    @media (max-width: $SCREEN_SIZE_XS) {
      margin-bottom: 0;
    }
  }

  &__boosted {
    background-color: $GRAY_LIGHTER;
    border-radius: $BORDER_S;
    font-size: $TEXT_XS;
    font-weight: bold;
    margin-bottom: auto;
    margin-left: $SPACING_XXS;
    margin-top: $SPACING_3XS;
    padding: $SPACING_3XS $SPACING_XXS;
    transition: $TRANSITION_MED;

    @include themify($themes) {
      background-color: themed('colorBoostedLabel');
      color: $WHITE;
    }

    &:hover {
      background-color: $BLACK;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }
  }

  &__description-container {
    align-items: center;
    display: flex;
  }

  &__modal {
    align-items: center;
    background-color: $GRAY_LIGHTEST_TRANSPARENT;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &__save-btn-container {
    align-items: center;
    display: flex;
    margin-left: auto;

    @media (max-width: $SCREEN_SIZE_XS) {
      margin-bottom: auto;
      margin-top: unset;
    }
  }

  &__bookmark,
  &__bookmark--filled {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    margin-top: auto;
    width: 0.8rem;

    .bookmark-filled-icon {
      color: $THEME_PINK;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  &__save-count {
    font-weight: $LIGHT;
    margin-bottom: auto;
    margin-right: $SPACING_3XS;
    margin-top: auto;
  }

  &__bookmark--filled {
    display: none;
    width: 0.8rem;
  }

  > a {
    text-align: initial;
  }

  &__img-btn-container {
    border: none;
    border-radius: $SPACING_XXS;

    display: grid;
    grid-auto-flow: row dense;
    grid-gap: 0.625rem;
    grid-template-columns: 1fr;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    > Button {
      padding: $SPACING_XXS $SPACING_XXL $SPACING_XXS $SPACING_XXL;
    }
  }

  &__img-dot-items {
    display: flex;
    height: 2.5rem;
    padding-bottom: 40rem;
    position: absolute;
    right: $SPACING_XXS;
    width: 2.5rem;
    z-index: 1;
  }

  &__img-menu-dots {
    align-self: center;
    color: white;
    display: flex;
    height: 1.75rem;
    justify-content: flex-end;
    margin: 0 auto;
    width: 1.75rem;
  }

  &__link {
    color: $BLACK;
    text-align: initial;
    text-decoration: none;
  }
}

.reveal {
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  transition: 0.5s all ease;
}

.reveal.active {
  opacity: $OPACITY_HIGH;
  transform: translateY(0);
}