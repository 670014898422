@import "../../../styles/Styles";

.toggle {
  display: flex;
  width: auto;

  &__switch {
    display: inline-block;
    height: 1rem;
    margin-bottom: 0;
    position: relative;
    width: 2rem;

    > input {
      display: none;

      &:checked + .toggle__slider {
        background-color: $THEME_PINK;

        &:hover {
          opacity: 0.75;
        }
      }

      &:checked + .toggle__slider--round {
        background-color: $THEME_PINK;

        &:hover {
          opacity: 0.75;
        }
      }

      &:checked + .toggle__slider:before {
        -webkit-transform: translateX(0.8rem);
        -ms-transform: translateX(0.8rem);
        transform: translateX(0.8rem);
      }
    }
  }

  // cookies
  &__slider {
    background-color: $GRAY_LIGHTEST;
    bottom: 0;

    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: $TRANSITION_MED;
    transition: $TRANSITION_MED;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }
  }

  &__slider:hover {
    background-color: $GRAY_LIGHTER;
  }

  &__slider:before {
    @include themify($themes) {
      background-color: themed('sliderColor');
    }

    box-shadow: $SHADOW_HEAVY;
    content: "";
    height: 75%;
    left: 0.2rem;
    position: absolute;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 40%;
  }

  &__slider--round {
    border-radius: $BORDER_XL;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__slider--round:before {
    border-radius: $BORDER_XL;
  }
}