@import "src/styles/Styles";

.navbar {
  background-color: $GRAY_LIGHTEST;
  flex-direction: column;
  height: calc(100vh - 114px);
  margin-top: 0.06rem;
  overflow-y: auto;
  padding: 2.8rem;
  position: fixed;
  right: 0;
  transition: all 250ms;
  width: 13rem;
  z-index: 4;

  @include themify($themes) {
    background: themed('navBarBackground');
    color: themed('colorText');

    @media (max-width: $SCREEN_SIZE_S) {
      background: themed('hoverBackgroundColor');
    }
  }

  &--transform-up {
    height: calc(100vh - 150px);
  }

  @media (max-width: $SCREEN_SIZE_S) {
    padding: $SPACING_L 0 $SPACING_L 0;
    position: fixed;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;

    @media (max-width: $SCREEN_SIZE_S) {
      overflow-y: scroll;
      padding-left: $SPACING_4XL;
      padding-right: $SPACING_4XL;
    }
  }

  &__img-container {
    margin-bottom: $SPACING_3XS;
    margin-left: $SPACING_XL;
    padding: $SPACING_M 0;
    width: 100%;
  }

  &__feature-img {
    height: 9rem;
    width: 9rem;

    @media (max-width: $SCREEN_SIZE_S) {
      height: 11rem;
      width: 11rem;
    }
  }

  &__list {
    font-weight: $MEDIUM;
    padding-top: $SPACING_M;

    &-item {
      display: flex;
      justify-content: space-between;
      margin: $SPACING_3XS 0;
      padding: $SPACING_3XS 0;

      .form__social-menu-boosted {
        font-size: $TEXT_XXS;
        margin-bottom: 0;
        margin-left: $SPACING_XXS;
        margin-top: 0.25rem;

        @include themify($themes) {
          background: themed('colorBoostedLabel');
        }
      }

      @media (max-width: $SCREEN_SIZE_S) {
        padding: $SPACING_XXS 0;
      }

      &:last-of-type {
        @media (max-width: $SCREEN_SIZE_S) {
          margin-bottom: $SPACING_XXL;
        }
      }

      &-top {
        font-size: $TEXT_XL;

        @include themify($themes) {
          color: themed('colorText');
        }

        @media (max-width: $SCREEN_SIZE_S) {
          font-size: $TEXT_XXL;
        }
      }

      &-bottom {
        font-size: $TEXT_L;

        @include themify($themes) {
          color: themed('colorText');
        }

        @media (max-width: $SCREEN_SIZE_S) {
          font-size: $TEXT_XL;
        }
      }

      &-bottom-first {
        margin-top: $SPACING_XL;
      }

      > p {
        font-size: $TEXT_L;
        line-height: inherit;
        margin: 0;

        @include themify($themes) {
          color: themed('colorText');
        }

        @media (max-width: $SCREEN_SIZE_S) {
          font-size: $TEXT_XL;
        }
      }
    }

    > li {
      > a {
        align-items: center;
        display: flex;
        -webkit-text-size-adjust: 100%;
        /* Mobile: prevent font scaling in landscape while allowing user zoom */

        > div {
          display: flex;

          > svg {
            color: $THEME_PINK;
            font-size: $TEXT_L;
            padding: 0 $SPACING_XXS;
            padding-top: 0.25rem;
          }
        }
      }
    }
  }

  &__list-item:hover:after,
  &__item-static-arrow:after {
    color: $THEME_PINK;
    content: "\2192";
    display: flex;
    font-size: $TEXT_L;
    right: 0;
    width: 3rem;
  }

  &__logout {
    color: $BLACK;

    font-weight: $MEDIUM;
  }

  &__profile {
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &__buttons {
    bottom: $SPACING_XL;
    margin-bottom: $SPACING_M;
    width: 12rem;

    @media (max-width: $SCREEN_SIZE_S) {
      margin: 0 auto $SPACING_XXL auto;
      position: static;
      width: 100%;
    }
  }
}