@import "../../Styles";

@import "src/styles/Styles";

.how-it-works-testimonials {
  align-self: center;
  max-width: 75rem;
  padding-top: 4rem;
  text-align: center;

  & > div {
    display: grid;
    grid-gap: $SPACING_L;
    grid-template-columns: 1fr 1fr 1fr;

    @media (min-width: $SCREEN_SIZE_S) and (max-width: $SCREEN_SIZE_L) {
      display: grid;
      grid-gap: $SPACING_L;
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      display: block;
    }
  }

  &__review-title {
    font-size: $TEXT_3XL;
    margin: auto;
    padding-bottom: $SPACING_M;
    width: 25rem;

    @media (max-width: $SCREEN_SIZE_XS) {
      width: initial;
    }
  }

  &__reviews {
    margin-top: $SPACING_XL;
  }

  &__review-card {
    background-color: $WHITE;
    border-radius: $BORDER_L;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $SPACING_L $SPACING_M $SPACING_L $SPACING_M;

    @include themify($themes) {
      box-shadow: themed('heavyShadow');
    }

    @media (max-width: $SCREEN_SIZE_S) {
      margin: 0 auto;
      width: 25rem;

      &:not(:first-of-type) {
        margin-top: $SPACING_L;
      }
    }

    @media (max-width: $SCREEN_SIZE_L) {
      width: 17rem;
    }

    @media screen and (max-width: $SCREEN_SIZE_XS) {
      width: auto;
    }

    & > div {
      display: flex;
      justify-content: center;
    }
  }

  &__review-text {
    font-weight: $REGULAR;
  }

  &__review-image {
    border: 2px solid #292929;
    border-radius: 100%;
    height: 3.5rem;
    object-fit: cover;
    width: 3.5rem;
  }

  &__review-job-container {
    align-items: flex-start;
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-left: $SPACING_M;
  }

  &__review-name {
    font-size: $TEXT_M;
    font-weight: $REGULAR;
    padding-bottom: $SPACING_3XS;
  }

  &__review-job-title {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    line-height: $SPACING_M;
    margin: 0;
    text-align: left;
  }
}