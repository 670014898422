// Config
@import "../styles/config/Colors";
@import "../styles/config/Sizes";
@import "../styles/config/Spacing";
@import "../styles/config/Fonts";
@import "../styles/config/Misc";
@import "../styles/config/Cursors.scss";

img {
  pointer-events: none;
}

$themes: (
  light: (colorHeaderFooter: $WHITE,
  colorText: $BLACK,
  colorBackground: $WHITE,
  invertLogoColor: invert(0),
  overlayColor: $WHITE,
  boxShadowColor: $GRAY_LIGHTEST,
  colorBoostedLabel: $GRAY_LIGHTER,
  boxShadowLightColor: $SHADOW_LIGHT,
  sliderColor: $WHITE,
  dropdownOverlay: $WHITE,
  textGray: $GRAY,
  labelColor: $GRAY_LIGHTEST,
  navBarBackground: $GRAY_LIGHTEST,
  cursorMegosu1: $CURSOR_MEGOSU_1,
  cursorMegosu0: $CURSOR_MEGOSU_0,
  cursorPointerMegosu3: $CURSOR_POINTER_MEGOSU_3,
  cursorPointerMegosu2: $CURSOR_POINTER_MEGOSU_2,
  cursorPointerMegosu1: $CURSOR_POINTER_MEGOSU_1,
  cursorPointerMegosu0: $CURSOR_POINTER_MEGOSU_0,
  notificationBarColor: $BLACK,
  hoverBackgroundColor: $GRAY_LIGHTEST,
  componentShadow: $SHADOW_LIGHT,
  selectedItemColor: $GRAY,
  heavyShadow: $SHADOW_HEAVY,
  sliderPosition: 3px,
  backDrop: rgb(255, 255, 250, 0.8),
),
dark: (colorHeaderFooter: $BLACK,
colorText: $WHITE,
colorBackground: #292929,
invertLogoColor: invert(1),
overlayColor: #5a5a5a,
boxShadowColor: none,
colorBoostedLabel: $THEME_PINK,
boxShadowLightColor: 0 0 6px 0 rgb(146, 146, 146),
sliderColor: $BLACK,
dropdownOverlay: #a3a3a3,
textGray: $WHITE,
labelColor: $BLACK,
navBarBackground: #5a5a5a,
cursorMegosu1: $CURSOR_MEGOSU_WHITE_1,
cursorMegosu0: $CURSOR_MEGOSU_WHITE_0,
cursorPointerMegosu3: $CURSOR_POINTER_MEGOSU_WHITE_3,
cursorPointerMegosu2: $CURSOR_POINTER_MEGOSU_WHITE_2,
cursorPointerMegosu1: $CURSOR_POINTER_MEGOSU_WHITE_1,
cursorPointerMegosu0: $CURSOR_POINTER_MEGOSU_WHITE_0,
notificationBarColor: #5a5a5a,
hoverBackgroundColor: $BLACK,
componentShadow: none,
selectedItemColor: $THEME_PINK,
heavyShadow: none,
sliderPosition: 5px,
backDrop: rgba(95, 95, 95, 0.8),
),
);

@mixin themify($themes) {

  @each $theme,
    $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key,
        $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map,
        ($key: $value,
      )) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

.theme-dark,
html,
body {
  cursor: $CURSOR_MEGOSU_WHITE_1;
}

.theme-light,
html,
body {
  cursor: $CURSOR_MEGOSU_1;
}