@import "src/styles/Styles";

.subscription-card {
  border: 2px solid $WHITE;
  border-radius: $BORDER_L;

  display: flex;
  flex-direction: column;
  height: 32rem;
  margin-top: $SPACING_XL;
  padding: $SPACING_L $SPACING_M $SPACING_M $SPACING_M;
  text-align: center;
  transition: border-color $TRANSITION_MED;

  @include themify($themes) {
    box-shadow: themed('heavyShadow');
  }

  @include themify($themes) {
    cursor: themed('cursorPointerMegosu3');
  }

  @media (min-width: $SCREEN_SIZE_XS) {
    padding: $SPACING_L $SPACING_L $SPACING_M $SPACING_L;
  }

  @media (min-width: $SCREEN_SIZE_L) and (max-width: $SCREEN_SIZE_XL) {
    padding: $SPACING_L $SPACING_S $SPACING_M $SPACING_S;
  }

  &:hover {
    border-color: $BLACK;
  }

  h4 {
    font-weight: $MEDIUM;
  }

  h5 {
    font-size: 1.8rem;
    line-height: $SPACING_3XL;
    padding-bottom: 0.1rem;
  }

  h6 {
    font-size: $TEXT_M;
    padding-bottom: $SPACING_M;
  }

  &__subtitle {
    bottom: $SPACING_M;
    font-size: $TEXT_L;
    font-weight: $MEDIUM;
    padding-bottom: $SPACING_S;
    position: relative;
  }

  @media (max-width: $SCREEN_SIZE_L) {
    margin: 0 auto;
    width: $WIDTH_M;
  }

  @media (max-width: $SCREEN_SIZE_XS) {
    width: auto;
  }

  &--active {
    border-color: $BLACK;
  }

  &--recommended {
    border-color: $THEME_PINK;
    height: 34rem;
    margin-top: 0;
  }

  &__free-card {
    height: 27rem;
    margin-top: 7rem;

    @media (max-width: $SCREEN_SIZE_M) {
      margin-top: 0;
    }
  }

  &--pending {
    border-color: $ORANGE;
  }

  &__title-pro {
    font-size: $TEXT_3XL;
    margin-top: $SPACING_M;
    text-transform: capitalize;
  }

  &__title {
    font-size: $TEXT_3XL;
    margin: 1.35rem 0 0 0;
    text-transform: capitalize;
  }

  &__description {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    line-height: $SPACING_M;
    margin: 0;
    padding-top: $SPACING_S;

    &--no-padding-top {
      padding-top: 0;
    }
  }

  &__price {
    font-size: 3rem;
    font-weight: $MEDIUM;
    margin-bottom: 0;
    padding: $SPACING_M 0;

    span {
      font-weight: $REGULAR;
    }

    h4 {
      margin-bottom: 0;
    }
  }

  &__price--business {
    margin-bottom: $SPACING_XXS;
    padding: $SPACING_L 0;
  }

  li span {
    left: $SPACING_XS;
    position: relative;
  }

  &__list {
    margin-block-end: 0;
    margin-block-start: 0;
    margin-left: $SPACING_XXL;
    padding: $SPACING_L 0 0 0;
    padding-inline-start: 0;
    text-align: left;

    > li {
      display: flex;
      font-weight: $LIGHT;
      margin: $SPACING_M 0;
      white-space: nowrap;
    }

    > li:before {
      content: "•";
      display: inline-block;
      width: 1em;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  &__list--business {
    > li:first-child {
      font-weight: $MEDIUM;
    }
  }

  &__loading-spinner {
    padding: $SPACING_3XL $SPACING_3XL $SPACING_3XL $SPACING_3XL;
  }

  &__billing {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    line-height: $SPACING_M;
    margin: 0;
    margin-bottom: $SPACING_S;
  }

  &__button-pending {
    background-color: rgb(255, 165, 0);
    border-color: $WHITE;
    padding: $SPACING_XS $SPACING_L $SPACING_XS $SPACING_L;

    &:hover {
      background-color: rgb(255, 140, 0);
      color: $WHITE;
    }
  }

  &__pitch-beta-icon {
    background: #5D34D3;
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: $WHITE;
    font-size: $TEXT_XS;
    font-weight: $BOLD;
    height: 0.95rem;
    line-height: $TEXT_XS;
    margin-top: $SPACING_XXS;
    padding: 0.2rem 0.1rem 0.1rem 0.1rem;
    position: absolute;
    text-align: center;
    transition: all 0.3s;
    width: 1.85rem;
  }
}

.spinner__icon--megosu {
  width: 2.5rem;
}