@import "../../styles/Styles";

.login__container {
  align-items: center;
  background-attachment: fixed;
  background-image: url("../../assets/images/Matteo-Bauer-Bornemann.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;

  .login {
    border-radius: $BORDER_M;

    padding: $SPACING_XXL $SPACING_M $SPACING_M;

    @include themify($themes) {
      box-shadow: themed('heavyShadow');
    }

    @include themify($themes) {
      background-color: themed('colorBackground');
      color: themed('colorText');
    }

    &__form-container {
      @include themify($themes) {
        background-color: themed('colorBackground');
        color: themed('colorText');
      }

      margin: 1.5rem 2.5rem 1.5rem 2.5rem;
      text-align: center;
      width: 25rem;

      @media (max-width: $SCREEN_SIZE_XS) {
        margin: 0rem;
        width: $WIDTH_M;
      }
    }

    &__registration-link {
      font-size: $TEXT_L;

      > a {
        font-size: $TEXT_M;
      }
    }

    &__title {
      font-size: $TEXT_3XL;
      font-weight: $MEDIUM;
      margin-bottom: 0;
      margin-top: $SPACING_M;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  .login-form {
    margin: 1.875rem 2.5rem 2.5rem 0rem;
    width: 100%;
  }
}