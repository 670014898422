@import "../../../styles/Styles";

.chat-detail {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 40rem;
  min-width: 40rem;

  @media (max-width: $SCREEN_SIZE_XL) {
    max-width: 35rem;
    min-width: 25rem;
  }

  @media (max-width: $SCREEN_SIZE_S) {
    max-width: 100vw;
    min-width: 100vw;
  }

  &__date-container {
    color: $GRAY_LIGHT;
    font-size: 0.7rem;
    margin-top: 0.2rem;
  }

  &__container {
    border-radius: $BORDER_S;
    margin: 0rem 1rem;
    max-height: 90vh;
    padding: $SPACING_XL;
    padding-bottom: 1rem;
    position: relative;

    @include themify($themes) {
      background-color: themed('colorBackground');
      color: themed('colorText');
    }

    .button {
      font-size: $TEXT_S;
      margin: 0;
      min-width: 1.5rem;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      align-self: center;
      align-self: center;
      margin: 0 0;
      max-height: 75vh;
      max-width: 90vw;
      min-height: 75vh;
      min-width: 90vw;
      padding: $SPACING_M;
      padding-bottom: 0;
    }

    @media (max-width: $SCREEN_SIZE_S) and (max-height: $SCREEN_SIZE_S) {
      max-height: 60vh;
      max-width: 90vw;
      min-height: 60vh;
      min-width: 90vw;
      padding-bottom: 2rem;
    }
  }

  .header__user-image-container {
    margin-left: 0;
    margin-right: $SPACING_M;
    max-width: 2.2rem;
    max-width: 2.2rem;
    min-height: 2.2rem;
    min-width: 2.2rem;
  }

  .chat-overview__user-info-container {
    margin-left: 0;
    padding-left: 0;
  }

  &__title-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: $SPACING_M $SPACING_M $SPACING_XL $SPACING_M;

    @media (max-width: $SCREEN_SIZE_S) {
      margin: 1rem;
    }

    .header__user-image-container {
      max-height: 3.5rem;
      max-width: 3.5rem;
      min-height: 3.5rem;
      min-width: 3.5rem;
      width: 100%;
    }
  }

  &__message-container {
    margin: 0 1rem;
  }

  &__recipient-info-container {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  &__info-location {
    align-items: center;
    display: flex;
    display: flex;
    font-size: $TEXT_S;
    font-weight: $LIGHT;
    margin-top: $SPACING_3XS;

    svg {
      color: $GRAY_LIGHT;
      margin-right: $SPACING_3XS;
    }
  }

  &__text-header {
    display: inline;
    font-size: $TEXT_XXL;
    font-weight: $MEDIUM;
    margin-bottom: 0.125rem;
    text-align: left;

    @include themify($themes) {
      color: themed('colorText');
    }

    @media (min-width: $SCREEN_SIZE_M) {
      white-space: nowrap;
    }

    & > * {
      font-size: $TEXT_3XL;
      font-weight: $REGULAR;
      margin-bottom: 0.125rem;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  &__message-text {
    font-size: $TEXT_L;
    font-weight: $LIGHT;
    margin-bottom: $SPACING_M;
    overflow-wrap: break-word;
    white-space: pre-wrap;

    @media (max-width: $SCREEN_SIZE_S) {
      max-width: 85vw;
    }
  }

  &__content-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: $SPACING_XS;
  }

  &__content-image {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    display: inline-block;

    > img {
      border-radius: 0.5rem;
      height: 8rem;
      max-height: 8rem;
      max-width: 10rem;
      object-fit: cover;
    }
  }

  &__send-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-height: 14.5rem;
    width: 95%;

    .button {
      align-self: flex-end;
      margin-bottom: 1rem;
      margin-top: 1rem;
      max-width: 6rem;
      min-width: 4rem;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      position: relative;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  &__icons {
    color: $GRAY_LIGHTER;
    display: flex;
    margin-top: $SPACING_S;

    .chat-detail__label-input > svg {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      height: 1.25rem;
      width: 1.25rem;
    }

    form:not(:first-child) > label > svg {
      margin-left: $SPACING_XS;
    }
  }

  &__message-input {
    border-radius: $BORDER_M $BORDER_M 0 0;
    height: 5.5rem;
    margin-top: $SPACING_M;
    text-indent: $SPACING_XXS;
  }

  &__messages {
    max-height: 45vh;
    overflow-y: scroll;
  }

  &__messages::-webkit-scrollbar {
    display: none;
  }

  &__mobile-button-container {
    display: flex;
    justify-content: space-between;
    padding: 0 $SPACING_M;
  }

  &__button-container {
    padding-top: $SPACING_XXS;

    .button--white-form {
      @media (min-width: $SCREEN_SIZE_S) {
        padding: $SPACING_XXS $SPACING_S;
      }
    }
  }

  &__input {
    appearance: none;
    display: none;
  }

  &__preview-files {
    background-color: $GRAY_LIGHTEST;
    border-radius: 0 0 $BORDER_M $BORDER_M;
    display: block;
    margin-top: -0.1rem;
    max-width: 100%;
    min-width: 100%;
  }

  &__preview-image-files {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    padding-bottom: $SPACING_XXS;
    padding-left: $SPACING_3XS;

    @media (max-height: $SCREEN_SIZE_M) {
      padding: 0 $SPACING_S;
      padding-bottom: $SPACING_XXS;
    }
  }

  &__preview-image-file {
    margin-left: $SPACING_XS;
    margin-top: $SPACING_S;
    position: relative;

    > svg:first-child {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      margin-top: -0.45rem;
      overflow: visible;
      position: absolute;
      right: -0.45rem;
      visibility: hidden;
    }

    &:hover > svg:first-child,
    > svg:first-child:hover {
      visibility: visible;
    }

    > img,
    svg:not(:first-child) {
      border-radius: $BORDER_S;
      height: 3.2rem;
      object-fit: cover;
      width: 2.9rem;
    }

    @media (max-height: $SCREEN_SIZE_M) {
      > svg:first-child {
        visibility: visible;
      }
    }
  }

  &__preview-other-files {
    display: flex;
    flex-wrap: wrap;
  }

  &__preview-other-file {
    background-color: $GRAY_LIGHTER;
    border-radius: $BORDER_S;
    cursor: default;
    display: flex;
    flex-basis: auto;
    margin-bottom: $SPACING_S;
    margin-left: $SPACING_XXS;
    max-width: 90%;
    padding: $SPACING_3XS $SPACING_XXS;
    position: relative;
    width: max-content;

    &--downloadable {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }

    > svg:first-child {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      margin-top: -0.65rem;
      overflow: visible;
      position: absolute;
      right: -0.45rem;
      visibility: hidden;
    }

    &:hover > svg:first-child,
    > svg:first-child:hover {
      visibility: visible;
    }

    > svg:not(:first-child) {
      align-self: center;
      height: 0.9rem;
      width: 0.9rem;
    }

    &-text {
      align-self: center;
      color: $BLACK;
      font-size: $TEXT_S;
      font-weight: $LIGHT;
      line-height: initial;
      margin: 0;
      padding-left: $SPACING_3XS;
      width: max-content;
      word-break: break-all;
    }

    @media (max-height: $SCREEN_SIZE_M) {
      > svg:first-child {
        overflow: visible;
        visibility: visible;
      }
    }
  }

  .form-error {
    color: $RED;
    font-size: $TEXT_S;
    padding-bottom: $SPACING_XXS;
    text-align: left;
  }
}