// Megosu Logo cursors (auto)
$CURSOR_MEGOSU_1: url("../assets/images/cursors/megosu-logo.svg"), auto;
$CURSOR_MEGOSU_0: url("assets/images/cursors/megosu-logo.svg"), auto;

$CURSOR_MEGOSU_WHITE_0: url("assets/images/cursors/megosu-logo-white.svg"), auto;
$CURSOR_MEGOSU_WHITE_1: url("../assets/images/cursors/megosu-logo-white.svg"), auto;

// Pointer cursors (onHover)
$CURSOR_POINTER_MEGOSU_3: url("../../../assets/images/cursors/black-dot.svg"), pointer;
$CURSOR_POINTER_MEGOSU_2: url("../../assets/images/cursors/black-dot.svg"), pointer;
$CURSOR_POINTER_MEGOSU_1: url("../assets/images/cursors/black-dot.svg"), pointer;
$CURSOR_POINTER_MEGOSU_0: url("assets/images/cursors/black-dot.svg"), pointer;

$CURSOR_POINTER_MEGOSU_WHITE_3: url("../../../assets/images/cursors/white-dot.svg"), pointer;
$CURSOR_POINTER_MEGOSU_WHITE_2: url("../../assets/images/cursors/white-dot.svg"), pointer;
$CURSOR_POINTER_MEGOSU_WHITE_1: url("../assets/images/cursors/white-dot.svg"), pointer;
$CURSOR_POINTER_MEGOSU_WHITE_0: url("assets/images/cursors/white-dot.svg"), pointer;