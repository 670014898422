@import "../../styles/Styles";

.about {
  margin: 0 auto;
  padding: 0 $SPACING_L;

  @include themify($themes) {
    color: themed('colorText');
  }

  @media (min-width: $SCREEN_SIZE_M) {
    max-width: 49rem;
    padding-bottom: $SPACING_4XL;
  }

  a {
    font-size: $TEXT_XL;
  }

  &__block {
    margin-bottom: $SPACING_5XL;

    &--less-spacing {
      margin-bottom: $SPACING_XXL;
    }
  }

  &__title {
    font-weight: $MEDIUM;
    margin-bottom: $SPACING_M;
    margin-top: $SPACING_4XL;
  }

  &__feature-image {
    border-radius: $BORDER_S;
    width: 100%;
  }

  &__subtitle {
    font-weight: $MEDIUM;
    margin: $SPACING_L 0 0 0;
  }

  &__text {
    font-weight: $LIGHT;
    margin-top: $SPACING_XXS;

    &--bold {
      font-size: $TEXT_XL;
      font-weight: $MEDIUM;
    }
  }

  &__list-item {
    font-size: $TEXT_XL;
    font-weight: $LIGHT;
    line-height: $SPACING_L;
    list-style-position: outside;
    list-style-type: decimal;
    margin: $SPACING_XXS 0 $SPACING_M $SPACING_L;

    &--bold {
      font-size: $TEXT_XL;
      font-weight: $MEDIUM;
      margin-top: $SPACING_XXS;
    }
  }

  &__video-wrapper {
    margin-top: $SPACING_L;

    video {
      border-radius: $BORDER_S;
    }
  }

  &__designer-profiles {
    display: grid;
    gap: $SPACING_M;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;

    @media (max-width: $SCREEN_SIZE_S) {
      grid-template-columns: 1fr;
    }
  }

  &__designer {
    margin-top: $SPACING_L;

    &-title {
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      margin-bottom: 0;

      > span {
        font-size: $TEXT_XL;
        font-weight: $REGULAR;
      }
    }

    video {
      border-radius: $BORDER_M;
      margin-top: $SPACING_XS;
      object-fit: contain;
      width: 100%;
    }
  }
}