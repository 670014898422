@import "../../styles/Styles";

.subscription {
  @include themify($themes) {
    color: themed('colorText');
  }


  padding: 0 $SPACING_L $SPACING_L $SPACING_L;

  @media (min-width: $SCREEN_SIZE_S) {
    padding: 0 $SPACING_L $SPACING_L $SPACING_L;
  }

  @media (min-width: $SCREEN_SIZE_M) {
    padding: 0 $SPACING_XXL $SPACING_XXL $SPACING_XXL;
  }

  @media (min-width: $SCREEN_SIZE_L) {
    padding: 0 $SPACING_XXL;
  }

  @media (min-width: $SCREEN_SIZE_XL) {
    margin: 0 auto;
    width: 74rem;
  }

  &__feature-container {
    align-items: center;
    display: grid;
    grid-gap: $SPACING_5XL;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $SCREEN_SIZE_L) {
      display: grid;
      grid-gap: 0;
      grid-template-columns: 1fr;
    }

    &:not(:first-of-type) {
      padding-bottom: $SPACING_5XL;

      @media (max-width: $SCREEN_SIZE_L) {
        padding-bottom: $SPACING_XXL;
      }
    }

    &:first-of-type {
      padding-top: $SPACING_5XL;

      @media (max-width: $SCREEN_SIZE_L) {
        padding-top: $SPACING_XXL;
      }
    }
  }

  &__feature-image-container {
    margin-block-end: 0;
    margin-block-start: 0;
    margin-inline-end: 0;
    margin-inline-start: 0;
    width: 100%;

    @media (max-width: $SCREEN_SIZE_L) {
      grid-row: 1;
      padding-top: $SPACING_L;
    }

    figcaption {
      font-size: $TEXT_XS;
      font-weight: $LIGHT;
      padding-top: $SPACING_XXS;
      text-align: right;
    }
  }

  &__feature-image-wrapper {
    height: 0;
    overflow: hidden;
    padding-bottom: 60%;
    position: relative;

    img {
      border-radius: $BORDER_S;
      height: 100%;
      justify-content: center;
      left: 0;
      object-fit: cover;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
    }
  }

  &__feature-title {
    font-size: $TEXT_3XL;

    @media (max-width: $SCREEN_SIZE_L) {
      margin-top: $SPACING_M;
    }
  }

  &__feature-subtext {
    font-size: $TEXT_XL;
    font-weight: 300;
    line-height: $SPACING_L;

    &--less-height {
      line-height: $SPACING_M;
    }
  }

  &__feature-button {
    padding-left: $SPACING_3XL;
    padding-right: $SPACING_3XL;

    &--less-width {
      padding-left: $SPACING_M;
      padding-right: $SPACING_M;
    }
  }

  &__title {
    font-size: $TEXT_3XL;
    font-style: normal;
    font-weight: $BOLD;
    margin-bottom: 0;
    padding-top: $SPACING_5XL;
    text-align: center;

    @media (max-width: $SCREEN_SIZE_L) {
      padding-top: $SPACING_4XL;
    }
  }

  &__contact-container {
    padding-bottom: $SPACING_5XL;
    text-align: center;

    @media (max-width: $SCREEN_SIZE_L) {
      padding: $SPACING_L 0 $SPACING_XXL 0;
    }

    button {
      min-width: 6rem;
    }
  }

  &__contact-title {
    font-size: $TEXT_3XL;
  }

  &__contact-subtext {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    line-height: 1.3rem;
    margin: 0 auto;
    max-width: 25rem;
    padding-bottom: $SPACING_L;
  }

  &__image-container {
    align-items: center;
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(1, 1fr);
    justify-items: center;
    margin: $SPACING_XXL auto;
    margin-top: $SPACING_M;
    width: 100%;

    @include themify($themes) {
      filter: themed('invertLogoColor');
    }

    @media (max-width: $SCREEN_SIZE_M) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }

  &__company-image {
    height: 6rem;
    width: 100%;

    @media (max-width: $SCREEN_SIZE_M) {
      height: 4rem;
      width: 100%;
    }
  }
}