@import "../../../styles/Styles";

.slideshow {
  border-radius: 5px;
  height: 33rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;

  @media (max-width: $SCREEN_SIZE_S) {
    height: 35rem;
  }

  &__main-content {
    margin: $SPACING_XXL $SPACING_XXL 0 $SPACING_XXL;

    @media (max-width: $SCREEN_SIZE_S) {
      margin: $SPACING_L $SPACING_M 0rem $SPACING_M;
    }
  }

  &__inner {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__pagination {
    bottom: 35px;
    cursor: default;
    height: 12px;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2;

    &-item {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      display: inline-block;
      height: 32px;
      padding: 15px 5px;
      position: relative;
      text-indent: -999em;
      width: 46px;
      z-index: 1;

      + &-page {
        margin-left: -2px;
      }

      &::before {
        background: rgba(255, 255, 255, 0.5);
        content: "";
        display: block;
        height: 2px;
        left: 5px;
        position: absolute;
        top: 15px;
        transition: background 0.2s ease;
        width: 36px;
      }

      &::after {
        background: #fff;
        transition: width 0.2s ease;
        width: 0;
        z-index: 2;
      }

      &:hover::before,
      &.is-active::before {
        background-color: #fff;
      }
    }
  }
}

.slide {
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;

  &.is-active {
    display: block;
  }

  &.is-loaded {
    opacity: 1;
  }

  &__close-icon {
    color: $GRAY_LIGHTEST;

    display: flex;
    height: 1.2rem;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    transition: $TRANSITION_SHORT;
    width: 1.2rem;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    @media (max-width: $SCREEN_SIZE_L) {
      right: $SPACING_XL;
      top: $SPACING_XL;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      right: $SPACING_M;
      top: $SPACING_M;
    }

    &:hover {
      color: $GRAY_LIGHT;
      transition: $TRANSITION_SHORT;
    }
  }

  &__banner-btn {
    background-color: $BLACK;
    border: none;
    color: $WHITE;
    padding: $SPACING_XS $SPACING_M $SPACING_XS $SPACING_M;
    transition: all 0.3s ease;
  }

  &__caption {
    padding: 0 6.25rem;

    @media (max-width: $SCREEN_SIZE_S) {
      margin-top: 14rem;
      padding: 0rem $SPACING_M;
    }
  }

  &__image-container {
    background-position: center;
    background-size: cover;
    height: 100%;
    image-rendering: optimizeQuality;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    &::before {
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__image {
    height: 100%;
    object-fit: cover;
    width: 100%;
    width: 100%;
  }

  &__content {
    align-items: center;
    color: #fff;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    text-align: left;
    top: 0;
    width: 100%;
    z-index: 2;

    @media (max-width: $SCREEN_SIZE_S) {
      justify-content: center;
      text-align: center;
    }
  }

  &__title {
    font-size: $TEXT_3XL;
    margin: 0;
    width: 100%;

    @media (min-width: $SCREEN_SIZE_S) {
      width: 60%;
    }

    @media (min-width: $SCREEN_SIZE_L) {
      font-size: 2rem;
      width: 60%;
    }

    @media (min-width: $SCREEN_SIZE_XL) {
      font-size: 2.25rem;
      width: 60%;
    }

    @media (min-width: $SCREEN_SIZE_XXL) {
      font-size: 3rem;
      width: 60%;
    }
  }

  &__text {
    > p {
      font-size: $TEXT_L;
      line-height: $SPACING_L;
    }
  }

  &__btn {
    background: transparent;
    border: 1px solid #e1e1e1;
    color: #fff;
    display: inline-block;
    letter-spacing: 0.4em;
    padding: 0.813rem 1.25rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-indent: $SPACING_3XS;
    text-transform: uppercase;
    transition: color 0.1s linear 0.05s;

    &::before {
      background: #e1e1e1;
      content: "";
      display: block;
      height: 1px;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 50%;
      transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0.2s;
      width: 100%;
      z-index: 1;
    }

    &::after {
      transition: border 0.1s linear 0.05s;
    }

    &:hover {
      color: #373737;
      transition: color 0.1s linear 0s;

      &::before {
        height: 100%;
        opacity: 1;
        top: 0;
        transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0s;
      }

      &::after {
        border-color: #373737;
        transition: border 0.1s linear 0s;
      }
    }
  }

  &__btn-inner {
    position: relative;
    z-index: 2;
  }
}

.btn {
  background: transparent;
  border: 1px solid #e1e1e1;
  color: #fff;
  display: inline-block;
  font: 12px/1.2 "Oswald", sans-serif;
  letter-spacing: 0.4em;
  padding: 0.813rem 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: $SPACING_3XS;
  text-transform: uppercase;
  transition: color 0.1s linear 0.05s;

  &::before {
    background: #e1e1e1;
    content: "";
    display: block;
    height: 0.063rem;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    transition: height $TRANSITION_SHORT ease, top $TRANSITION_SHORT ease, opacity 0s linear $TRANSITION_SHORT;
    width: 100%;
    z-index: 1;
  }

  &::after {
    transition: border 0.1s linear 0.05s;
  }

  .btn-inner {
    position: relative;
    z-index: 2;
  }

  &:hover {
    color: #373737;
    transition: color 0.1s linear 0s;

    &::before {
      height: 100%;
      opacity: 1;
      top: 0;
      transition: height $TRANSITION_SHORT ease, top $TRANSITION_SHORT ease, opacity 0s linear 0s;
    }

    &::after {
      border-color: #373737;
      transition: border 0.1s linear 0s;
    }
  }
}

.slides {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.arrows {
  .arrow {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    margin: -2.063rem 0 0;
    padding: 1.25rem;
    position: absolute;
    top: 50%;
    z-index: 3;
  }

  .prev {
    left: $SPACING_L;

    &:hover .svg {
      left: -$SPACING_S;
    }
  }

  .next {
    right: $SPACING_L;

    &:hover .svg {
      left: $SPACING_S;
    }
  }

  .svg {
    fill: #fff;
    height: 1.625rem;
    left: 0;
    position: relative;
    transition: left 0.2s ease;
    width: 0.875rem;
  }
}