@import "src/styles/Styles";

.admin-advertisement-form {
  &__container {
    margin-bottom: $SPACING_L;
    min-height: 20rem;
    padding-bottom: $SPACING_3XL;
    width: 100%;

    @include themify($themes) {
      background-color: themed('overlayColor');
      box-shadow: themed('boxShadowColor');
      color: themed('colorText');
    }
  }

  &__content-container {
    margin-left: $SPACING_S;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    margin-left: $SPACING_XXS;
    margin-top: $SPACING_L;
  }

  &__input-container {
    margin-bottom: 1.3rem;
    margin-top: $SPACING_XS;
    max-width: 50rem;
    width: 65%;
  }

  &__error-container {
    display: flex;
    justify-content: space-between;

    &__input,
    &__text-area {
      font-weight: $LIGHT;
    }
  }

  &__error {
    color: $RED;
    font-size: $TEXT_S;
  }

  &__input-dropdown {
    margin-top: $SPACING_XS;
    width: 100%;
  }

  &__header-container {
    display: flex;
    flex-direction: row;
  }

  &__header {
    margin-top: $SPACING_3XL;
  }

  &__visual {
    height: 40%;
    margin-top: $SPACING_M;
    object-fit: cover;
    width: 40%;
  }

  &__current-file-title {
    margin-bottom: 0;
    margin-top: $SPACING_M;
  }

  &__button {
    border-radius: 0.25rem;
    color: $WHITE;

    font-size: $TEXT_M;
    height: $SPACING_XL;
    margin-bottom: $SPACING_S;
    padding: $SPACING_XXS $SPACING_S;
    transition: all 0.3s;
    width: 7rem;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    &--margin-left {
      margin-left: $SPACING_M;
    }
  }
}