@import "../../../styles/Styles";

.company-filter {
  display: flex;
  width: 100%;

  @include themify($themes) {
    color: themed('colorText');
  }

  &__container {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: $SCREEN_SIZE_L) {
      justify-content: space-between;
      margin-right: 0;
    }
  }

  &__search-status {
    align-items: center;
    order: 4;
    width: 100%;

    @media (max-width: $SCREEN_SIZE_S) {
      order: 2;
    }

    p span {
      animation: fadeInAnimation $TRANSITION_SHORT;
      border: 1px solid $GRAY_LIGHT;
      border-radius: $SPACING_XXS;
      font-size: $TEXT_S;
      font-weight: $LIGHT;
      margin: $SPACING_XXS $SPACING_XXS 0 0;
      margin-right: $SPACING_XXS;
      padding: $SPACING_XXS;

      @include themify($themes) {
        color: themed('colorText');
      }

      @keyframes fadeInAnimation {
        from {
          opacity: 0;
        }

        to {
          opacity: $OPACITY_HIGH;
        }
      }

      @media (max-width: $SCREEN_SIZE_S) {
        margin: $SPACING_XXS $SPACING_XXS 0 0;
      }
    }

    p:first-child {
      font-size: $TEXT_S;
      font-weight: $MEDIUM;
      line-height: inherit;
      margin: $SPACING_M 0 0 0;
      transition: $TRANSITION_MED all;

      @media (max-width: $SCREEN_SIZE_S) {
        display: none;
      }
    }

    p:last-child {
      display: flex;
      flex-wrap: wrap;
      line-height: inherit;
      margin-top: 0;
      width: $WIDTH_S;

      @media (max-width: $SCREEN_SIZE_XXL) {
        min-height: auto;
      }

      @media (max-width: $SCREEN_SIZE_S) {
        width: 100%;
      }

      span svg {
        @include themify($themes) {
          cursor: themed('cursorPointerMegosu3');
        }

        height: 0.7rem;
        position: relative;
        top: 0.125rem;
        width: 0.7rem;
      }
    }
  }

  &__searchbar-container {
    border: 1px solid $GRAY_LIGHT;
    border-radius: $SPACING_XXS;
    display: flex;
    flex-grow: 1;
    height: 2.25rem;
    margin-right: $SPACING_M;
    position: relative;

    @media (max-width: $SCREEN_SIZE_XS) {
      height: $SPACING_L;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      margin-right: 0;
      order: 1;
      padding-bottom: 0;
    }
  }

  &__searchbar-form {
    align-items: center;
    display: flex;
    width: 100%;
  }

  &__searchbar-input {
    background-color: transparent;
    border-radius: 0;
    font-size: $TEXT_S;
    padding: $SPACING_S;
    width: 100%;

    @include themify($themes) {
      color: themed('colorText');
    }

    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    &::placeholder {
      font-weight: $MEDIUM;
      opacity: $OPACITY_HIGH;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    &:-ms-input-placeholder {
      @include themify($themes) {
        color: themed('colorText');
      }

      font-weight: $REGULAR;
      opacity: $OPACITY_HIGH;
    }

    &::-ms-input-placeholder {
      @include themify($themes) {
        color: themed('colorText');
      }

      font-weight: $REGULAR;
      opacity: $OPACITY_HIGH;
    }

    @media (max-width: $SCREEN_SIZE_M) {
      width: 100%;
    }
  }

  &__searchbar-icon {
    padding: $SPACING_XXS $SPACING_S;
    padding-right: 0;
  }

  &__dropdown-container {
    display: flex;
    order: 3;
    position: relative;

    @media (max-width: $SCREEN_SIZE_XL) {
      flex-direction: row-reverse;
      flex-grow: 1;

      > div:first-of-type {
        margin-left: $SPACING_XXS;
      }

      > div:nth-of-type(3) {
        margin-right: $SPACING_XXS;
      }
    }

    @media (max-width: $SCREEN_SIZE_S) {
      width: 100%;
    }
  }

  @media (max-width: $SCREEN_SIZE_S) {
    flex-direction: column;
  }

  &__dropdown {
    border-radius: $BORDER_M;
    box-shadow: none;
    margin-top: 1px;
    min-width: 8.8rem;
    opacity: 0;
    padding: $SPACING_S 0;
    position: absolute;
    transform: translateY(-10px);
    transition: all $TRANSITION_SHORT;
    visibility: hidden;
    width: 9rem;
    z-index: 1;

    @include themify($themes) {
      background-color: themed('overlayColor');
    }

    &--btn-dropdown {
      border-radius: $BORDER_M;
      box-shadow: none;
      margin-top: 0;
      min-width: 9.375rem;
      padding: $SPACING_S;
      position: relative;
      transition: all $TRANSITION_MED;
      width: auto;
      z-index: 1;

      @include themify($themes) {
        background-color: themed('overlayColor');
      }
    }

    @media (max-width: $SCREEN_SIZE_L) {
      box-shadow: none;
      margin-top: 0;
      opacity: $OPACITY_HIGH;
      position: relative;
      visibility: visible;
      width: auto;
    }
  }

  &__dropdown-tag {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    display: flex;
    flex-direction: row;
    font-size: $TEXT_S;
    justify-content: space-between;
    padding: $SPACING_S $SPACING_M $SPACING_XXS $SPACING_M;

    @include themify($themes) {
      color: ('colorText');
    }

    @media (min-width: $SCREEN_SIZE_XL) {
      padding: $SPACING_S $SPACING_L $SPACING_XXS $SPACING_L;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      margin: 0 0 $SPACING_S 0;
      padding: $SPACING_S $SPACING_M $SPACING_3XS $SPACING_M;
    }

    &:hover {
      .company-filter__dropdown-tag-count {
        font-weight: $REGULAR !important;
      }

      > div {
        font-weight: $REGULAR !important;
      }
    }
  }

  &__dropdown-tag-count {
    @include themify($themes) {
      color: ('colorText');
    }

    font-size: $TEXT_S;
  }

  &__label-list {
    @include themify($themes) {
      color: ('colorText');
    }

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    display: block;
    font-size: $TEXT_S;
    font-weight: $MEDIUM;
    margin: 0;
    padding: $SPACING_S $SPACING_M;

    @media (min-width: $SCREEN_SIZE_XL) {
      padding: $SPACING_S 1.5rem;
    }

    @media (min-width: $SCREEN_SIZE_XXL) {
      padding: $SPACING_S $SPACING_L;
    }

    @media (max-width: $SCREEN_SIZE_L) {
      padding: $SPACING_S $SPACING_M;
    }
  }

  &__categories {
    border-bottom: 1px solid $GRAY_LIGHT;
    display: flex;
    justify-content: space-between;
    margin: 0 $SPACING_S;
    overflow-x: scroll;
  }

  &__dropdown-content {
    border-radius: $BORDER_M;

    display: block;
    opacity: 0;
    padding: $SPACING_S 0 0 0;
    position: absolute;
    top: 2.375rem;
    transition: $TRANSITION_MED;
    visibility: hidden;
    width: 100%;
    z-index: 1;

    @include themify($themes) {
      box-shadow: themed('heavyShadow');
    }

    @include themify($themes) {
      background-color: themed('overlayColor');
    }

    @media (max-width: $SCREEN_SIZE_S) {
      &--active {
        opacity: $OPACITY_HIGH;
        visibility: visible;
      }
    }

    @media (min-width: $SCREEN_SIZE_L) {
      display: none;
    }
  }

  &__btn {
    align-items: center;
    border: 1px solid $GRAY_LIGHT;
    border-radius: $BORDER_M;

    display: none;
    font-size: $TEXT_S;
    font-weight: $MEDIUM;
    order: 5;
    padding: $SPACING_S;
    text-align: center;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    @include themify($themes) {
      background-color: themed('colorBackground');
    }

    @media (max-width: $SCREEN_SIZE_XL) {
      display: block;
      flex: auto;
      width: 15rem;
    }

    @media (max-width: $SCREEN_SIZE_M) {
      display: block;
      flex: auto;
      width: auto;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      &--disabled {
        opacity: 0.2;
      }
    }
  }

  &__list-item {
    &:hover .company-filter__dropdown {
      @include themify($themes) {
        box-shadow: themed('heavyShadow');
      }

      opacity: $OPACITY_HIGH;
      padding: $SPACING_S 0;
      transform: translateY(0px);
      visibility: visible;
    }
  }

  &__btn:hover ~ &__dropdown-content,
  &__dropdown-content:hover {
    @media (min-width: $SCREEN_SIZE_S) {
      @include themify($themes) {
        box-shadow: themed('heavyShadow');
      }

      opacity: $OPACITY_HIGH;
      transform: translateY(0px);
      visibility: visible;
    }
  }

  &__bsx-icon {
    transition: all 0.3s;

    &--reversed {
      @media (min-width: $SCREEN_SIZE_S) {
        transform: rotate(180deg);
      }
    }

    &--reversed-mobile {
      @media (max-width: $SCREEN_SIZE_S) {
        transform: rotate(180deg);
      }
    }
  }

  &__filter-tag-container {
    border: 1px solid $GRAY_LIGHT;
    border-radius: $SPACING_XXS;
    display: flex;
  }
}

.company-filter-unordered-list {
  align-items: center;
  border-radius: $SPACING_XXS;
  display: flex;
  height: auto;
  margin-right: $SPACING_M;

  @media (max-width: $SCREEN_SIZE_L) {
    display: flex;
    display: none;
    order: 4;
  }

  &__item {
    &:first-child {
      @media (max-width: $SCREEN_SIZE_S) {
        display: none;
      }
    }

    display: inline-block;
    position: relative;
  }
}

.company-filter-reset {
  border: 1px solid $GRAY_LIGHT;
  border-radius: $SPACING_XXS;

  height: $SPACING_M;
  margin-right: $SPACING_M;
  padding: $SPACING_S;
  width: $SPACING_M;

  @include themify($themes) {
    cursor: themed('cursorPointerMegosu3');
  }

  @media (max-width: $SCREEN_SIZE_XS) {
    height: $SPACING_S;
  }

  @media (max-width: $SCREEN_SIZE_S) {
    margin-right: $SPACING_S;
  }

  * {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    @media (max-width: $SCREEN_SIZE_XS) {
      height: 0.8rem;
      margin-bottom: $SPACING_M;
    }
  }

  > label > svg {
    transition: all 0.5s;
  }

  &:hover > label > svg {
    transform: rotate(-360deg);
    transition: all 0.5s;
  }
}

.company-filter__bsx-icon {
  margin-left: 0.3rem;

  @media (max-width: $SCREEN_SIZE_L) {
    float: right;
  }

  &--disabled {
    display: block;
  }
}

.company-filter__caretup-icon {
  display: block;
  float: right;
  margin-left: auto;

  @media (min-width: $SCREEN_SIZE_S) {
    display: none;
  }

  &--disabled {
    display: none;
  }
}

.company-detail-filter {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;

  @media (max-width: $SCREEN_SIZE_L) {
    display: block;
  }

  @media (max-width: $SCREEN_SIZE_L) {
    flex: auto;
    margin: 0;
    order: 4;
    top: 0;
    width: auto;
  }

  &__btn {
    align-items: center;
    border: 1px solid $GRAY_LIGHT;
    border-radius: $BORDER_M;

    display: flex;
    font-size: $TEXT_S;
    font-weight: $MEDIUM;
    padding: $SPACING_S;
    text-align: left;
    width: 21rem;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    @include themify($themes) {
      background-color: themed('colorBackground');
    }

    .form__social-menu-boosted {
      margin-left: $SPACING_S;
    }

    @media (min-width: $SCREEN_SIZE_XXL) {
      width: 21rem;
    }


    @media (max-width: $SCREEN_SIZE_XXL) {
      width: 23rem;
    }

    @media (max-width: $SCREEN_SIZE_XL) {
      width: 15rem;
    }

    .company-detail-filter__bsfilter-icon {
      margin-right: $SPACING_S;

      @media (max-width: $SCREEN_SIZE_L) {
        display: none;
      }
    }

    @media (max-width: $SCREEN_SIZE_M) {
      display: block;
      text-align: center;
      width: auto;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      &--disabled {
        opacity: 0.2;
      }
    }
  }

  &__dropdown-content {
    border-radius: $BORDER_M;

    opacity: 0;
    padding-right: 0;
    padding-top: $SPACING_S;
    position: absolute;
    top: 2.375rem;
    transform: translateY(-10px);
    transition: $TRANSITION_SHORT;
    visibility: hidden;
    width: 100%;
    z-index: 1;

    @include themify($themes) {
      box-shadow: themed('heavyShadow');
    }

    @include themify($themes) {
      background-color: themed('overlayColor');
    }

    @media (max-width: $SCREEN_SIZE_S) {
      top: 3rem;

      &--active {
        opacity: $OPACITY_HIGH;
        visibility: visible;
      }
    }

    @media (min-width: $SCREEN_SIZE_XS) {
      @media (max-width: $SCREEN_SIZE_XL) {
        padding-right: $SPACING_L;
        right: 0;
        width: auto;
      }
    }

    .company-detail-filter__dropdown-category {
      padding: 0 0 0 $SPACING_L;

      @media (max-width: $SCREEN_SIZE_XS) {
        padding: 0 $SPACING_XXS 0 $SPACING_M;
      }

      & > p {
        font-size: $TEXT_S;
        font-weight: $MEDIUM;
        line-height: inherit;
        margin: $SPACING_S 0;
      }

      .company-detail-filter__dropdown-options {
        align-items: center;
        display: flex;
        margin-bottom: $SPACING_L;

        &:nth-child(3) {
          @include themify($themes) {
            cursor: themed('cursorPointerMegosu3');
          }
        }

        &:last-child {
          align-items: flex-start;
        }

        .toggle {
          margin-right: $SPACING_S;
        }

        .company-detail-filter__dropdown-tooltip {
          margin-left: $SPACING_S;
          position: relative;

          &:hover .company-detail-filter__dropdown-tooltip-content {
            opacity: $OPACITY_HIGH;
            transform: translateY(0px);
            visibility: visible;
          }

          & > p {
            background-color: $GRAY_LIGHTEST;
            border-radius: $BORDER_XL;
            color: $BLACK;

            font-size: $TEXT_S;
            line-height: $SPACING_M;
            margin: 0;
            position: relative;
            text-align: center;
            width: 1rem;

            @include themify($themes) {
              cursor: themed('cursorPointerMegosu3');
            }
          }

          .company-detail-filter__dropdown-tooltip-content {
            background-color: $WHITE;
            border: 0.8px solid #f4f4f4;
            border-radius: $BORDER_M;

            left: -6rem;
            opacity: 0;
            padding: $SPACING_3XS $SPACING_M;
            position: absolute;
            text-align: left;
            top: 1.75rem;
            transform: translateY(-10px);
            transition: all $TRANSITION_MED;
            visibility: hidden;
            width: 11rem;
            z-index: 1;

            @include themify($themes) {
              box-shadow: themed('heavyShadow');
            }

            @media (max-width: $SCREEN_SIZE_XS) {
              left: -9rem;
            }

            &:before {
              background-color: $WHITE;
              border: 1px solid #f4f4f4;
              -webkit-clip-path: polygon(0 0, 100% 100%, 0 100%);
              clip-path: polygon(0 0, 100% 100%, 0 100%);
              content: "";
              display: block;
              height: 0.438rem;
              left: 0;
              margin: auto;
              position: absolute;
              right: 0;
              top: -0.25rem;
              transform: rotate(135deg);
              width: 0.438rem;
              z-index: 2;

              @media (max-width: $SCREEN_SIZE_XS) {
                display: none;
              }
            }

            p {
              color: $BLACK;
              font-size: $TEXT_S;
              line-height: $SPACING_S;
            }

            span {
              font-size: $TEXT_S;
              font-weight: $MEDIUM;
            }
          }
        }

        .company-detail-filter__globe-icon {
          margin: $SPACING_XXS $SPACING_S 0 0;
        }

        span {
          font-size: $TEXT_S;
          font-weight: $LIGHT;
          transition: $TRANSITION_SHORT all;
        }

        ul {
          display: flex;

          @media (min-width: $SCREEN_SIZE_XS) {
            flex-wrap: wrap;
          }

          li {
            @include themify($themes) {
              color: themed('colorText');
            }

            @include themify($themes) {
              cursor: themed('cursorPointerMegosu3');
            }

            font-size: $TEXT_S;
            font-weight: $LIGHT;
            margin-right: $SPACING_M;

            @media (max-width: $SCREEN_SIZE_S) {
              margin-right: $SPACING_L;
            }

            @media (max-width: $SCREEN_SIZE_XS) {
              margin-bottom: $SPACING_XXS;
            }
          }
        }
      }
    }
  }

  &__bsx-icon {
    margin-left: auto;
    transition: all 0.3s;

    @media (max-width: $SCREEN_SIZE_L) {
      float: right;
    }

    &--reversed {
      @media (min-width: $SCREEN_SIZE_S) {
        transform: rotate(180deg);
      }
    }

    &--reversed-mobile {
      @media (max-width: $SCREEN_SIZE_S) {
        transform: rotate(180deg);
      }
    }
  }

  &:hover ~ &__dropdown-content,
  &__dropdown-content:hover {
    @media (min-width: $SCREEN_SIZE_S) {
      opacity: $OPACITY_HIGH;
      transform: translateY(0px);
      visibility: visible;
      z-index: 2;
    }
  }
}