@import "src/styles/Styles";

.checked-dropdown {
  display: flex;
  flex-direction: column;
  font-size: $TEXT_M;
  min-width: 14.375rem;
  width: 100%;

  &--active {

    .dropdown-label,
    .dropdown-label--university,
    .dropdown-label--filter {
      color: $GRAY_LIGHTEST;
    }
  }

  &__caret-icon {
    color: $BLACK;
    display: block;
    transition: all 0.3s;

    &-reverse {
      transform: rotate(180deg);
    }
  }

  &__option-name {
    max-width: 14rem;
    padding-left: $SPACING_XXS;

    @media (max-width: $SCREEN_SIZE_S) {
      max-width: 18rem;
    }
  }

  &__unknown {
    font-size: $TEXT_M;
    margin-left: $SPACING_S;
  }
}

.checked-dropdown__container {
  display: block;
  position: relative;
}

[class^="checked-dropdown__option-container"] {
  border-radius: $BORDER_S;
  box-shadow: $SHADOW_HEAVY;
  display: block;
  flex-direction: column;
  max-height: 15rem;
  overflow-y: auto;
  padding-bottom: $SPACING_3XS;
  padding-top: $SPACING_3XS;
  position: absolute;
  top: 2.5rem;
  width: 100%;
  z-index: 2;

  @include themify($themes) {
    background-color: themed('dropdownOverlay');
    color: themed('colorText');
  }

  .checked-dropdown__option {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-weight: $LIGHT;
    max-height: 1.1rem;
    padding-left: $SPACING_3XS;
    text-align: left;
  }

  .checked-dropdown__option-name {
    margin-left: 0.7rem;
    padding-top: $SPACING_XS;
  }

  .checkmark {
    height: 0.875rem;
    width: 0.875rem;

    &:hover {
      background-color: $GRAY_LIGHTEST;
    }
  }

  .checkbox {
    padding: 0.625rem 0.375rem;
  }

  .checkbox .checkmark:after {
    border: solid white;
    border-width: 0 3px 3px 0;
    height: 0.55rem;
    left: 0.17rem;
    top: -0.07rem;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    width: 0.275rem;
  }
}

.checked-dropdown__option-container--university {
  transition: all 0.3s;

  .checked-dropdown__option {
    max-height: 2rem;
    padding-top: 0.1rem;
  }
}

.checked-dropdown__option-container--filter {
  max-height: 10rem;
  padding-top: $SPACING_XXS;
  top: 1.9rem;
  transition: all 0.3s;

  .checked-dropdown__option {
    padding-right: $SPACING_M;
    text-align: initial;
  }
}

.checked-dropdown__amount {
  margin-left: auto;
}

[class^="dropdown-label"] {
  align-items: center;
  background: $GRAY_LIGHTEST;
  border: $GRAY_LIGHTEST;
  border-radius: $BORDER_S;

  display: flex;
  font-weight: $LIGHT;
  justify-content: space-between;
  letter-spacing: 0;
  margin-bottom: 0;
  overflow: hidden;
  padding: $SPACING_S $SPACING_XXS;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include themify($themes) {
    cursor: themed('cursorPointerMegosu3');
  }

  &:hover {
    background: $GRAY_LIGHTER;
    transition: all $TRANSITION_SHORT;
  }
}

.dropdown-label--filter,
.dropdown-input--filter {
  font-size: $TEXT_S;
  padding: $SPACING_XXS;
  width: 15.5rem;
}

.dropdown-input--extra-width {
  width: 17.5rem;
}

.checked-dropdown__option-container-unknown {
  font-weight: 300;
  padding: $SPACING_XXS 0;
  text-align: left;
  transition: all 0.3s;
}