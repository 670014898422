@import "../../../styles/Styles";

.horizontal-rule {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 1px solid $BLACK;
  color: $BLACK;
  display: block;
  height: 0.01px;
  width: 100%;

  @include themify($themes) {
    border-color: themed('colorText');
  }
}