@import "../../../styles/Styles";

.upload-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: $SPACING_XXL 0 $SPACING_L 0;
  width: 100%;

  &__title {
    font-size: $TEXT_3XL;
    font-weight: $MEDIUM;
    margin-top: 0;
    text-align: left;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__compressor-warn {
    background-color: $THEME_PINK;
    border-radius: 0.25rem;
    border-top-width: 4px;
    color: $WHITE;
    display: none;
    margin-top: $SPACING_M;
    opacity: 0;
    padding: 0.5rem;
    transition: all 0.3s;
    visibility: hidden;

    @include themify($themes) {
      box-shadow: 0 0 $SPACING_XS 0 themed('boxShadowColor');
    }

    &--show {
      display: flex;
      opacity: 1;
      visibility: visible;
    }

    &-container {
      display: flex;
      flex-direction: row;
      gap: $SPACING_M;
      justify-content: center;
    }

    &-icon {
      align-items: center;
      display: flex;

      > svg {
        fill: $WHITE;
        max-height: 1.5rem;
        max-width: 1.5rem;
        width: 100%;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 0rem;
      text-align: left;

      > p {
        font-size: $TEXT_S;
        line-height: 1.5rem;
        margin: 0;
      }

      &--bold {
        font-size: $TEXT_M;
        font-weight: $BOLD;
      }
    }
  }

  &__content-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-right: $SPACING_XXS;

    @media (max-width: $SCREEN_SIZE_S) {
      flex-direction: column;
    }

    > * {
      flex: 1;
    }

    > div:first-of-type {
      margin-right: $SPACING_M;
    }

    > div:nth-of-type(2) {
      margin-left: $SPACING_3XS;
    }
  }

  &__cover-image .image-uploader {
    aspect-ratio: 7 / 8;
  }

  &__tooltip {
    position: relative;

    &:hover .upload-form__tooltip-content {
      opacity: $OPACITY_HIGH;
      transform: translateY(0px);
      visibility: visible;
    }

    & > p {
      background-color: $GRAY_LIGHTEST;
      border-radius: $BORDER_XL;
      color: $BLACK;

      font-size: $TEXT_S;
      line-height: $SPACING_M;
      margin: 0;
      position: relative;
      text-align: center;
      width: 1rem;

      @include themify($themes) {
        cursor: pointer;
      }
    }

    .upload-form__tooltip-content {
      background-color: $WHITE;
      border: 0.8px solid #f4f4f4;
      border-radius: $BORDER_M;

      left: -4rem;
      opacity: 0;
      padding: $SPACING_3XS $SPACING_M;
      position: absolute;
      text-align: center;
      top: 1.75rem;
      transform: translateY(-10px);
      transition: all $TRANSITION_MED;
      visibility: hidden;
      width: 8rem;
      z-index: 1;

      @include themify($themes) {
        box-shadow: themed('heavyShadow');
      }

      &:before {
        background-color: $WHITE;
        border: 1px solid #f4f4f4;
        -webkit-clip-path: polygon(0 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 100%, 0 100%);
        content: "";
        display: block;
        height: 0.438rem;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: -0.25rem;
        transform: rotate(135deg);
        width: 0.438rem;
        z-index: 2;
      }

      p {
        color: $BLACK;
        font-size: $TEXT_XS;
        line-height: $SPACING_S;
        line-height: inherit;
      }
    }
  }

  &__measurement-container {
    display: flex;
    flex-direction: row;

    @media (max-width: $SCREEN_SIZE_S) {
      flex-direction: column;
    }

    > div:first-of-type {
      display: flex;
      flex: auto 1;
      flex-direction: column;
      max-width: 18rem;

      @media (max-width: $SCREEN_SIZE_S) {
        max-width: 100%;
        width: 100%;
      }

      .upload-form-size-container {
        display: flex;
        flex-direction: row;
        min-width: 0;

        > div {
          display: flex;
          flex: 1;
          flex-direction: column;
          margin-right: $SPACING_XXS;
          min-width: 0;

          &:last-child {
            margin-right: 0;
          }

          > input,
          span {
            flex: 1;
            min-width: 0;
            text-align: center;
          }

          > span {
            font-size: $TEXT_XS;
            font-weight: $LIGHT;
            padding: $SPACING_3XS 0;

            @include themify($themes) {
              color: themed('textGray');
            }
          }
        }
      }
    }

    > div:nth-of-type(2) {
      margin-left: $SPACING_L;
      min-width: 0;

      @media (max-width: $SCREEN_SIZE_S) {
        margin-left: 0;
      }

      > input {
        text-align: center;
      }
    }
  }

  &__label-container {
    display: flex;
    justify-content: space-between;

    &--market {
      display: flex;
      margin-bottom: $SPACING_3XS;
    }

    .description-label {
      color: $GRAY;
      font-size: $TEXT_S;
      font-weight: $LIGHT;
    }
  }

  &__label {
    margin-right: $SPACING_3XS;
    text-align: left;
  }

  &__error {
    padding: 0 0 0 $SPACING_XXS;
  }

  &__custom-checkbox {
    @include themify($themes) {
      cursor: pointer;
    }

    margin-top: auto;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__custom-checkbox > input {
    @include themify($themes) {
      cursor: pointer;
    }

    height: 0;
    opacity: 0;
    position: relative;
    width: 0;
  }

  &__checkmark {
    background-color: $WHITE;
    border: 1px solid $GRAY_LIGHT;
    border-radius: $BORDER_S;
    box-sizing: border-box;
    height: 1rem;
    left: 0;
    position: absolute;
    width: 1rem;

    &:hover {
      background-color: $GRAY_LIGHTEST;
    }
  }

  &__custom-checkbox input:checked ~ .upload-form__checked {
    background-color: $BLACK;
    border-color: $BLACK;
  }

  &__custom-checkbox input:checked ~ .upload-form__checked:after {
    display: block;
  }

  &__checked:after {
    content: "";
    display: none;
    position: absolute;
  }

  &__custom-checkbox .upload-form__checked:after {
    border: solid white;
    border-width: 0 3px 3px 0;
    height: 0.55rem;
    left: 0.2rem;
    top: 0;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    width: 0.275rem;
  }

  &__info-button {
    background-color: $GRAY_LIGHTEST;
    border-radius: 100%;

    font-size: $TEXT_S;
    margin-right: $SPACING_XXS;
    text-align: center;
    width: 1rem;

    @include themify($themes) {
      cursor: pointer;
    }
  }

  &__info-card {
    background-color: $WHITE;
    border: 1px solid $GRAY_LIGHTEST;
    border-radius: $BORDER_S;

    font-size: $TEXT_S;
    font-weight: $LIGHT;
    left: 4.5rem;
    padding: 0 $SPACING_M;
    position: absolute;
    text-align: center;
    top: 1.5rem;
    z-index: 99;

    @include themify($themes) {
      box-shadow: themed('heavyShadow');
    }

    p {
      width: 9rem;
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: $SPACING_M;
    position: relative;
    width: 100%;

    input,
    textarea {
      color: $BLACK !important;
      font-weight: $LIGHT;
    }

    .ck-toolbar {
      background: $GRAY_LIGHTEST !important;
      border: none !important;
      border-radius: 0 !important;
      border-top-left-radius: $SPACING_XXS !important;
      border-top-right-radius: $SPACING_XXS !important;
    }

    .ck-splitbutton__arrow {
      display: none !important;
    }

    .ck-content {
      background: $GRAY_LIGHTEST !important;
      border: none;
      border-bottom-left-radius: $SPACING_XXS !important;
      border-color: $GRAY-LIGHT;
      border-radius: 0 !important;
      border-bottom-right-radius: $SPACING_XXS !important;
      box-shadow: none !important;
      height: calc($SPACING_L * 6) !important; // = 5 lines height

      ul,
      ol {
        margin: 0;
        padding-left: $SPACING_XXL;
      }

      ul li {
        font-size: $TEXT_L;
        font-weight: $LIGHT;
        line-height: $SPACING_L;
        list-style-type: disc;
      }

      ol li {
        font-size: $TEXT_L;
        font-weight: $LIGHT;
        line-height: $SPACING_L;
        list-style-type: decimal;
      }

      p {
        color: $BLACK !important;
        font-size: $TEXT_L;
        font-weight: $LIGHT;
      }

      strong {
        font-weight: $REGULAR;
      }
    }

    .ck-content:focus {
      border: none !important;
      border-color: $GRAY-LIGHT !important;
    }

    .ck.ck-editor__editable > .ck-placeholder::before {
      color: $GRAY_LIGHT;
      font-size: $TEXT_M;
      letter-spacing: 0.01rem;
    }

    .triangle {
      border-bottom: 0.4rem solid white;
      border-left: 0.3rem solid transparent;
      border-right: 0.3rem solid transparent;
      height: 0;
      position: absolute;
      right: calc(50% - 0.6rem);
      top: -0.4rem;
      width: 0;
    }

    @media (min-width: $SCREEN_SIZE_S) {
      margin: $SPACING_XXS 0;
    }

    .upload-form__image-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: initial;
      width: initial;

      .image-uploader__form {
        margin-right: $SPACING_XXS;
        width: 4.58rem;

        &:last-of-type {
          margin-right: 0;
        }

        @media (max-width: $SCREEN_SIZE_S) {
          margin-bottom: $SPACING_XXS;
          width: calc(33% - 0.3rem);

          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }

    .upload-form__team-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media (max-width: $SCREEN_SIZE_S) {
        flex-direction: column;
      }

      > .upload-form__team-member {
        display: grid;
        grid-column-gap: $SPACING_XXS;
        grid-template-columns: 1fr auto;
        margin-top: $SPACING_XXS;
        width: 100%;

        &:first-of-type {
          margin-top: 0;
        }

        .upload-form__team-member-select {
          border-radius: $SPACING_S;

          .upload-form__team-member-input {
            align-items: center;
            background-color: $GRAY_LIGHTEST;
            border-radius: $BORDER_M;
            display: flex;

            > input {
              flex-grow: 1;
            }
          }

          .upload-form__team-discard-icon {
            @include themify($themes) {
              cursor: pointer;
            }

            margin-right: $SPACING_S;
            transform: rotate(45deg);
          }

          .upload-form__team-member-dropdown {
            background-color: $WHITE;
            border-radius: $BORDER_M;

            padding: $SPACING_S 0;
            position: absolute;
            transition: $TRANSITION_SHORT;
            width: 100%;
            z-index: 2;

            @include themify($themes) {
              box-shadow: themed('heavyShadow');
            }

            &--hidden {
              opacity: 0;
              visibility: hidden;
            }

            .upload-form__team-member-list {
              max-height: 15rem;
              overflow: scroll;
              padding: 0 $SPACING_M;

              &-item {
                border-bottom: 1px solid $GRAY_LIGHTER;

                padding: $SPACING_M $SPACING_S;

                @include themify($themes) {
                  cursor: pointer;
                }
              }
            }

            &-text {
              color: $GRAY_LIGHT;
              font-size: $TEXT_M;
              font-weight: $LIGHT;
              line-height: $TEXT_XL;
              padding: 0 $SPACING_L;
              text-align: left;

              &--mail-link {
                @include themify($themes) {
                  cursor: pointer;
                }

                text-decoration: underline;
                text-underline-offset: 1px;
              }
            }
          }
        }

        @media (max-width: $SCREEN_SIZE_S) {
          margin-bottom: $SPACING_M;
          max-width: 100%;
        }
      }
    }

    .upload-form__market-availability {
      display: flex;
      flex-direction: row;
      font-weight: $LIGHT;
      justify-content: flex-start;
      letter-spacing: 0.01rem;

      > label {
        top: 0.125rem;
      }
    }

    .upload-form__market-availability > * + * {
      margin-left: 1.2rem;
    }
  }

  &__error-container {
    display: flex;
    justify-content: space-between;

    .upload-form__error {
      color: $RED;
      font-size: $TEXT_S;
    }

    .upload-form__availability-error {
      padding: 0 0 0.375rem;
    }

    .description-label {
      font-size: $TEXT_S;
      font-weight: $LIGHT;

      @include themify($themes) {
        color: themed('textGray');
      }

      &--error {
        color: $RED;
      }
    }

    &__input,
    &__text-area {
      font-weight: $LIGHT;
    }
  }

  &__title-container {
    display: flex;
  }

  &__boosted {
    border-radius: $BORDER_S;
    color: $WHITE;
    font-size: $TEXT_XS;
    font-weight: bold;
    margin-bottom: auto;
    margin-left: $SPACING_XXS;
    margin-top: $SPACING_3XS;
    padding: $SPACING_3XS $SPACING_XXS;
    transition: $TRANSITION_MED;

    @include themify($themes) {
      background-color: themed('colorBoostedLabel');
    }

    &:hover {
      background-color: $BLACK;

      @include themify($themes) {
        cursor: pointer;
      }
    }
  }

  input,
  textarea,
  select {
    &:hover {
      background-color: $GRAY_LIGHTER;
      transition: all $TRANSITION_SHORT;
    }
  }

  .upload-form__input--error {
    border: 1px solid $RED;
    border-radius: $SPACING_XXS;
  }

  .upload-form__input--disabled {
    pointer-events: none;
  }

  .upload-form__button-container {
    flex-direction: column;
    margin-top: $SPACING_L;

    .upload-form__submit {
      align-self: center;
      background-color: $THEME_PINK;
      border: none;
      border-radius: $BORDER_S;
      color: $WHITE;
      font-size: $TEXT_L;
      font-weight: $MEDIUM;
      min-width: $FORM_MIN_WIDTH;
      padding: $SPACING_M;
    }

    .upload-form__cancel {
      @include themify($themes) {
        cursor: pointer;
      }

      font-weight: $LIGHT;
      margin-bottom: $SPACING_XXS;
      margin-top: $SPACING_L;

      &:hover {
        font-weight: $REGULAR;
      }
    }

    .upload-form__delete {
      @include themify($themes) {
        cursor: pointer;
      }

      font-weight: $LIGHT;

      &:hover {
        font-weight: $REGULAR;
      }
    }
  }

  .upload-form-container {
    border-radius: $BORDER_S;
    display: flex;
    flex-direction: column;
    padding: $SPACING_L $SPACING_XXL;
    text-align: center;
    width: 70%;

    @include themify($themes) {
      background-color: themed('colorBackground');
      color: themed('colorText');
    }

    @media (min-width: $SCREEN_SIZE_XS) {
      max-width: $WIDTH_L;
      padding: $SPACING_XXL $SPACING_4XL;
    }
  }

  .invisible {
    display: none;
  }

  .image-uploader__input-container .image-uploader__input-container--active {
    height: none;
  }
}

.image-uploader--simplified {
  aspect-ratio: 7 / 8;
}

.max-upload-size {
  margin-right: 0.6rem;

  @include themify($themes) {
    color: themed('textGray');
  }
}