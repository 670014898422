@import "../../Styles";

.form-popup {
  &--contact {
    display: flex;
    flex-direction: column;

    &__spinner {
      h4 {
        font-weight: $REGULAR;
        padding-top: $SPACING_S;
      }
    }

    &__success-message-container,
    &__error-message-container {
      .icon {
        font-size: 5rem;

        &__success {
          color: #52c41a;
        }

        &__error {
          color: $RED;
        }
      }

      .title {
        font-size: $TEXT_3XL;
        font-style: normal;
        font-weight: $MEDIUM;
        line-height: 2.25rem;
        margin-bottom: $SPACING_M;
      }

      .sub-text {
        font-size: $TEXT_L;
        font-style: normal;
        font-weight: $LIGHT;
        line-height: 1.2rem;
      }

      .button {
        margin-top: $SPACING_L;
      }
    }

    &__top-container {
      margin-bottom: $SPACING_XXL;

      .user-container {
        align-items: center;
        display: flex;

        .user-avatar {
          border: 1px solid $BLACK;
          border-radius: 46.5px;
          height: 93px;
          object-fit: cover;
          width: 93px;

          @media (max-width: $SCREEN_SIZE_XS) {
            height: 45px;
            width: 45px;
          }
        }

        .user-message__title {
          color: $BLACK;
          font-size: $TEXT_3XL;
          font-style: normal;
          font-weight: $MEDIUM;
          line-height: 36px;
          margin-left: $SPACING_XXL;

          @media (max-width: $SCREEN_SIZE_XS) {
            font-size: $TEXT_XL;
            margin-left: $SPACING_L;
          }
        }
      }
    }

    &__middle-container {
      display: flex;
      flex-direction: column;

      .contact-message-textarea {
        font-size: $TEXT_M;
        font-weight: $LIGHT;
        min-height: 377px;
        padding: $SPACING_M;

        @media (max-width: $SCREEN_SIZE_XS) {
          min-height: 150px;
        }
      }

      .input__title {
        text-align: left;
      }

      .input {
        font-size: $TEXT_M;
        font-weight: $LIGHT;
        margin-bottom: $SPACING_M;
      }

      .form-popup--contact__input--error {
        border: 1px solid $RED;
      }
    }

    &__bottom-container {
      display: flex;
      justify-content: flex-end;
      margin-top: $SPACING_L;

      @media (max-width: $SCREEN_SIZE_XS) {
        justify-content: center;
      }

      button {
        font-size: $TEXT_L;
        padding-left: $SPACING_L;
        padding-right: $SPACING_L;

        &:first-of-type {
          margin-right: $SPACING_XXS;
        }

        &:last-of-type {
          margin-left: $SPACING_XXS;
        }
      }
    }
  }

  &--change-email,
  &--change-password,
  &--reset-password,
  &--socials,
  &--project-uploaded,
  &--contact,
  &--invite-team-member {
    &__title {
      font-size: $TEXT_3XL;
      font-weight: $REGULAR;
      margin: 0 auto $SPACING_M auto;
      max-width: 19rem;

      @media (max-width: $SCREEN_SIZE_S) {
        margin: $SPACING_M auto $SPACING_S auto;
      }
    }

    &__subtitle {
      font-size: $TEXT_XL;
      font-weight: $MEDIUM;
      margin: 0 auto $SPACING_L auto;
      max-width: $WIDTH_L;
    }

    &__text {
      margin: 0 auto $SPACING_L auto;
      max-width: 25rem;
    }

    &__button-container {
      display: flex;
      justify-content: center;

      button {
        font-size: $TEXT_L;
        padding-left: $SPACING_L;
        padding-right: $SPACING_L;
      }

      @media (max-width: $SCREEN_SIZE_XS) {
        flex-direction: column-reverse;
      }
    }

    &__boosted {
      background-color: $GRAY_LIGHTER;
      border-radius: $BORDER_S;
      color: $WHITE;
      font-size: $TEXT_XS;
      font-weight: bold;
      margin-bottom: auto;
      margin-left: $SPACING_XXS;
      margin-top: $SPACING_3XS;
      padding: $SPACING_3XS $SPACING_XXS;
      transition: $TRANSITION_MED;

      &:hover {
        background-color: $BLACK;

        @include themify($themes) {
          cursor: themed('cursorPointerMegosu3');
        }
      }
    }
  }

  &--reset-password,
  &--invite-team-member {
    &__form {
      display: flex;
      flex-direction: column;
      margin-top: $SPACING_XXL;
    }

    &__text {
      font-size: $TEXT_L;
      line-height: $SPACING_M;
    }

    &__input-container {
      display: flex;
      flex-direction: column;
      margin-bottom: $SPACING_M;
      text-align: left;

      .form-popup--reset-password__input {
        font-weight: $LIGHT;
      }

      .form-popup--reset-password__input--error {
        border: 1px solid $RED;
      }
    }

    &__error-container {
      display: flex;
      justify-content: space-between;

      > span {
        color: $RED;
      }
    }

    &__button-container {
      display: flex;
      justify-content: center;
      margin-top: $SPACING_L;

      @media (max-width: $SCREEN_SIZE_S) {
        align-items: center;
      }

      button {
        font-size: $TEXT_L;
        padding-left: $SPACING_L;
        padding-right: $SPACING_L;

        @media (max-width: $SCREEN_SIZE_XS) {
          margin-right: 0;
          min-width: 7rem;
        }

        &:first-of-type {
          margin-right: $SPACING_XXS;

          @media (max-width: $SCREEN_SIZE_XS) {
            margin-right: 0;
          }
        }

        &:last-of-type {
          margin-left: $SPACING_XXS;

          @media (max-width: $SCREEN_SIZE_XS) {
            margin-bottom: $SPACING_XXS;
            margin-left: 0;
          }
        }
      }
    }
  }

  &--team-member {
    align-items: flex-start;
    display: flex;
    margin-top: $SPACING_XXS;
    width: 100%;

    &__error {
      color: $RED;
      float: left;
      margin: $SPACING_XXS 0;
    }

    &__select {
      border-radius: $SPACING_S;
      flex-grow: 1;
    }

    &__input {
      align-items: center;
      background-color: $GRAY_LIGHTEST;
      border-radius: $BORDER_M;
      display: flex;

      &--error {
        border: 1px solid $RED;
      }

      & > input {
        flex-grow: 1;
      }
    }

    &__discard-icon {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      margin-right: $SPACING_S;
      transform: rotate(45deg);
    }
  }

  &--change-password,
  &--change-email,
  &--socials,
  &--project-uploaded,
  &--contact,
  &--invite-team-member {
    &__form {
      display: flex;
      flex-direction: column;
      margin-top: $SPACING_XXL;
      padding: 0 $SPACING_XXL;

      input {
        font-weight: $LIGHT;
      }
    }

    &__input-container {
      display: flex;
      flex-direction: column;
      margin-bottom: $SPACING_M;
      text-align: left;

      .form-popup--change-password__input {
        font-weight: $LIGHT;
      }

      .form-popup--change-password__input--error,
      .form-popup--change-email__input--error,
      .form-popup--socials__input--error {
        border: 1px solid $RED;
      }
    }

    &__error-container {
      display: flex;

      > span {
        color: $RED;
        font-size: $TEXT_S;
        margin-left: 1rem;
      }
    }

    &__button-container {
      display: flex;
      justify-content: center;
      margin-top: $SPACING_L;

      button {
        font-size: $TEXT_L;
        padding-left: $SPACING_L;
        padding-right: $SPACING_L;

        &:first-of-type {
          margin-right: $SPACING_XXS;

          @media (max-width: $SCREEN_SIZE_XS) {
            margin-right: 0;
          }
        }

        &:last-of-type {
          margin-left: $SPACING_XXS;

          @media (max-width: $SCREEN_SIZE_XS) {
            margin-bottom: $SPACING_XXS;
            margin-left: 0;
          }
        }
      }
    }
  }

  &--project-uploaded {
    &__button-container {
      flex-flow: column wrap;
      margin: auto;
      width: 17rem;

      @media (max-width: $SCREEN_SIZE_XS) {
        width: 15rem;
      }

      .button {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: $SPACING_S;
      }
    }

    &__designer-guide {
      font-size: $TEXT_XL;
    }

    &__text {
      line-height: normal;
      margin: 0 auto 1.5rem auto;
      max-width: 19rem;
    }

    &__designer-guide,
    &__text {
      @media (max-width: $SCREEN_SIZE_XS) {
        font-size: $TEXT_L;
      }
    }

    &__title {
      margin: 0 auto 1.25rem auto;
      max-width: none;

      @media (max-width: $SCREEN_SIZE_XS) {
        font-size: $TEXT_XXL;
      }
    }
  }

  &--contact__middle-container span {
    color: $RED;
    margin-bottom: -$SPACING_XXS;
    margin-right: $SPACING_S;
    text-align: right;
  }

  &--registration__login-link {
    font-size: $TEXT_M;
  }

  &--registration__title,
  &--login__title {
    color: $BLACK;
    font-size: $TEXT_3XL;
    font-weight: $MEDIUM;
    margin-bottom: 0;
    margin-top: $SPACING_M;
  }

  &--login {
    margin: $SPACING_L 0 $SPACING_XXL 0;

    .login-form {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &--registration {
    .registration-form {
      margin-left: 0;
      margin-right: 0;
    }
  }
}