@import "src/styles/Styles";

.user-details-card {
  &__info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: left;
  }

  &__user-info-container {
    flex-direction: column;
  }

  &__header {
    display: flex;
  }

  &__title {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    color: $BLACK;
    font-size: $TEXT_L;
    font-style: normal;
    font-weight: $MEDIUM;
    line-height: 1.1rem;
    margin-bottom: $SPACING_XXS;
    word-break: break-word;


    &--dynamic-color {
      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .bookmark-icon {
      float: right;
    }
  }

  &__info-location {
    align-items: center;
    color: $BLACK;
    display: flex;
    display: flex;
    font-size: $TEXT_S;
    font-weight: $LIGHT;
    margin-top: $SPACING_3XS;

    &--dynamic-color {
      @include themify($themes) {
        color: themed('colorText');
      }
    }


    a > svg {
      color: $GRAY_LIGHT;
      font-size: $TEXT_S;
      margin-right: $SPACING_3XS;
    }
  }

  &__boosted {
    background-color: $THEME_PINK;
    border-radius: $BORDER_S;
    color: $WHITE;

    font-size: $TEXT_XS;
    font-weight: bold;
    margin-bottom: auto;
    margin-left: $SPACING_XXS;
    margin-top: $SPACING_3XS;
    padding: $SPACING_3XS $SPACING_XXS;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    &:hover {
      opacity: 0.75;
    }
  }
}