@import "../../../styles/Styles";

.filter {
  color: $BLACK;
  display: flex;
  width: 100%;

  &__container {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $SPACING_XXL $SPACING_XXL 0 $SPACING_XXL;
    width: 100%;

    @media (max-width: $SCREEN_SIZE_S) {
      padding: $SPACING_L $SPACING_M $SPACING_XXL $SPACING_M;
    }

    @media (max-width: $SCREEN_SIZE_L) {
      justify-content: space-between;
      margin-right: 0;
    }
  }

  &__search-status {
    align-items: center;
    order: 4;
    width: 100%;

    @media (max-width: $SCREEN_SIZE_S) {
      order: 2;
    }

    p span {
      animation: fadeInAnimation 200ms;
      border: 1px solid $GRAY_LIGHT;
      border-radius: $SPACING_XXS;
      font-size: $TEXT_S;
      font-weight: $LIGHT;
      margin: $SPACING_XXS $SPACING_XXS 0 0;
      margin-right: $SPACING_XXS;
      padding: $SPACING_XXS;

      @include themify($themes) {
        color: themed('colorText');
      }

      @keyframes fadeInAnimation {
        from {
          opacity: 0;
        }

        to {
          opacity: $OPACITY_HIGH;
        }
      }

      @media (max-width: $SCREEN_SIZE_S) {
        margin: $SPACING_XXS $SPACING_XXS 0 0;
      }
    }

    p:first-child {
      font-size: $TEXT_S;
      font-weight: $MEDIUM;
      line-height: inherit;
      margin: $SPACING_M 0 0 0;
      transition: $TRANSITION_MED all;

      @include themify($themes) {
        color: themed('colorText');
      }

      @media (max-width: $SCREEN_SIZE_S) {
        display: none;
      }
    }

    p:last-child {
      display: flex;
      flex-wrap: wrap;
      line-height: inherit;
      margin-top: 0;
      width: $WIDTH_L;

      @media (max-width: $SCREEN_SIZE_XXL) {
        min-height: auto;
      }

      @media (max-width: $SCREEN_SIZE_XL) {
        min-height: auto;
      }

      @media (max-width: $SCREEN_SIZE_L) {
        min-height: auto;
      }

      @media (max-width: $SCREEN_SIZE_S) {
        width: 100%;
      }

      span svg {
        @include themify($themes) {
          cursor: themed('cursorPointerMegosu3');
        }

        height: 0.7rem;
        position: relative;
        top: 0.125rem;
        width: 0.7rem;
      }
    }
  }

  &__searchbar-container {
    border: 1px solid $GRAY_LIGHT;
    border-radius: $SPACING_XXS;
    display: flex;
    flex-grow: 1;
    height: 2.25rem;
    margin-right: $SPACING_M;
    position: relative;

    @media (max-width: $SCREEN_SIZE_S) {
      margin-right: 0;
      order: 1;
      padding-bottom: 0;
    }
  }

  &__searchbar-form {
    align-items: center;
    display: flex;
    width: 100%;
  }

  &__searchbar-input {
    background-color: transparent;
    border-radius: 0;
    font-size: $TEXT_S;
    padding: $SPACING_S;
    padding-right: $SPACING_3XS;
    width: 100%;

    @include themify($themes) {
      color: themed('colorText');
    }

    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    &::placeholder {
      font-weight: $REGULAR;
      opacity: $OPACITY_HIGH;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    &:-ms-input-placeholder {
      @include themify($themes) {
        color: themed('colorText');
      }

      font-weight: $REGULAR;
      opacity: $OPACITY_HIGH;
    }

    &::-ms-input-placeholder {
      @include themify($themes) {
        color: themed('colorText');
      }

      font-weight: $REGULAR;
      opacity: $OPACITY_HIGH;
    }

    @media (max-width: $SCREEN_SIZE_M) {
      width: 100%;
    }
  }

  &__searchbar-icon {
    padding: $SPACING_XXS $SPACING_S;
    padding-right: 0;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &__dropdown-container {
    display: flex;
    order: 3;
    position: relative;

    @media (max-width: $SCREEN_SIZE_L) {
      flex-direction: row-reverse;
      flex-grow: 1;

      > div:first-of-type {
        margin-left: $SPACING_XXS;
      }

      > div:nth-of-type(3) {
        margin-right: $SPACING_XXS;
      }
    }

    @media (max-width: $SCREEN_SIZE_S) {
      width: 100%;
    }
  }
}

.filter-unordered-list {
  align-items: center;
  border-radius: $SPACING_XXS;
  display: flex;
  height: auto;
  margin-right: $SPACING_M;

  @media (max-width: $SCREEN_SIZE_L) {
    display: flex;
    display: none;
    order: 4;
  }

  &__item {
    &:first-child {
      @media (max-width: $SCREEN_SIZE_S) {
        display: none;
      }
    }

    display: inline-block;
    position: relative;
  }
}

.filter-reset {
  border: 1px solid $GRAY_LIGHT;
  border-radius: $SPACING_XXS;

  height: $SPACING_M;
  margin-right: $SPACING_M;
  padding: $SPACING_S;
  width: $SPACING_M;

  @include themify($themes) {
    cursor: themed('cursorPointerMegosu3');
  }

  @include themify($themes) {
    color: themed('colorText');
  }

  @media (max-width: $SCREEN_SIZE_S) {
    margin-right: $SPACING_S;
  }

  * {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }
  }

  > label > svg {
    transition: all 0.5s;
  }

  &:hover > label > svg {
    transform: rotate(-360deg);
    transition: all 0.5s;
  }
}

.project-filter__bsx-icon,
.designer-filter__bsx-icon {
  margin-left: auto;

  @media (max-width: $SCREEN_SIZE_L) {
    float: right;
  }

  &--disabled {
    display: block;
  }
}

.project-filter__caretup-icon,
.designer-filter__caretup-icon {
  display: block;
  float: right;
  margin-left: auto;

  @media (min-width: $SCREEN_SIZE_S) {
    display: none;
  }

  &--disabled {
    display: none;
  }
}