@import "../../../styles/Styles";

.designer-filter {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;

  @media (max-width: $SCREEN_SIZE_L) {
    display: block;
  }

  @media (max-width: $SCREEN_SIZE_L) {
    flex: auto;
    margin: 0;
    order: 4;
    top: 0;
    width: auto;
  }

  &__btn {
    align-items: center;
    border: 1px solid $GRAY_LIGHT;
    border-radius: $BORDER_M;

    display: flex;
    font-size: $TEXT_S;
    font-weight: $MEDIUM;
    padding: $SPACING_S;
    text-align: left;
    width: 19rem;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    @include themify($themes) {
      background-color: themed('colorBackground');
      color: themed('colorText');
    }

    .form__social-menu-boosted {
      margin-left: $SPACING_S;
    }

    @media (min-width: $SCREEN_SIZE_XXL) {
      width: 21rem;
    }

    @media (max-width: $SCREEN_SIZE_XL) {
      width: 12rem;
    }

    .designer-filter__bsfilter-icon {
      margin-right: $SPACING_S;

      @media (max-width: $SCREEN_SIZE_L) {
        display: none;
      }
    }

    @media (max-width: $SCREEN_SIZE_L) {
      display: block;
      text-align: center;
      width: auto;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      &--disabled {
        opacity: 0.2;
      }
    }
  }

  &__project-deliverables {
    > div {
      width: 20rem;
    }
  }

  &__dropdown-content {
    border-radius: $BORDER_M;

    opacity: 0;
    padding: 1.5rem $SPACING_M $SPACING_M $SPACING_M;
    padding-top: $SPACING_S;
    position: absolute;
    right: 0rem;
    top: 2.375rem;
    transform: translateY(-10px);
    transition: $TRANSITION_SHORT;
    visibility: hidden;
    width: 100%;
    z-index: 1;

    @include themify($themes) {
      background-color: themed('overlayColor');
      box-shadow: themed('heavyShadow');
    }

    @media (max-width: $SCREEN_SIZE_S) {
      top: 3rem;

      &--active {
        opacity: $OPACITY_HIGH;
        visibility: visible;
      }
    }

    @media (max-width: $SCREEN_SIZE_XL) {
      right: 0;
      width: auto;
    }

    .designer-filter__dropdown-category {
      padding: 0 0 0 $SPACING_L;

      & > p {
        font-size: $TEXT_S;
        font-weight: $MEDIUM;
        line-height: inherit;
        margin: $SPACING_S 0;

        @include themify($themes) {
          color: themed('colorText');
        }
      }

      .designer-filter__dropdown-options {
        align-items: center;
        display: flex;
        margin-bottom: $SPACING_L;


        > span {
          @include themify($themes) {
            color: themed('colorText');
          }
        }

        &:nth-child(3) {
          @include themify($themes) {
            cursor: themed('cursorPointerMegosu3');
          }
        }

        &:last-child {
          align-items: flex-start;
        }

        .toggle {
          margin-right: $SPACING_S;
        }

        .designer-filter__dropdown-tooltip {
          margin-left: $SPACING_S;
          position: relative;

          &:hover .designer-filter__dropdown-tooltip-content {
            opacity: $OPACITY_HIGH;
            transform: translateY(0px);
            visibility: visible;
          }

          & > p {
            background-color: $GRAY_LIGHTEST;
            border-radius: $BORDER_XL;

            font-size: $TEXT_S;
            line-height: $SPACING_M;
            margin: 0;
            position: relative;
            text-align: center;
            width: 1rem;

            @include themify($themes) {
              cursor: themed('cursorPointerMegosu3');
            }
          }

          .designer-filter__dropdown-tooltip-content {
            background-color: $WHITE;
            border: 0.8px solid #f4f4f4;
            border-radius: $BORDER_M;

            left: -4rem;
            opacity: 0;
            padding: $SPACING_3XS $SPACING_M;
            position: absolute;
            text-align: center;
            top: 1.75rem;
            transform: translateY(-10px);
            transition: all $TRANSITION_MED;
            visibility: hidden;
            width: 8rem;
            z-index: 1;

            @include themify($themes) {
              box-shadow: themed('heavyShadow');
            }

            &:before {
              background-color: $WHITE;
              border: 1px solid #f4f4f4;
              -webkit-clip-path: polygon(0 0, 100% 100%, 0 100%);
              clip-path: polygon(0 0, 100% 100%, 0 100%);
              content: "";
              display: block;
              height: 0.438rem;
              left: 0;
              margin: auto;
              position: absolute;
              right: 0;
              top: -0.25rem;
              transform: rotate(135deg);
              width: 0.438rem;
              z-index: 2;
            }

            p {
              color: $BLACK;
              font-size: $TEXT_XS;
              line-height: $SPACING_S;
            }
          }
        }

        .designer-filter__globe-icon {
          margin: $SPACING_XXS $SPACING_S 0 0;

          @include themify($themes) {
            color: themed('colorText');
          }
        }

        span {
          font-size: $TEXT_S;
          font-weight: $LIGHT;
          transition: $TRANSITION_SHORT all;
        }

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            @include themify($themes) {
              cursor: themed('cursorPointerMegosu3');
            }

            font-size: $TEXT_S;
            font-weight: $LIGHT;
            margin-right: $SPACING_M;


            @include themify($themes) {
              color: themed('colorText');
            }

            @media (max-width: $SCREEN_SIZE_S) {
              margin-right: $SPACING_L;
            }

            @media (max-width: $SCREEN_SIZE_XS) {
              margin-bottom: $SPACING_XXS;
            }
          }
        }
      }
    }
  }

  &__bsx-icon {
    transition: all 0.3s;

    &--reversed {
      @media (min-width: $SCREEN_SIZE_S) {
        transform: rotate(180deg);
      }
    }

    &--reversed-mobile {
      @media (max-width: $SCREEN_SIZE_S) {
        transform: rotate(180deg);
      }
    }
  }

  &:hover ~ &__dropdown-content,
  &__dropdown-content:hover {
    @media (min-width: $SCREEN_SIZE_S) {
      opacity: $OPACITY_HIGH;
      transform: translateY(0px);
      visibility: visible;
      z-index: 2;
    }
  }
}