@import "../../styles/Styles";

.home {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 90vh;
  padding-bottom: $SPACING_L;
  position: relative;
  width: 100%;

  &__banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: $SPACING_XXS;
    padding: $SPACING_M $SPACING_M $SPACING_S $SPACING_M;
    position: relative;

    @media (min-width: $SCREEN_SIZE_S) {
      justify-content: center;
    }

    @media (min-width: 1075px) {
      padding: $SPACING_XXL $SPACING_XXL $SPACING_S $SPACING_XXL;
    }
  }

  &__banner-inactive {
    display: none;
  }

  &__dot-container {
    align-self: center;
    bottom: 1.5rem;
    display: grid;
    position: absolute;
  }

  &__dot {
    background-color: $GRAY_LIGHT;
    border-radius: 50%;

    display: inline-block;
    height: $SPACING_XS;
    margin: 0 2px;
    transition: all 0.6s ease;
    width: $SPACING_XS;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu2');
    }

    &--active {
      background-color: white;
      transition: all 0.6s ease;
    }
  }

  &__close-icon {
    color: $GRAY_LIGHTEST;

    display: flex;
    height: 1.2rem;
    position: absolute;
    right: 3.5rem;
    top: 3.5rem;
    transition: $TRANSITION_SHORT;
    width: 1.2rem;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu2');
    }

    @media (max-width: $SCREEN_SIZE_L) {
      right: 2rem;
      top: 2rem;
    }

    &:hover {
      color: $GRAY_LIGHT;
      transition: $TRANSITION_SHORT;
    }
  }

  &__banner-image {
    &--desktop {
      animation: fadeIn 1s;
      background-color: $GRAY_LIGHTEST;
      border-radius: $SPACING_XXS;
      display: none;
      height: 33rem;
      object-fit: cover;
      width: 100%;
    }

    &--mobile {
      animation: fadeIn 0.4s;
      background-color: $GRAY_LIGHTEST;
      border-radius: $SPACING_XXS;
      display: block;
      height: 35rem;
      object-fit: cover;
      object-fit: cover;
      width: auto;
    }

    &--tablet {
      animation: fadeIn 0.4s;
      background-color: $GRAY_LIGHTEST;
      border-radius: $SPACING_XXS;
      display: block;
      height: 38rem;
      max-height: 40rem;
      object-fit: cover;
      object-fit: cover;
      width: auto;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: $OPACITY_HIGH;
      }
    }

    @media (min-width: $SCREEN_SIZE_S) {
      &--desktop {
        display: block;
      }

      &--mobile {
        display: none;
      }
    }
  }

  &__banner-content {
    color: $WHITE;
    left: 50%;
    min-width: 19.688rem;
    padding: 0 $SPACING_L;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);

    &--title {
      font-size: $TEXT_3XL;
      margin-bottom: $SPACING_M;
    }

    &--subtitle {
      font-size: $TEXT_L;
      line-height: $SPACING_L;
    }

    &--title,
    &--subtitle {
      width: 100%;
    }

    button {
      margin: $SPACING_S 0 $SPACING_XXL 0;
    }

    @media (max-width: $SCREEN_SIZE_S) and (max-height: $SCREEN_SIZE_S) {
      width: 17rem;
    }

    @media (min-width: $SCREEN_SIZE_S) {
      left: 0;
      padding: 0 $SPACING_4XL;
      text-align: left;
      transform: translateX(0%);
      width: auto;

      &--title {
        font-size: $TEXT_3XL;
      }

      &--subtitle {
        font-size: $TEXT_L;
      }

      &--title,
      &--subtitle {
        width: 60%;
      }

      button {
        margin: 0;
      }

      & > * {
        margin-left: 0;
      }
    }

    @media (min-width: $SCREEN_SIZE_M) {
      font-size: $TEXT_3XL;
    }

    @media (min-width: $SCREEN_SIZE_L) {
      left: 0;
      padding: 0 $SPACING_5XL;
      text-align: left;
      transform: translateX(0%);
      width: auto;

      &--title {
        font-size: 2rem;
        margin-bottom: $SPACING_M;
      }

      &--title,
      &--subtitle {
        width: 60%;
      }

      button {
        margin: 0;
      }

      & > * {
        margin-left: 0;
      }
    }

    @media (min-width: $SCREEN_SIZE_XL) {
      &--title {
        font-size: 2.25rem;
      }
    }

    @media (min-width: $SCREEN_SIZE_XXL) {
      &--title {
        font-size: 3rem;
      }
    }
  }

  &__spinner-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 $SPACING_XXL;

    @media (max-width: $SCREEN_SIZE_S) {
      padding: 0 $SPACING_M;
    }
  }

  &__container-other-projects {
    margin-bottom: 1rem;
    margin-top: $SPACING_XL;
  }

  &__content-container-projects {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: $SPACING_M;

    @media (min-width: $SCREEN_SIZE_XS) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $SCREEN_SIZE_S) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: $SCREEN_SIZE_L) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: $SCREEN_SIZE_3XL) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__content-container-noresults {
    display: flex;
    font-size: $TEXT_XL;
    margin: 0 auto;
    padding: $SPACING_XXL 0 $SPACING_4XL 0;
    text-align: center;
  }

  &__advertisement {
    &-visual-container {
      background-color: $GRAY_LIGHTEST;
      border-radius: $BORDER_M;

      padding-bottom: 114%;
      position: relative;
      width: 100%;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu2');
      }
    }

    &-visual {
      border-radius: $BORDER_M;
      color: transparent;
      height: 100%;
      object-fit: cover;
      position: absolute;
      transition: $TRANSITION_SHORT;
      width: 100%;


      @include themify($themes) {
        box-shadow: 0 0 0.6rem 0 themed('boxShadowColor');
      }
    }

    &-description-container {
      align-items: center;
      display: flex;
    }

    &-text {
      &-container {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: $SPACING_M;
        justify-content: space-between;
        padding: $SPACING_S $SPACING_XXS;
        width: 100%;
      }

      &-title {
        @include themify($themes) {
          color: themed('colorText');
          cursor: themed('cursorPointerMegosu2');
        }

        font-size: $TEXT_M;
        font-weight: $REGULAR;
        margin-bottom: auto;
        text-decoration: underline;
        word-break: break-word;

        @media (max-width: $SCREEN_SIZE_XS) {
          font-size: $TEXT_S;
        }
      }

      &-link {
        color: $BLACK;
        text-align: initial;
        text-decoration: none;
      }
    }

    &-attributes-container {
      display: flex;
      flex-grow: 1;
      margin-bottom: auto;

      @media (max-width: $SCREEN_SIZE_XS) {
        margin-bottom: 0;
      }
    }

    &-label {
      border-radius: $BORDER_S;
      color: $WHITE;
      font-size: $TEXT_XS;
      font-weight: bold;
      margin-bottom: auto;
      margin-left: $SPACING_XXS;
      padding: $SPACING_3XS $SPACING_XXS;
      transition: $TRANSITION_MED;

      @include themify($themes) {
        background-color: themed('colorBoostedLabel');
      }

      &:hover {
        background-color: $BLACK;

        @include themify($themes) {
          cursor: themed('cursorPointerMegosu2');
        }
      }
    }
  }

  &__related-results-title {
    font-size: $TEXT_XL;
    margin-bottom: $SPACING_M;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &__sorting {
    margin: 0 $SPACING_XXL 0 auto;
    position: relative;

    @media (max-width: $SCREEN_SIZE_S) {
      align-self: flex-end;
      display: flex;
      margin: 0;
      padding-right: $SPACING_M;
    }
  }

  &__sorting-title {
    align-items: center;

    display: flex;
    font-size: $TEXT_M;
    font-weight: $REGULAR;
    margin: 0;
    padding-bottom: $SPACING_M;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu2');
    }

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &__sorting-title-icon {
    height: 0.8rem;
    margin-left: $SPACING_XXS;
    transition: all 0.3s;
    width: 0.8rem;

    &--reversed {
      @media (min-width: $SCREEN_SIZE_S) {
        transform: rotate(180deg);
      }
    }

    &--reversed-mobile {
      @media (max-width: $SCREEN_SIZE_S) {
        transform: rotate(180deg);
      }
    }
  }

  &__sorting-title:hover + &__sorting-dropdown,
  &__sorting-dropdown:hover {
    @media (min-width: $SCREEN_SIZE_S) {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }

  &__sorting-dropdown {
    border-radius: $BORDER_M;
    margin-top: -$SPACING_XS;
    min-width: 9.375rem;
    opacity: 0;
    padding: $SPACING_S 0;
    position: absolute;
    right: 0;
    transform: translateY(-10px);
    transition: all $TRANSITION_SHORT;
    visibility: hidden;
    z-index: 1;

    @include themify($themes) {
      background-color: themed('overlayColor');
      box-shadow: themed('boxShadowColor');
    }

    &--active {
      @media (max-width: $SCREEN_SIZE_S) {
        margin-top: 2.2rem;
        opacity: 1;
        right: $SPACING_S;
        transform: translateY(0px);
        visibility: visible;
      }
    }

    &__item {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu2');
      }

      display: flex;
      flex-direction: row;
      font-size: $TEXT_S;
      font-weight: $LIGHT;
      padding: $SPACING_S $SPACING_M $SPACING_XS $SPACING_M;

      @include themify($themes) {
        color: themed('colorText');
      }

      & .home__social-menu-boosted {
        margin-left: $SPACING_XXS;
      }

      @media (min-width: $SCREEN_SIZE_XL) {
        padding: $SPACING_S $SPACING_L $SPACING_XS $SPACING_L;
      }

      @media (max-width: $SCREEN_SIZE_S) {
        margin: 0 0 $SPACING_S 0;
        padding: $SPACING_S $SPACING_M $SPACING_XXS $SPACING_M;
      }

      &--active {
        font-weight: $REGULAR;
      }
    }
  }

  &__verification-popup {
    background-color: $WHITE;
    border-radius: $BORDER_M;
    bottom: 5rem;

    padding: $SPACING_L $SPACING_XXL $SPACING_M $SPACING_XXL;
    position: fixed;
    right: 2.5rem;
    width: $WIDTH_M;
    z-index: 3;

    @include themify($themes) {
      box-shadow: themed('heavyShadow');
    }

    @media (max-width: $SCREEN_SIZE_XXL) {
      right: $SPACING_XL;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      right: 1rem;
      width: 15rem;
    }
  }

  &__verification-title {
    font-size: $TEXT_XL;
    font-weight: $MEDIUM;
    margin-bottom: $SPACING_XXS;
  }

  &__verification-subtitle {
    font-size: $TEXT_M;
    line-height: inherit;
    margin-top: $SPACING_S;

    &--underline {
      border-bottom: 1px solid $BLACK;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu2');
      }
    }
  }

  &__verification-icon-close {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu2');
    }

    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &__filter-ending-message {
    padding: $SPACING_L 0;
    text-align: center;

    @include themify($themes) {
      color: themed('colorText');
    }

    & > p {
      font-size: $TEXT_L;
    }
  }

  &__verification-input {
    display: flex;
    flex-direction: column;
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    margin-bottom: $SPACING_S;
    width: 100%;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__input {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    margin-bottom: $SPACING_S;
  }

  &__label-container {
    display: flex;
    justify-content: left;
  }

  &__label {
    align-self: flex-start;
    font-size: $TEXT_S;
    min-width: 6rem;

    @media (min-width: $SCREEN_SIZE_S) {
      font-size: $TEXT_M;
      min-width: $WIDTH_S;
    }
  }

  &__form-error {
    color: $RED;
    font-size: $TEXT_S;
    padding-bottom: $SPACING_XXS;
    text-align: right;
  }

  &__input-error {
    border: 1px solid $RED;
  }

  &__banner-content--button {
    background-color: $BLACK;
    border: none;
    color: $WHITE;
    padding: $SPACING_XS $SPACING_M $SPACING_XS $SPACING_M;
  }

  &__social-menu-boosted {
    border-radius: $BORDER_S;
    color: $WHITE;
    display: inline-block;
    font-size: $TEXT_XS;
    font-weight: bold;
    margin-bottom: auto;
    margin-left: $SPACING_3XS;
    margin-top: $SPACING_3XS;
    padding: $SPACING_3XS $SPACING_XXS $SPACING_3XS $SPACING_XXS;
    text-align: left;
    transition: $TRANSITION_MED;

    @include themify($themes) {
      background-color: themed('colorBoostedLabel');
    }

    &:hover {
      background-color: $BLACK;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu2');
      }
    }
  }
}

.infinite-scroll-component {
  padding: 0 !important;
}