@import "src/styles/Styles";

.subscription-form {
  padding: $SPACING_L 0 $SPACING_5XL 0;
  text-align: center;

  @media (max-width: $SCREEN_SIZE_S) {
    padding: $SPACING_XXL 0 $SPACING_4XL 0;
  }

  &__toggle {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    padding: 0 0 $SPACING_XXL $SPACING_XXL;

    .toggle {
      margin: 0 $SPACING_M;

      .toggle__switch {
        height: 1.5rem;
        width: $SPACING_3XL;
      }

      .toggle__switch > input:checked + .toggle__slider:before {
        transform: translateX(1.3rem);
      }
    }

    span {
      &:last-child {
        color: $THEME_PINK;
        padding-left: $SPACING_XXS;
      }
    }
  }

  &__card-container {
    display: grid;
    grid-gap: $SPACING_XXL;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: $SCREEN_SIZE_L) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: $SCREEN_SIZE_M) {
    &__sub-container:nth-child(1) {
      grid-row: 1;
    }

    &__sub-container:nth-child(2) {
      grid-row: 2;
    }
  }

  &__partners {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: $SPACING_4XL;

    @media (max-width: $SCREEN_SIZE_M) {
      margin-top: $SPACING_XL;
    }

    &-container {
      border: 2px solid $WHITE;
      border-radius: $BORDER_L;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: $SPACING_L;
      text-align: center;
      transition: border-color $TRANSITION_MED;

      @include themify($themes) {
        box-shadow: themed('heavyShadow');
      }

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      &:hover {
        border-color: $BLACK;
      }

      @media (max-width: $SCREEN_SIZE_L) {
        flex-direction: column;
        padding: $SPACING_M;
      }
    }

    &-column {
      margin-left: $SPACING_M;
      margin-right: $SPACING_M;
      text-align: left;

      @media (max-width: $SCREEN_SIZE_L) {
        text-align: center;
      }
    }

    &-title {
      font-size: $TEXT_XXL;
      margin-bottom: 0;

      @media (max-width: $SCREEN_SIZE_L) {
        margin-top: $SPACING_M;
      }
    }

    &-paragraph {
      font-size: $TEXT_M;
      max-width: 20rem;

      @media (max-width: $SCREEN_SIZE_L) {
        margin-bottom: $SPACING_L;
      }
    }

    &-features-list {
      margin-block-end: 0;
      margin-left: $SPACING_XL;
      margin-left: $SPACING_XXL;
      padding-inline-start: 0;
      text-align: left;

      @media (max-width: $SCREEN_SIZE_L) {
        margin-top: $SPACING_L;
      }

      > li {
        display: flex;
        font-weight: $LIGHT;
        margin: $SPACING_M 0;
        white-space: nowrap;
      }

      > li:before {
        color: $BLACK;
        content: "•";
        display: inline-block;
        width: 1em;
      }
    }
  }
}