@import "../../../styles/Styles";

.input {
  flex: 1;

  &__container {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
  }

  &__title {
    flex: 1;
  }
}
