@import "../../../styles/Styles";

.admin-statistics {
  @include themify($themes) {
    color: themed('colorText');
  }

  &__card-container {
    display: grid;
    gap: $SPACING_XL;
    grid-template-columns: repeat(3, 1fr);
  }

  &__card {
    background-color: $GRAY_LIGHTER;
    border-radius: $BORDER_L;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @include themify($themes) {
      background-color: themed('navBarBackground');
      box-shadow: themed('boxShadowColor');
      color: themed('colorText');
    }

    &:hover {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }

    &-column {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin-left: $SPACING_M;
      margin-right: $SPACING_M;
      width: 100%;
    }

    &-icon {
      > svg {
        height: $SPACING_3XL;
        width: $SPACING_3XL;
      }

      &--pink {
        color: $THEME_PINK;
      }

      &--purple {
        color: $THEME_PURPLE;
      }
    }

    &-content {
      margin-top: $SPACING_M;
      text-align: right;
      width: 100%;
    }

    &-number {
      font-size: $TEXT_XXL;
      margin-bottom: 0rem;
    }

    &-metric-name {
      font-size: $TEXT_L;
      margin-top: 0rem;
    }
  }

  &__container {
    display: grid;
    gap: $SPACING_XL;
    grid-template-columns: repeat(3, 1fr);
    margin-top: $SPACING_L;
  }

  &__box {
    @include themify($themes) {
      color: themed('colorText');
    }

    &-title {
      font-size: $TEXT_XL;
      margin-bottom: $SPACING_M;
    }
  }

  &__table {
    align-content: flex-end;
    display: flex;
    flex-direction: row;

    &-row {
      align-items: baseline;
      border-bottom: 1px $GRAY_LIGHT solid;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      > p {
        font-size: $TEXT_L;
        font-weight: $BOLD;
        margin-bottom: 0rem;

        > span {
          font-size: $TEXT_L;
          font-weight: $REGULAR;
          margin-left: $SPACING_L;
        }
      }
    }
  }

}