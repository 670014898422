@import "src/styles/Styles";

.partner-images {
  &__image-container {
    align-items: center;
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    justify-items: center;
    margin: $SPACING_XXL auto;
    margin-top: $SPACING_M;
    width: 100%;

    @media (max-width: $SCREEN_SIZE_S) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__company-image {
    height: 5rem;
    width: 100%;

    @include themify($themes) {
      filter: themed('invertLogoColor');
    }

    @media (max-width: $SCREEN_SIZE_XS) {
      height: 4rem;
      width: 100%;
    }
  }

  &__title {
    font-weight: $MEDIUM;
    margin: 0;
    margin-top: $SPACING_4XL;
  }
}