@import "../../../styles/Styles";

.admin-header-form {
  &__container {
    margin-bottom: $SPACING_L;
    min-height: 20rem;
    padding-bottom: $SPACING_3XL;
    width: 100%;

    @include themify($themes) {
      background-color: themed('navBarBackground');
      box-shadow: themed('boxShadowColor');
      color: themed('colorText');
    }
  }

  &__content-container {
    margin-left: $SPACING_S;
  }

  &__title {
    font-size: $TEXT_M;

    @include themify($themes) {
      color: themed('colorText');
    }

  }

  &__sub-title {
    font-size: $TEXT_L;
    font-weight: $REGULAR;
    padding-top: $SPACING_XS;
  }

  &__mid-title {
    font-size: $TEXT_M;
    font-weight: $REGULAR;
    margin-top: $SPACING_S;
  }

  &__image-uploader {
    width: 35%;

    &--mobile {
      .image-uploader {
        aspect-ratio: 7 / 8;
      }
    }
  }


  &__input-container {
    margin-top: $SPACING_L;
  }

  &__input {
    margin-top: $SPACING_XS;
    width: 95%;
  }

  &__button-container {
    margin-left: $SPACING_XXS;
    margin-top: $SPACING_L;
  }
}