@import "src/styles/Styles";

.admin-company-form {
  &__container {
    margin-bottom: $SPACING_L;
    min-height: 20rem;
    padding-bottom: $SPACING_3XL;
    width: 100%;


    @include themify($themes) {
      background-color: themed('overlayColor');
      box-shadow: themed('boxShadowColor');
      color: themed('colorText');
    }

    > div:nth-of-type(2) {
      margin-right: $SPACING_XS;
    }
  }

  &__content-container {
    margin-left: $SPACING_S;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    margin-left: $SPACING_XXS;
    margin-top: $SPACING_L;
  }

  &__input-container {
    margin-bottom: 1.3rem;
    margin-top: $SPACING_XS;
    max-width: 70rem;
    width: 50rem;

    @media (max-width: $SCREEN_SIZE_XXL) {
      width: 30rem;
    }

    @media (max-width: $SCREEN_SIZE_L) {
      width: 15rem;
    }

    &--group {
      display: flex;
      flex-direction: column;
      gap: $SPACING_XS;
      margin-bottom: $SPACING_XL;
    }
  }

  &__input-dropdown {
    margin-top: $SPACING_XS;
    max-width: 70rem;
    width: 50rem;

    @media (max-width: $SCREEN_SIZE_XXL) {
      width: 30rem;
    }

    @media (max-width: $SCREEN_SIZE_L) {
      width: 15rem;
    }
  }

  &__image-uploader {
    margin-right: $SPACING_XXS;
  }

  &__error-container {
    display: flex;
    justify-content: space-between;

    .company-form__error {
      color: $RED;
      font-size: $TEXT_S;
    }

    .upload-form__availability-error {
      padding: 0 0 $SPACING_XXS;
    }

    .description-label {
      color: $GRAY;
      font-size: $TEXT_S;
      font-weight: $LIGHT;

      &--error {
        color: $RED;
      }
    }

    &__input,
    &__text-area {
      font-weight: $LIGHT;
    }
  }
}

.company-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: $SPACING_XXL 0 $SPACING_L 0;
  width: 100%;

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__content-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin-right: $SPACING_XXS;

    @media (max-width: $SCREEN_SIZE_S) {
      flex-direction: column;
    }

    > * {
      flex: 1;
    }

    > div:first-of-type {
      margin-right: $SPACING_M;
    }

    > div:nth-of-type(2) {
      margin-left: $SPACING_3XS;
    }
  }

  &__cover-image .image-uploader {
    aspect-ratio: 7 / 8;
  }

  &__label-container {
    display: flex;
    justify-content: space-between;

    &--market {
      display: flex;
      margin-bottom: $SPACING_3XS;
    }

    .description-label {
      color: $GRAY;
      font-size: $TEXT_S;
      font-weight: $LIGHT;
    }
  }

  &__label {
    margin-right: $SPACING_3XS;
    text-align: left;
  }

  &__label-set {
    margin-right: $SPACING_XXL;
  }

  &__textarea {
    height: 12rem;
    width: 100%;
  }

  &__error {
    padding: 0 0 0 $SPACING_XXS;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: $SPACING_M;
    position: relative;
    width: 100%;

    input,
    textarea {
      font-weight: $LIGHT;
    }

    @media (min-width: $SCREEN_SIZE_S) {
      margin: $SPACING_XXS 0;
    }
  }

  &__input-description {
    background-color: $GRAY_LIGHTEST;
  }

  .image-uploader__input-container .image-uploader__input-container--active {
    height: none;
  }

  &__checkboxes {
    &-title {
      margin-bottom: 0.2rem;
      margin-top: 1.5rem;
    }

    &-text {
      margin-left: 1.4rem;
    }

  }
}

.image-uploader--simplified {
  aspect-ratio: 7 / 8;
}

.max-upload-size {
  margin-right: 0.6rem;
}