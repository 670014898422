@import "../../../styles/Styles";

.admin-file-form {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__container {
    width: $WIDTH_L;
  }

  &__image-uploader {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__image-title {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    margin-bottom: $SPACING_S;
    width: 100%;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    margin-top: $SPACING_M;
    max-width: $WIDTH_L;
    width: 100%;

    &-buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      > button {
        padding: $SPACING_XS $SPACING_4XL $SPACING_XS $SPACING_4XL;
      }
    }
  }

  &__input {
    display: none;
  }

  &__input-text {
    background-color: $GRAY_LIGHTEST;
    border: none;
    border-radius: $BORDER_M;
    color: $BLACK;

    font-size: $TEXT_M;
    font-weight: $LIGHT;
    letter-spacing: 0.01rem;
    outline: none;
    padding: $SPACING_S $SPACING_XXS;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    > svg {
      margin-right: $SPACING_XXS;
    }
  }

  &__dropdown {
    margin-top: $SPACING_XS;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: $SPACING_L;

    > button {
      font-size: $TEXT_L;
      padding: $SPACING_XS $SPACING_4XL $SPACING_XS $SPACING_4XL;
    }
  }

  &__uploaders-container {
    display: flex;
    flex-direction: row;
    gap: $SPACING_L;
  }

  &__error {
    color: $RED;
    font-size: $TEXT_S;
    font-style: italic;
    margin-top: $SPACING_S;
  }
}