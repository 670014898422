// Basic Colors
$WHITE: #ffffff;
$GRAY: #808080;
$GRAY_LIGHT: #c1c1c1;
$GRAY_LIGHTER: #e7e7e7;
$GRAY_LIGHTEST: #f4f4f4;
$BLACK: #292929;
$GRAY_LIGHTEST_TRANSPARENT: rgba(244, 244, 244, 0.4);

// Theme Colors
$THEME_PINK: #c0879b;
$THEME_PURPLE: #5d34d3;

// Validations Colors
$RED: #ff0000;
$ORANGE: #ffa500;
// Box Shadow
$SHADOW_LIGHT: 0 0 6px 0 rgba(244, 244, 244, 1);
$SHADOW_HEAVY: 0 0 50px 10px rgba(0, 0, 0, 0.07);