@import "../../styles/Styles";

.faq {
  margin: 0 auto;
  padding: 0 $SPACING_L;

  @include themify($themes) {
    color: themed('colorText');
  }

  @media (min-width: $SCREEN_SIZE_M) {
    max-width: 49rem;
    padding-bottom: $SPACING_4XL;
  }

  a {
    font-size: $TEXT_XL;
  }

  &__title {
    font-weight: $MEDIUM;
    margin-bottom: $SPACING_M;
    padding-top: $SPACING_4XL;
  }

  &__subtitle {
    font-weight: $MEDIUM;
    margin: $SPACING_4XL 0 $SPACING_M 0;

    &--spacing {
      font-weight: $MEDIUM;
      margin: $SPACING_4XL 0 $SPACING_L 0;
      width: 75%;
    }

    @media (max-width: $SCREEN_SIZE_XS) {
      width: 100%;
    }
  }

  &__feature-image {
    border-radius: $BORDER_S;
    pointer-events: none;
    position: relative;
    width: 100%;
  }

  &__feature-image-copyright {
    float: right;
    font-size: $TEXT_XS;
    font-weight: $LIGHT;
    line-height: 1.5rem;
    margin: 0;
  }

  &__container {
    margin: $SPACING_M 0;
  }

  &__container-title {
    font-size: $TEXT_XL;
    font-weight: $MEDIUM;
    margin: 0;
  }

  &__text-container {
    font-size: $TEXT_XL;
    font-weight: $LIGHT;
    margin: $SPACING_S 0 $SPACING_S 0;

    @include themify($themes) {
      color: themed('colorText');
    }

    &--spacing {
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      margin: $SPACING_S 0 $SPACING_S 0;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  &__list {
    &--spacing {
      margin: $SPACING_M 0;
    }
  }

  &__list-item {
    font-size: $TEXT_XL;
    font-weight: $LIGHT;
    line-height: $SPACING_L;
    list-style-type: disc;
    margin-left: $SPACING_L;
    padding: 0;

    &--spacing {
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      list-style-type: disc;
      margin-left: $SPACING_XXL;
    }

    &--ordered {
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      line-height: $SPACING_L;
      list-style-type: lower-alpha;
      margin-left: $SPACING_L;
      padding: 0;
    }
  }

  &-row-wrapper {
    border-top: 1px solid #ccc;

    @include themify($themes) {
      background-color: themed('colorBackground');
    }
  }

  .button--black {
    margin-bottom: $SPACING_XL;
  }
}

.styles_icon-wrapper__2cftw {
  top: auto !important;

  @include themify($themes) {
    filter: themed('invertLogoColor');
  }
}

.styles_row-title-text__1MuhU {
  font-size: $TEXT_XL;

  @include themify($themes) {
    color: themed('colorText');
  }
}