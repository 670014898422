@import "styles/Styles";

html {
  height: 100%;
}

body {
  // Smooth text for macOS
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  margin: 0;
  min-height: 100vh;
  padding: 0;
}

html,
body {
  font-family: Biotif;
}

//AccountSetupForm elements font businessType
input,
textarea,
select,
button,
a {
  font-family: inherit;
  font-size: $TEXT_XXS;
}

label,
span,
a,
li {
  font-size: $TEXT_M;
}

label {
  letter-spacing: 0.01rem;
  margin-bottom: $SPACING_XXS;
}

button {
  @include themify($themes) {
    cursor: themed('cursorPointerMegosu0');
  }

  // Smooth text for macOS
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  padding: $SPACING_XXS $SPACING_M;
}

input,
textarea,
select {
  background-color: $GRAY_LIGHTEST;
  border: none;
  border-radius: $BORDER_M;
  font-size: $TEXT_M;
  font-weight: $LIGHT;
  letter-spacing: 0.01rem;
  outline: none;
  padding: $SPACING_S $SPACING_XXS;
}

::placeholder {
  color: $GRAY_LIGHT;
  font-style: normal;
  font-weight: $LIGHT;
}

:-ms-input-placeholder {
  color: $GRAY_LIGHT;
  font-style: normal;
  font-weight: $LIGHT;
}

::-ms-input-placeholder {
  color: $GRAY_LIGHT;
  font-style: normal;
  font-weight: $LIGHT;
}

textarea {
  height: 3rem;
  line-height: 1.2rem;
  resize: none;
}

a {
  color: $THEME_PURPLE;

  text-align: center;
  text-decoration-line: none;

  @include themify($themes) {
    cursor: themed('cursorPointerMegosu0');
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2 {
  margin-bottom: $SPACING_L;
}

h3,
h4 {
  margin-bottom: $SPACING_M;
}

h1 {
  font-size: $TEXT_3XL;
}

p {
  font-size: $TEXT_XL;
  line-height: $SPACING_L;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}