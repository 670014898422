@import "src/styles/Styles";

.footer {
  margin-top: auto;

  @include themify($themes) {
    background-color: themed('overlayColor');
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $SPACING_XXL $SPACING_L;

    a,
    span {
      color: $BLACK;
      font-weight: $LIGHT;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    @media (max-width: $SCREEN_SIZE_S) {
      flex-direction: column;
      padding: $SPACING_XXL;
    }

    > * {
      flex: 1 1;
    }
  }

  &__list-container {
    display: flex;

    .footer__list-item {
      &:not(:first-of-type) {
        @include themify($themes) {
          cursor: themed('cursorPointerMegosu3');
        }

        margin: $SPACING_S 0 0 0;

        @media (max-width: $SCREEN_SIZE_S) {
          margin: $SPACING_M 0 0 0;
        }
      }

      &:first-of-type p {
        font-size: $TEXT_M;
        font-weight: $REGULAR;
        line-height: $SPACING_M;
        margin-bottom: $SPACING_M;
      }

      p {
        line-height: $SPACING_M;
        margin: 0;
        margin-block-end: 0;
        margin-block-start: 0;

        @include themify($themes) {
          color: themed('colorText');
        }
      }

      a:hover {
        color: $GRAY;
      }
    }

    &:last-of-type {
      @media (max-width: $SCREEN_SIZE_S) {
        width: $WIDTH_S;
      }

      @media (max-width: $SCREEN_SIZE_XS) {
        width: 6rem;
      }
    }
  }

  &__list-container--social {
    display: none;

    .footer__list {
      display: flex;
      justify-content: center;

      .footer__list-item {
        margin: 0 $SPACING_XXS;

        svg {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    @media (max-width: $SCREEN_SIZE_S) {
      display: block;
    }
  }

  &__row-container {
    display: flex;
    justify-content: space-around;

    @media (max-width: $SCREEN_SIZE_S) {
      justify-content: space-between;
      margin-bottom: $SPACING_XXL;
    }
  }

  &__list-container--logo {
    flex: 0;
    margin-right: $SPACING_4XL;

    > p {
      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .footer__list {
      display: flex;

      @media (max-width: $SCREEN_SIZE_S) {
        display: none;
      }

      .footer__list-item {
        margin: 0 $SPACING_XXS;

        svg {
          height: 1.1rem;
          width: 1.1rem;
        }
      }
    }

    img {
      height: auto;
      object-fit: contain;
      width: 5rem;

      @include themify($themes) {
        filter: themed('invertLogoColor');
      }
    }

    p {
      font-size: $TEXT_M;
      line-height: 1.1rem;
      margin-bottom: $SPACING_L;
      margin-top: $SPACING_S;
      padding-left: $SPACING_XXS;

      @media (max-width: $SCREEN_SIZE_S) {
        padding-left: 0;
      }
    }

    @media (max-width: $SCREEN_SIZE_S) {
      flex-basis: 100%;
      margin-right: 0;
    }
  }

  &__bottom-bar {
    border-top: 1px solid $GRAY_LIGHT;
    display: flex;
    justify-content: space-between;
    padding: $SPACING_M $SPACING_L;

    @media (max-width: $SCREEN_SIZE_S) {
      align-items: center;
      flex-direction: column-reverse;
      text-align: center;
    }

    p {
      font-size: $TEXT_XS;
      font-weight: $LIGHT;
      line-height: $SPACING_S;

      @include themify($themes) {
        color: themed('colorText');
      }

      @media (max-width: $SCREEN_SIZE_S) {
        font-size: $TEXT_S;
      }
    }
  }
}