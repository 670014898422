@import "../../styles/Styles";

.policy {
  margin: 0 auto;
  padding: 0 $SPACING_L;

  @include themify($themes) {
    color: themed('colorText');
  }

  @media (min-width: $SCREEN_SIZE_M) {
    max-width: 49rem;
    padding-bottom: $SPACING_4XL;
  }

  a {
    font-size: $TEXT_XL;
  }

  &__title {
    font-weight: $MEDIUM;
    margin-bottom: $SPACING_M;
    padding-top: $SPACING_4XL;
  }

  &__subtitle {
    font-weight: $MEDIUM;
    margin: $SPACING_XXL 0;
    width: 75%;

    &--spacing {
      font-weight: $MEDIUM;
      margin: $SPACING_4XL 0 $SPACING_L 0;
      width: 75%;
    }

    @media (max-width: $SCREEN_SIZE_XS) {
      width: 100%;
    }
  }

  &__feature-image {
    border-radius: $BORDER_M;
    pointer-events: none;
    position: relative;
    width: 100%;
  }

  &__feature-image-copyright {
    float: right;
    font-size: $TEXT_XS;
    font-weight: $LIGHT;
    line-height: 1.5rem;
    margin: 0;
  }

  &__container {
    margin: $SPACING_L 0;
  }

  &__container-title {
    font-size: $TEXT_XL;
    font-weight: $MEDIUM;
    margin: 0;

    &--spacing {
      font-size: $TEXT_XL;
      font-weight: $MEDIUM;
      margin: 0;
      margin: $SPACING_4XL 0 0 0;
    }
  }

  &__container-text {
    font-weight: $LIGHT;
    margin: 0;

    &--spacing {
      font-weight: $LIGHT;
      margin: $SPACING_M 0 0 0;
    }
  }

  &__list-item {
    font-size: $TEXT_XL;
    font-weight: $LIGHT;
    line-height: $SPACING_L;
    list-style-type: disc;
    margin-left: $SPACING_L;
    padding: 0;

    &--spacing {
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      line-height: $SPACING_L;
      list-style-type: disc;
      margin-left: $SPACING_XXL;
    }

    &--ordered {
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      line-height: $SPACING_L;
      list-style-position: inside;
      list-style-type: lower-alpha;
      padding: 0;
    }
  }

  &__image-container {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(4, 1fr);
    justify-items: start;
    margin: $SPACING_XXL 0;
    padding-top: $SPACING_L;

    @media (max-width: $SCREEN_SIZE_XS) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__company-image {
    width: 100%;
  }
}