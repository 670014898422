@import "../../styles/Styles";

.project-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 90vh;
  padding: 0 $SPACING_M $SPACING_L $SPACING_M;
  position: relative;

  @include themify($themes) {
    color: themed('colorText');
  }

  &__waiting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    gap: $SPACING_M;
    margin-top: $SPACING_L;

    @include themify($themes) {
      color: themed('colorText');
    }

    > h1 {
      font-size: $TEXT_L;
      margin-bottom: 0;
    }

    > p {
      margin: 0;
      font-size: $TEXT_M;
    }
  }

  img {
    pointer-events: all;
  }

  @media (min-width: $SCREEN_SIZE_S) {
    padding: 0 $SPACING_L $SPACING_L $SPACING_L;
  }

  @media (min-width: $SCREEN_SIZE_M) {
    padding: 0 $SPACING_XXL $SPACING_XXL $SPACING_XXL;
  }

  @media (min-width: $SCREEN_SIZE_L) {
    padding: 0 $SPACING_XXL;
  }

  @media (min-width: $SCREEN_SIZE_XL) {
    padding: 0 $SPACING_5XL $SPACING_4XL $SPACING_5XL;
  }

  @media (min-width: $SCREEN_SIZE_XXL) {
    margin: 0 auto;
    width: 74rem;
  }

  &__content-container {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: $SCREEN_SIZE_M) {
      flex-direction: row;
    }
  }

  &__modal-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  }

  &__modal-background {
    background-color: $GRAY_LIGHTEST_TRANSPARENT;
    display: flex;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &__rating-container {
    padding: $SPACING_M;
    text-align: center;
  }

  &__rating-star {
    color: $THEME_PINK;
    height: 2rem;
    width: 2rem;
  }
}