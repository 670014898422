@import "../../styles/Styles";

.admin {
  height: 100%;
  position: relative;

  @include themify($themes) {
    color: themed('colorText');
  }

  &__main-content {
    height: auto;
    margin-left: 16rem;
    margin-top: $SPACING_M;
    padding: $SPACING_M $SPACING_L;

    @media (max-width: $SCREEN_SIZE_S) {
      margin-left: 10rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    font-weight: $BOLD;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    width: 15rem;

    @include themify($themes) {
      background-color: themed('navBarBackground');
    }

    &-item {
      > a {
        font-size: $TEXT_L;

        @include themify($themes) {
          color: themed('colorText');
        }
      }

      &--active {
        > a {
          font-size: $TEXT_L;

          @include themify($themes) {
            color: themed('selectedItemColor');
          }
        }
      }
    }

    @media (max-width: $SCREEN_SIZE_S) {
      width: $WIDTH_S;
    }
  }

  &__list-container {
    padding: $SPACING_M;

    > li {
      padding: $SPACING_XXS 0;
    }
  }

  &__list-content {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(1, 1fr);
    height: 100%;

    @media (min-width: $SCREEN_SIZE_XS) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $SCREEN_SIZE_L) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: $SCREEN_SIZE_XL) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: $SCREEN_SIZE_3XL) {
      grid-template-columns: repeat(5, 1fr);
    }

    > div {
      padding-bottom: $SPACING_M;
    }
  }

  &__notification-bar {
    &-container {
      display: flex;
      flex-direction: column;
      margin-top: $SPACING_M;
      width: 50%;
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: $SPACING_L;
      width: 50%;

      > button {
        font-size: $TEXT_L;
        padding: $SPACING_XS $SPACING_4XL $SPACING_XS $SPACING_4XL;
      }
    }
  }

  &__back-button {
    margin-bottom: $SPACING_M;
    padding: $SPACING_XS $SPACING_M $SPACING_XS $SPACING_M;
  }
}