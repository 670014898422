@import "src/styles/Styles";

.header {
  align-items: center;
  background-color: $WHITE;
  display: flex;
  padding: $SPACING_M $SPACING_M $SPACING_M $SPACING_L;
  position: relative;

  @include themify($themes) {
    background: themed('colorBackground');
  }

  @media (max-width: $SCREEN_SIZE_S) {
    padding: $SPACING_M $SPACING_M $SPACING_M $SPACING_S;
  }

  &__theme-option {
    &-container {
      margin-left: $SPACING_L;
      margin-top: $SPACING_XXS;

      @media (max-width: $SCREEN_SIZE_S) {
        margin-left: $SPACING_S;
      }
    }

    &-switch {
      display: inline-block;
      height: 18px;
      position: relative;
      transform: rotate(90deg);
      width: 30px;
    }

    &-switch input {
      height: 0;
      opacity: 0;
      width: 0;
    }

    &-slider {
      background-color: $GRAY_LIGHTER;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0px;
      top: 0;
      -webkit-transition: .4s;
      transition: .4s;
    }

    &-slider:before {
      background-color: $BLACK;
      bottom: 3.1px;
      content: "";
      height: 12px;
      position: absolute;
      -webkit-transition: .4s;
      transition: .4s;
      width: 12px;

      @include themify($themes) {
        background-color: themed('sliderColor');
        left: themed('sliderPosition');
      }
    }

    &-checkbox:checked + &-slider {
      background-color: #5f5f5f;
    }

    &-checkbox:checked + &-slider:before {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px);
    }

    &-slider.round {
      border-radius: 34px;
    }

    &-slider.round:before {
      border-radius: 50%;
    }
  }

  &__bar {
    animation: outAnimation 100ms ease-out;
    display: flex;
    height: $SPACING_XXL;
    opacity: 0;
    transition: $TRANSITION_SHORT;
    visibility: hidden;
    width: 100%;

    @include themify($themes) {
      background-color: themed('notificationBarColor');
    }

    @media (max-width: $SCREEN_SIZE_S) {
      height: $SPACING_4XL;
    }

    &-text {
      align-self: center;
      color: $WHITE;

      font-size: $TEXT_M;
      font-weight: $REGULAR;
      margin: auto;
      text-align: center;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }

    &-close-icon {
      align-self: center;
      color: $WHITE;
      height: 1.2rem;
      margin-right: $SPACING_S;
      width: 1.2rem;

      @media (max-width: $SCREEN_SIZE_XS) {
        height: 2rem;
        width: 2rem;
      }

      &:hover {
        @include themify($themes) {
          cursor: themed('cursorPointerMegosu3');
        }
      }
    }

    &--visible {
      animation: inAnimation 250ms ease-in;
      display: flex;
      opacity: 1;
      visibility: visible;
    }

    &--invisible {
      display: none;
    }
  }

  @keyframes inAnimation {
    0% {
      opacity: 0;
      visibility: hidden;
    }

    100% {
      opacity: 1;
      visibility: visible;
    }
  }


  &__logo {
    display: flex;
    margin-left: $SPACING_XS;
    margin-top: 0.3rem;
    width: 5rem;

    @include themify($themes) {
      filter: themed('invertLogoColor');
    }
  }

  &__logo-container {
    display: flex;
  }

  &__links-container {
    display: none;

    @media (min-width: $SCREEN_SIZE_M) {
      display: block;
    }
  }

  &__links {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: $SPACING_XXL;
  }

  &__links-item {
    padding-right: $SPACING_XXL;

    a {
      font-size: $TEXT_M;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    a:hover {
      color: $GRAY;
    }

    p {
      color: $BLACK;

      font-size: $TEXT_M;
      margin: 0;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }
  }

  &__user-image-container {
    background-position: center center;
    background-size: cover;
    border-radius: 100%;

    height: 1.8rem;
    margin-left: $SPACING_S;
    width: 1.8rem;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    @include themify($themes) {
      border: 1px solid themed('colorText');
    }

    &--user-detail-card {
      background-position: center center;
      background-size: cover;
      border-radius: 100%;

      height: 2.4rem;
      margin-right: $SPACING_M;
      width: 2.4rem;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      @include themify($themes) {
        border: 1px solid themed('colorText');
      }
    }
  }

  &__buttons-container {
    display: none;

    @media (min-width: $SCREEN_SIZE_M) {
      align-items: center;
      display: flex;
    }

    .header__login {
      border: 1px solid $BLACK;
      border-radius: $BORDER_S;
      color: $BLACK;

      font-size: $TEXT_M;
      margin-left: $SPACING_S;
      margin-right: $SPACING_3XS;
      min-width: $SPACING_3XL;
      padding: $SPACING_XXS $SPACING_S;
      text-align: center;
      transition: all 0.3s;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      @include themify($themes) {
        background-color: themed('colorBackground');
        border: 1px solid themed('colorText');
        color: themed('colorText');
      }

      &:hover {
        @include themify($themes) {
          background: themed('colorText');
          color: themed('colorBackground');
        }
      }
    }

    .header__registration,
    .header__upload {
      background-color: $BLACK;
      border: 1px solid $BLACK;
      border-radius: $BORDER_S;
      color: $WHITE;

      font-size: $TEXT_M;
      margin-left: $SPACING_S;
      margin-right: $SPACING_S;
      min-width: $SPACING_3XL;
      padding: $SPACING_XXS $SPACING_S;
      transition: all 0.3s;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      @include themify($themes) {
        background: themed('colorText');
        color: themed('colorBackground');
      }

      &:hover {
        @include themify($themes) {
          background: themed('colorBackground');
          border: 1px solid themed('colorText');
          color: themed('colorText');
        }
      }
    }

    .header__upload-btn {
      margin-left: 0.8rem;
    }

    .header__profile {
      align-items: center;
      border: 1px solid;
      border-radius: $BORDER_XL;
      display: flex;
      font-size: $TEXT_XS;
      height: 1.5rem;
      justify-content: center;
      min-width: auto;
      padding: $SPACING_3XS;
      width: 1.5rem;
    }
  }

  &__menu {
    display: flex;

    > svg {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }
  }

  &__button-group-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    > div:first-of-type {
      align-items: center;
      display: flex;
      margin-right: $SPACING_XXS;
    }
  }

  &__pitch-beta-icon {
    background: #5D34D3;
    border-radius: 0.25rem;
    box-sizing: border-box;
    color: $WHITE;

    font-size: $TEXT_XS;
    font-weight: $BOLD;
    height: 0.95rem;
    line-height: $TEXT_XS;
    padding: 0.2rem 0.1rem 0.1rem 0.1rem;
    position: absolute;
    right: 10.9rem;
    text-align: center;
    top: $SPACING_S;
    transition: all 0.3s;
    width: 1.85rem;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }
  }

  .header__user-image-notification {
    background: $THEME_PINK;
    border: 2px solid $WHITE;
    border-radius: 50%;
    box-sizing: border-box;
    height: 0.9rem;
    position: absolute;
    right: 3.1rem;
    top: 0.9rem;
    transition: all 0.3s;
    width: 0.9rem;
  }

  &__nav-icon {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    height: 0.6rem;
    margin-left: $SPACING_M;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    width: 1.25rem;
  }

  &__nav-icon span {
    @include themify($themes) {
      background: themed('colorText');
    }

    border-radius: $BORDER_S;
    display: block;
    height: 1px;
    left: 0;
    opacity: $OPACITY_HIGH;
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    width: 100%;
  }

  &__nav-icon span:nth-child(1) {
    top: 0;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  &__nav-icon span:nth-child(2) {
    margin-left: 0;
    top: 0.3125rem;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  &__nav-icon span:nth-child(3) {
    top: 0.625rem;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  &__nav-icon.open span:nth-child(1) {
    height: 0.125rem;
    left: 0.1875rem;
    top: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 90%;
  }

  &__nav-icon.open span:nth-child(2) {
    opacity: 0;
    width: 0%;
  }

  &__nav-icon.open span:nth-child(3) {
    height: 0.125rem;
    left: 0.1875rem;
    top: 0.8125rem;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 90%;
  }

  &__about-dropdown {
    @include themify($themes) {
      background-color: themed('overlayColor');
    }

    border-radius: $BORDER_S;

    display: flex;
    flex-direction: column;
    margin-left: 20.5rem;
    margin-top: $SPACING_XL;
    opacity: 0;
    padding-bottom: 1.75rem;
    padding-top: 0.75rem;
    position: absolute;
    top: 2.72rem;
    transform: translateY(-20px);
    transition: all $TRANSITION_MED;
    visibility: hidden;

    @include themify($themes) {
      box-shadow: themed('heavyShadow');
    }

    &--show {
      opacity: $OPACITY_HIGH;
      transform: translateY(-10px);
      transition: all $TRANSITION_MED;
      visibility: visible;
    }

    &-item {
      @include themify($themes) {
        color: themed('colorText');
      }

      min-width: 7rem;
      padding: $SPACING_M 1.75rem;
      padding-bottom: 0;
      text-align: left;
      z-index: 1;
    }

    &-item:hover {
      color: $GRAY;
      transition: all $TRANSITION_MED;
    }
  }

  &__about-dropdown-content {
    position: relative;
    z-index: 1;

    &:after {
      content: "";
      height: $SPACING_4XL;
      left: -1.25rem;
      position: absolute;
      top: -1.25rem;
      width: 5rem;
      z-index: -1;
    }
  }

  &__navbar-dropdown-content:hover,
  &__about-dropdown-item:hover {
    .header__about-dropdown {
      @media (min-width: $SCREEN_SIZE_S) {
        opacity: $OPACITY_HIGH;
        transform: translateY(0px);
        visibility: visible;
      }
    }
  }

  &__profile-dropdown {
    @include themify($themes) {
      background-color: themed('overlayColor');
    }

    border-radius: $BORDER_S;

    display: flex;
    flex-direction: column;
    margin-right: -0.4rem;
    margin-top: $SPACING_XL;
    opacity: 0;
    padding-bottom: 1.75rem;
    padding-top: 0.75rem;
    position: absolute;
    top: 2.72rem;
    transform: translateY(-20px);
    transition: all $TRANSITION_MED;
    visibility: hidden;

    @include themify($themes) {
      box-shadow: themed('heavyShadow');
    }

    &--show {
      @include themify($themes) {
        background-color: themed('overlayColor');
      }

      border-radius: $BORDER_S;

      display: flex;
      flex-direction: column;
      margin-right: -0.4rem;
      margin-top: $SPACING_XL;
      opacity: $OPACITY_HIGH;
      padding-bottom: 1.75rem;
      padding-top: 0.75rem;
      position: absolute;
      top: 2.72rem;
      transform: translateY(-10px);
      transition: all $TRANSITION_MED;
      visibility: visible;

      @include themify($themes) {
        box-shadow: themed('heavyShadow');
      }
    }

    &-item {
      @include themify($themes) {
        color: themed('colorText');
      }

      min-width: 4.5rem;
      padding: $SPACING_M 1.75rem;
      padding-bottom: 0;
      text-align: left;
      transition: all $TRANSITION_MED;
      z-index: 1;
    }

    &-item:hover {
      color: $GRAY;
      transition: all $TRANSITION_MED;
    }
  }

  &__profile-dropdown-content {
    margin-left: 0.8rem;
    position: relative;
    z-index: 1;

    &:after {
      content: "";
      height: $SPACING_4XL;
      left: -1.25rem;
      position: absolute;
      top: -1.25rem;
      width: 5rem;
      z-index: -1;
    }
  }

  .header__profile-dropdown-chat-notification {
    background: $THEME_PINK;
    border: 2px solid $WHITE;
    border-radius: 50%;
    box-sizing: border-box;
    height: 0.7rem;
    left: 0.75rem;
    position: absolute;
    top: 6.2rem;
    transition: all 0.3s;
    width: 0.7rem;
  }

  &__profile-dropdown-item:hover {
    .header__profile-dropdown {
      @media (min-width: $SCREEN_SIZE_S) {
        opacity: $OPACITY_HIGH;
        transform: translateY(0px);
        visibility: visible;
      }
    }
  }
}

.header__container {
  background-color: $WHITE;
  border-bottom: 1px solid $GRAY_LIGHT;
  margin-top: 0;
  position: fixed;
  top: 0;
  transition: all 0.4s;
  width: 100%;
  z-index: 2;

  &--hide {
    margin-top: -2.5rem;

    @media (max-width: $SCREEN_SIZE_S) {
      margin-top: -4rem;
    }
  }
}