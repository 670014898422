@import "../../../styles/Styles";

.project-details-overview {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $SPACING_M 0 0 0;
  width: 100%;

  @include themify($themes) {
    color: themed('colorText');
  }

  @media (min-width: $SCREEN_SIZE_M) {
    padding: $SPACING_XXL 0 0 $SPACING_XS;
  }

  @media (min-width: $SCREEN_SIZE_L) {
    padding: $SPACING_XXL 0 0 $SPACING_M;
  }

  @media (min-width: $SCREEN_SIZE_XL) {
    padding: $SPACING_4XL 0 0 $SPACING_XL;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    position: relative;

    @media (min-width: $SCREEN_SIZE_S) {
      padding: 0 $SPACING_M;
    }

    @media (max-width: $SCREEN_SIZE_M) {
      padding-top: $SPACING_M !important;
    }
  }

  &__actions-container {
    justify-content: space-between;
    margin-bottom: $SPACING_M;
    width: initial;
  }

  &__feedback {
    line-height: 2;
  }

  &__feedback-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 $SPACING_S;

    &-buttons {
      display: flex;
      flex-direction: column;
      gap: $SPACING_XS;
    }

    &-text {
      display: flex;
      flex-direction: column;
    }
  }

  &__modal {
    background-color: $WHITE;
    border-radius: $BORDER_S;
    flex-direction: column;
    padding: $SPACING_M;
    position: absolute;
    top: 14rem;
    width: 50%;

    h2 {
      margin-bottom: 1rem;
    }
  }

  &__textarea {
    box-sizing: border-box;
    display: flex;
    height: 8rem;
    justify-content: center;
    width: 100%;
  }

  &__modal-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    > button {
      &:first-child {
        margin-right: $SPACING_3XS;
      }

      &:last-child {
        margin-left: $SPACING_3XS;
      }
    }
  }

  &__checkbox-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .project-details-overview__label {
      margin-top: 0.25rem;
    }
  }

  &__info-container {
    display: flex;
    flex-direction: column;

    .feature-img-container {
      margin-bottom: $SPACING_XXS;
    }
  }

  &__info_description {
    @include themify($themes) {
      color: themed('colorText');
    }

    font-size: $TEXT_S;
    font-style: normal;
    font-weight: $LIGHT;
    line-height: 14px;

    .main-text {
      font-weight: $LIGHT;
      letter-spacing: 0.03em;
    }
  }

  &__feature-img {
    border-radius: $BORDER_S;
    display: block;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
  }

  &__project-description {
    font-size: $TEXT_XL;
    font-weight: $LIGHT;
    margin: 1.25rem 0;
    white-space: break-spaces;
    word-break: break-word;

    strong {
      font-weight: $REGULAR;
    }

    ul,
    ol {
      margin: 0;
      padding-left: $SPACING_XXL;
    }

    ul li {
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      line-height: $SPACING_L;
      list-style-type: disc;
    }

    ol li {
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      line-height: $SPACING_L;
      list-style-type: decimal;
    }

    span {
      font-size: $TEXT_XL;
    }
  }

  &__img-secondary {
    border-radius: $BORDER_S;
    display: block;
    height: 100%;
    margin-bottom: $SPACING_L;
    object-fit: contain;
    width: 100%;
  }

  &__title {
    @include themify($themes) {
      color: themed('colorText');
    }

    font-size: $TEXT_XL;
    font-style: normal;
    font-weight: $MEDIUM;
    line-height: 24px;
    margin-top: $SPACING_L;
  }

  &__video-wrapper {
    padding-top: 56.25%;
    position: relative;
  }

  &__sketchfab-embed-wrapper {
    margin-top: $SPACING_M;

    > iframe {
      border: 0px;
      border-radius: $BORDER_S;
      height: 25rem;
      width: 100%;
    }
  }

  &__featured-video {
    left: 0;
    position: absolute;
    top: 0;

    & > div {
      border-radius: $BORDER_S;
    }
  }

  &__disabled-link {
    color: $BLACK;
    font-family: Biotif;
    font-size: $TEXT_XL;
    pointer-events: none;
  }

  &__error {
    color: $RED;
  }

  &__side-menu {
    color: $GRAY_LIGHTER;
    display: flex;
    flex-direction: column;
    grid-gap: $SPACING_M;
    margin-top: $SPACING_XS;
    overflow: hidden;
    position: absolute;
    right: -1rem;

    > div {
      &:hover {
        color: $GRAY;
        transition: 0.2s;
      }
    }

    &--menu-items {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      height: $TEXT_XL;
      width: $TEXT_XL;

      &--mobile {
        height: $TEXT_L;
        width: $TEXT_L;
      }
    }

    &--mobile {
      color: $GRAY_LIGHTER;
      display: grid;
      grid-template-columns: 2rem 0rem;
      position: relative;
      right: 1.1rem;
      top: 1.7rem;

      > div {
        &:hover {
          color: $GRAY;
          transition: 0.2s;
        }
      }
    }

    &--owner {
      grid-template-columns: 2rem 2rem 0rem;
    }
  }

  &__chat-icon > a {
    color: $GRAY_LIGHTER;

    &:hover {
      color: $GRAY;
      transition: 0.2s;
    }
  }

  &__bookmark-container {
    display: grid;
    grid-template-columns: 0.7rem 0rem;
    left: 1.2rem;
    position: relative;
  }

  &__details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--mobile {
      margin-top: 0.5rem;
    }
  }

  &__details-title {
    @include themify($themes) {
      color: themed('colorText');
    }

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    font-size: $TEXT_L;
    font-weight: $MEDIUM;
    margin-bottom: $SPACING_XXS;

    > span {
      font-size: $TEXT_M;
    }

    &--design-year {
      font-size: $TEXT_L;
      font-weight: $LIGHT;
    }
  }

  &__details-subtitle {
    @include themify($themes) {
      color: themed('colorText');
    }

    font-size: $TEXT_M;
    font-style: normal;
    font-weight: normal;
  }

  &__details-save-btn-container {
    align-items: flex-end;
    display: flex;
    height: 100%;
    margin-top: 0.7vh;
  }

  &__details-save-count {
    font-weight: $REGULAR;
  }

  &__details-bookmark,
  &__details-bookmark--filled {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    transform: translateY(10%);
    width: 0.8rem;

    .bookmark-filled-icon {
      color: $THEME_PINK;

      &:hover {
        opacity: 0.75;
      }
    }
  }
}