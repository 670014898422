@import "src/styles/Styles";

.pitch {
  display: flex;
  flex-direction: column;
  margin: 0 6rem;

  @include themify($themes) {
    color: themed('colorText');
  }

  @media (min-width: $SCREEN_SIZE_S) {
    align-self: center;
  }

  @media (max-width: $SCREEN_SIZE_XL) {
    margin: 0 $SPACING_L;
  }

  &__filter-ending-message {
    @include themify($themes) {
      color: themed('colorText');
    }

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: $SPACING_L 0;
    text-align: center;
    width: 100%;

    @media (min-width: $SCREEN_SIZE_XL) {
      justify-content: flex-end;
      width: 94%;
    }
  }

  &__filter-ending-message-block {
    & > p {
      font-size: $TEXT_L;
      text-align: left;
    }
  }

  &__filter-sponsor-message {
    display: flex;
    flex-direction: column;
    margin-left: 12rem;
    text-align: right;

    @include themify($themes) {
      color: themed('colorText');
    }

    & > p {
      font-size: $TEXT_L;
      font-weight: $LIGHT;
    }

    & > img {
      @include themify($themes) {
        filter: themed('invertLogoColor');
      }
    }
  }

  &__filter-sponsor-message-image {
    align-self: flex-end;
    width: $SPACING_4XL;
  }

  &__explanation {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: $SPACING_XXS;
    padding: $SPACING_M $SPACING_M $SPACING_S $SPACING_M;
    position: relative;

    @media (min-width: $SCREEN_SIZE_S) {
      justify-content: center;
    }

    @media (min-width: $SCREEN_SIZE_L) {
      padding: $SPACING_XXL $SPACING_L $SPACING_S $SPACING_L;
    }

    &-title {
      font-size: $TEXT_XXL;
      margin-left: $SPACING_XL;

      @media (max-width: $SCREEN_SIZE_M) {
        font-size: $TEXT_S;
        margin-left: 0;
        text-align: center;
      }

      @media (max-width: $SCREEN_SIZE_XL) {
        font-size: $TEXT_L;
        margin-left: 0;
        text-align: center;
      }
    }

    &-subtitle {
      @include themify($themes) {
        color: themed('colorText');
      }

      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      line-height: $TEXT_XL;
      margin-bottom: $SPACING_XXS;
      margin-left: $SPACING_XL;
      margin-top: 0;
      white-space: pre-line;

      @media (max-width: $SCREEN_SIZE_M) {
        font-size: $TEXT_XS;
        line-height: $TEXT_L;
        margin-left: 0;
        text-align: center;
        white-space: pre-line;
      }

      @media (max-width: $SCREEN_SIZE_XL) {
        font-size: $TEXT_S;
        line-height: $TEXT_L;
        margin-left: 0;
        text-align: center;
        white-space: pre-line;
      }
    }

    &-text {
      @include themify($themes) {
        color: themed('colorText');
      }

      font-size: $TEXT_S;
      font-weight: $LIGHT;
      line-height: $TEXT_S;
      margin-left: 0;
      margin-top: 0.6rem;
      max-width: 9rem;
      text-align: center;

      @media (min-width: $SCREEN_SIZE_M) {
        font-size: $TEXT_M;
        line-height: $TEXT_M;
        margin-left: $SPACING_M;
        margin-top: 0;
      }

      @media (min-width: $SCREEN_SIZE_L) {
        font-size: $TEXT_L;
        line-height: $TEXT_L;
        margin-left: 0;
        margin-top: 0;
        max-width: 13rem;
      }

      @media (min-width: $SCREEN_SIZE_XL) {
        font-size: $TEXT_L;
        line-height: $TEXT_L;
        margin-left: 1.3rem;
        margin-top: 0;
        max-width: 13rem;
      }

      &--hover {
        @include themify($themes) {
          cursor: themed('cursorPointerMegosu2');
        }
      }
    }

    &-readmore {
      @include themify($themes) {
        color: themed('colorText');
      }

      font-size: $TEXT_XS;
      font-weight: $LIGHT;
      line-height: $TEXT_XS;
      margin-top: 0;
      text-align: center;
      text-decoration: underline;

      @media (min-width: $SCREEN_SIZE_M) {
        font-size: $TEXT_XS;
        line-height: $TEXT_XS;
      }

      @media (min-width: $SCREEN_SIZE_L) {
        font-size: $TEXT_S;
        line-height: $TEXT_S;
      }

      &-subtitle {
        @include themify($themes) {
          color: themed('colorText');
        }

        font-size: $TEXT_XL;
        font-weight: $LIGHT;
        line-height: $TEXT_XL;
        margin-top: 0;
        text-align: center;
        text-decoration: underline;

        @media (max-width: $SCREEN_SIZE_M) {
          font-size: $TEXT_XS;
          line-height: $TEXT_L;
        }

        @media (max-width: $SCREEN_SIZE_XL) {
          font-size: $TEXT_S;
          line-height: $TEXT_L;
        }

        &-space {
          text-decoration: none;
        }
      }
    }
  }

  &__companies {
    display: flex;
    flex-direction: column;
    margin-left: $SPACING_XXL;
    padding: $SPACING_M $SPACING_M 0 $SPACING_M;

    @media (max-width: $SCREEN_SIZE_XL) {
      margin: 0;
    }

    @media (max-width: $SCREEN_SIZE_XS) {
      align-self: center;
      padding: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 $SPACING_XXL;

    @media (max-width: $SCREEN_SIZE_S) {
      padding: 0 $SPACING_M;
    }

    &-noresults {
      margin-top: $SPACING_XXS;
      padding-left: 3.75rem;
      position: relative;

      @media (max-width: $SCREEN_SIZE_S) {
        font-size: $TEXT_M;
        padding: 0;
        text-align: center;
      }

      @media (min-width: $SCREEN_SIZE_L) {
        padding-left: 3.75rem;
      }
    }
  }

  &__steps {
    justify-content: center;
    text-align: center;

    @media (max-width: $SCREEN_SIZE_S) {
      flex-direction: column;
    }
  }

  &__step-container {
    margin: $SPACING_XS;

    @media (min-width: $SCREEN_SIZE_XS) {
      margin: $SPACING_M;
    }
  }

  &__step {
    font-weight: $BOLDER;
    margin: 0.8rem 0;
    text-align: left;

    @media (max-width: $SCREEN_SIZE_S) {
      text-align: center;
    }
  }

  &__image-step-container {
    &-steps {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    @media (max-width: $SCREEN_SIZE_XL) {
      flex-direction: column;
    }
  }

  &__image-container {
    @media (max-width: $SCREEN_SIZE_S) {
      margin: 0 auto;
    }
  }

  &__step-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    width: auto;

    @media (min-width: $SCREEN_SIZE_M) {
      margin: $SPACING_M;
    }

    figcaption {
      font-size: $TEXT_XS;
      font-weight: $LIGHT;
      padding-top: $SPACING_XXS;
      text-align: right;
    }
  }

  &__step-image-wrapper {

    video,
    img {
      border-radius: $BORDER_M;

      color: transparent;
      height: 11rem;
      object-fit: cover;
      width: 9rem;

      @include themify($themes) {
        box-shadow: 0 0 6px 0 themed('boxShadowColor');
      }

      @media (max-width: $SCREEN_SIZE_XXS) {
        height: 12rem;
        width: 10rem;
      }

      @media (min-width: $SCREEN_SIZE_L) {
        height: 13rem;
        width: 11rem;
      }

      @media (min-width: $SCREEN_SIZE_XL) {
        height: 17rem;
        width: 14rem;
      }
    }
  }

  &__companies-filter-row {
    display: flex;
    flex-direction: row;
    gap: $SPACING_3XL;
    justify-content: center;
  }

  &__companies-pitch-container {
    display: flex;
    flex-direction: row;
  }

  &__companies-title-row {
    display: flex;
    flex-direction: row;
    gap: $SPACING_3XL;
    justify-content: flex-start;

    @media (max-width: $SCREEN_SIZE_M) {
      gap: $SPACING_L;
    }
  }

  &__companies-title-container {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  &__social-menu-boosted {
    border-radius: 0.2rem;
    bottom: 0.225rem;
    color: $WHITE;
    display: inline-block;
    font-size: $TEXT_XS;
    font-weight: bold;
    line-height: $TEXT_XS;
    margin-left: $SPACING_XXS;
    margin-right: $SPACING_S;
    margin-top: $SPACING_XXS;
    padding: $SPACING_3XS 0.25rem;
    position: relative;
    text-align: left;
    transition: $TRANSITION_MED;

    @include themify($themes) {
      background-color: themed('colorBoostedLabel');
    }

    @media (max-width: $SCREEN_SIZE_XL) {
      bottom: 0;
      font-size: $TEXT_XXS;
      line-height: $TEXT_XXS;
    }

    &:hover {
      background-color: $BLACK;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu2');
      }
    }
  }

  &__partner {
    display: flex;
    flex-direction: column;
    margin: 0;

    @media (min-width: $SCREEN_SIZE_S) {
      margin: $SPACING_M $SPACING_XXL $SPACING_XL $SPACING_3XL;
    }
  }

  &__partner-container {
    display: flex;
    flex-direction: column;
  }

  &__partner-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: $SCREEN_SIZE_XXS) {
      margin-left: $SPACING_XS;
    }

    @media (max-width: $SCREEN_SIZE_XL) {
      flex-direction: column;
    }
  }

  &__partner-wrapper-container {
    display: flex;
    flex-direction: row;
    gap: $SPACING_5XL;
    justify-content: flex-start;
    margin: $SPACING_M 0 $SPACING_XL $SPACING_XXL;
    max-width: 70rem;
    width: 100%;

    @media (max-width: $SCREEN_SIZE_XL) {
      flex-direction: column;
      gap: $SPACING_L;
      margin-left: 0;
    }

    @media (min-width: $SCREEN_SIZE_S) {
      margin: $SPACING_M $SPACING_XL $SPACING_XL 0;
    }

    @media (min-width: $SCREEN_SIZE_XXL) {
      gap: 4.5rem;
    }
  }

  &__partner-text-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @media (max-width: $SCREEN_SIZE_XL) {
      order: 2;
    }
  }

  &__partner-title {
    font-size: $TEXT_3XL;
  }

  &__partner-subtext {
    font-size: $TEXT_L;
    font-weight: $LIGHT;
    max-width: $WIDTH_L;

    @media (max-width: $SCREEN_SIZE_XL) {
      max-width: 100%;
    }
  }

  &__partner-image-container {
    @media (max-width: $SCREEN_SIZE_XL) {
      order: 1;
    }
  }

  &__partner-image-figure {
    border-radius: $BORDER_M;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: $SPACING_XS;
    width: 100%;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu2');
    }

    @media (max-width: $SCREEN_SIZE_XL) {
      margin: 0;
    }

    > figcaption {
      align-self: flex-end;
      color: $GRAY;
      font-size: $TEXT_XS;
      margin-right: $SPACING_3XS;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  &__partner-image {
    img {
      border-radius: $BORDER_M;
      height: 21rem;
      object-fit: cover;
      width: 100%;

      @media (max-width: $SCREEN_SIZE_XL) {
        height: auto;
      }
    }
  }


  &__sorting {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: $SPACING_3XL;
    width: $SPACING_4XL;

    @media (max-width: $SCREEN_SIZE_S) {
      margin-right: 0;
      margin-top: $SPACING_M;
    }

    @media (max-width: $SCREEN_SIZE_XL) {
      margin-right: 0;
    }
  }

  &__sorting-title {
    align-items: center;

    display: flex;
    font-size: $TEXT_M;
    font-weight: $REGULAR;
    margin: 0;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu2');
    }
  }

  &__sorting-title-icon {
    height: 0.8rem;
    margin-left: $SPACING_XXS;
    transition: all 0.3s;
    width: 0.8rem;

    &--reversed {
      transform: rotate(180deg);
    }
  }

  &__sorting-dropdown {
    border-radius: $BORDER_M;
    margin-top: $SPACING_XXL;
    opacity: 0;
    padding: $SPACING_S 0;
    position: absolute;
    transform: translateY(-20px);
    transition: all $TRANSITION_SHORT;
    visibility: hidden;
    z-index: 1;

    @include themify($themes) {
      background-color: themed('overlayColor');
      box-shadow: 0 0 6px 0 themed('boxShadowColor');
    }

    &--active {
      border-radius: $BORDER_M;

      margin-top: $SPACING_XXL;
      opacity: $OPACITY_HIGH;
      padding: $SPACING_S 0;
      position: absolute;
      transform: translateY(-20px);
      transition: all $TRANSITION_SHORT;
      visibility: visible;
      z-index: 1;

      @include themify($themes) {
        box-shadow: 0 0 6px 0 themed('boxShadowColor');
      }
    }

    &__item {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu2');
      }

      display: flex;
      flex-direction: row;
      font-size: $TEXT_S;
      font-weight: $LIGHT;
      padding: $SPACING_S $SPACING_M $SPACING_XS $SPACING_M;

      @include themify($themes) {
        color: themed('colorText');
      }

      @media (min-width: $SCREEN_SIZE_XL) {
        padding: $SPACING_S $SPACING_L $SPACING_XS $SPACING_L;
      }

      @media (max-width: $SCREEN_SIZE_S) {
        margin: 0 0 $SPACING_S 0;
        padding: $SPACING_S $SPACING_M $SPACING_XXS $SPACING_M;
      }

      &--active {
        font-weight: $REGULAR;
      }
    }
  }
}