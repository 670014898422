@import "../../../styles/Styles";

.file-card {
  &__content-container {
    aspect-ratio: 7/8;
    background-color: $GRAY_LIGHTEST;
    border-radius: $BORDER_S;

    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    @include themify($themes) {
      box-shadow: 0 0 0.6rem 0 themed('boxShadowColor');
    }
  }

  &__img-container {
    background-color: $GRAY_LIGHTEST;
    border-radius: $BORDER_S;

    padding-bottom: 114%;
    position: relative;
    width: 100%;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }
  }


  &__img,
  &__img-hover {
    border-radius: $BORDER_S;
    color: transparent;
    height: 100%;
    object-fit: cover;
    pointer-events: all;
    position: absolute;
    transition: 0.2s;
    width: 100%;

    @include themify($themes) {
      box-shadow: 0 0 0.6rem 0 themed('boxShadowColor');
    }
  }

  &__img-hover {
    opacity: 0;
    transition: opacity 0.3s;
    transition-delay: 0.1s;

    &:hover {
      opacity: $OPACITY_HIGH;
    }
  }

  &__element-container {
    align-items: center;
    border-radius: $BORDER_S;
    display: flex;
    justify-content: center;
    margin: auto;
  }

  &__element-text {
    object-fit: cover;
  }

  &__title-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    margin-top: $SPACING_XS;
  }

  &__title {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    font-size: $TEXT_M;
    font-weight: $REGULAR;
    margin-bottom: auto;
    word-break: break-word;
  }

  &__boosted {
    background-color: $GRAY_LIGHTER;
    border-radius: $BORDER_S;
    color: $WHITE;
    font-size: $TEXT_XS;
    font-weight: bold;
    margin-bottom: auto;
    margin-left: $SPACING_XXS;
    margin-top: $SPACING_3XS;
    padding: $SPACING_3XS $SPACING_XXS;
    transition: $TRANSITION_MED;

    @include themify($themes) {
      background-color: themed('colorBoostedLabel');
    }

    &:hover {
      background-color: $BLACK;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }
  }
}