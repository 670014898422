@import "../../../styles/Styles";

.spinner {
  &__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: $SPACING_M;
  }

  &__icon {
    -webkit-animation: icon-spin 2s infinite linear;
    animation: icon-spin 2s infinite linear;
    color: $THEME_PINK;
    font-size: 5rem;

    &--small {
      -webkit-animation: icon-spin 2s infinite linear;
      animation: icon-spin 2s infinite linear;
      color: $THEME_PINK;
      font-size: $TEXT_XXL;
    }

    &--megosu {
      transform: rotate(90deg) scaleX(1);
      width: 2rem;

      .cls-1 {
        animation: dash 2s ease-in-out infinite alternate;
        fill: none;
        stroke-dasharray: 1311.2235107421875;
        stroke-dashoffset: 1311.2235107421875;
        stroke-miterlimit: 10;
        stroke-width: 0.25rem;

        @include themify($themes) {
          stroke: themed('colorText');
        }
      }

      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }
    }

    @-webkit-keyframes icon-spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
      }
    }

    @keyframes icon-spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
      }
    }
  }

  &__loading-message {
    color: $BLACK;
    font-family: "Biotif";
    font-weight: $REGULAR;
    padding-left: $SPACING_L;
  }
}