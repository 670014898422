@import "../../../styles/Styles";

.admin-overview-form {
  &__header {
    display: flex;
    flex-direction: row;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &__container {
    margin-top: $SPACING_3XL;
  }

  &__items {
    display: flex;
    flex-direction: row;

    &-name {
      font-weight: $MEDIUM;
      margin-left: $SPACING_M;
      margin-top: 0;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  &__noresults {
    display: flex;
    font-size: $TEXT_XL;
    margin: 0 auto;
    padding: $SPACING_XXL 0 $SPACING_4XL 0;
    text-align: center;
  }

}