@import "../../styles/Styles";

.chat-page {
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  @media (min-width: $SCREEN_SIZE_M) {
    padding-bottom: $SPACING_3XL  !important;
    padding-top: $SPACING_3XL  !important;
  }

  @media (max-width: $SCREEN_SIZE_M) {
    padding-bottom: 0rem;
  }

  &__tips-download-container {
    flex-grow: 1;
    max-width: 15rem;
    min-width: 15rem;
  }

  &__tips-download-container-no-chat {
    flex-grow: 1;
    max-width: 55rem;
    min-width: 55rem;
  }

  &__container {
    display: flex;
    justify-content: center;

    @media (min-width: $SCREEN_SIZE_S) {
      margin-top: 5rem;
    }
  }

  &__background {
    background-attachment: fixed;
    background-image: url("../../assets/images/Elias-Julian-Kopp.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-grow: 1;
  }
}