@import "src/styles/Styles";

.checkbox {
  @include themify($themes) {
    cursor: themed('cursorPointerMegosu3');
  }

  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox > input {
  @include themify($themes) {
    cursor: themed('cursorPointerMegosu3');
  }

  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkbox input:checked ~ .checkmark {
  background-color: $BLACK;
  border-color: $BLACK;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  border: solid white;
  border-width: 0 3px 3px 0;
  height: 0.55rem;
  left: 0.2rem;
  top: 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  width: 0.275rem;
}

.checkmark {
  background-color: $WHITE;
  border: 1px solid $GRAY_LIGHT;
  border-radius: $BORDER_S;
  box-sizing: border-box;
  height: 1rem;
  position: absolute;
  width: 1rem;

  &:hover {
    background-color: $GRAY_LIGHTEST;
  }
}

.checkmark:after {
  content: "";
  display: none;
  position: absolute;
}