@import "../../styles/Styles";

.success {
  display: flex;
  flex-direction: column;
  margin: 0 12rem;

  @include themify($themes) {
    color: themed('colorText');
  }

  @media (min-width: $SCREEN_SIZE_S) {
    align-self: center;
  }

  @media (min-width: $SCREEN_SIZE_XL) {
    max-width: 68rem;
  }

  @media (max-width: $SCREEN_SIZE_XL) {
    margin: 0 $SPACING_L;
  }

  &__title {
    font-weight: $MEDIUM;
    margin-bottom: $SPACING_M;
    margin-top: $SPACING_4XL;
  }
}