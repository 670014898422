@import "src/styles/Styles";

.post-upload-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $SPACING_XXL 0;
  width: 100%;

  @media(max-width: $SCREEN_SIZE_XXL) {
    padding-bottom: 0;
  }

  &__go-back {
    color: $THEME_PINK;

    display: flex;
    margin-bottom: $SPACING_M;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    span {
      padding-left: $SPACING_3XS;
    }
  }

  &__title-container {
    margin-bottom: $SPACING_M;
    text-align: left;

    .post-upload-form__title {
      font-size: $TEXT_3XL;
      font-weight: $MEDIUM;
      margin: 0 0 $SPACING_XXS 0;
    }

    span {
      font-size: $TEXT_L;
      font-weight: $LIGHT;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__content-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;

    > * {
      flex: 1;
    }

    > div:first-of-type {
      margin-right: $SPACING_3XS;
    }

    > div:nth-of-type(2) {
      margin-right: $SPACING_3XS;
    }
  }

  &__measurement-container {
    display: flex;
    flex: 1;
    flex-direction: row;

    > div:first-of-type {
      display: flex;
      flex: 1;
      flex-direction: column;
      min-width: 0;

      .post-upload-form-size-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        min-width: 0;

        > div {
          display: flex;
          flex: 1;
          flex-direction: column;
          min-width: 0;

          > input,
          span {
            flex: 1;
            min-width: 0;
            text-align: center;
          }
        }
      }
    }

    > div:nth-of-type(2) {
      flex: 1;
      min-width: 0;

      > input {
        text-align: center;
      }
    }
  }

  &__label {
    padding: 0 $SPACING_XXS $SPACING_3XS 0;
    text-align: left;
  }

  &__input-container {
    display: flex;
    flex-direction: column;

    @media (min-width: $SCREEN_SIZE_S) {
      margin: $SPACING_XXS 0;
    }

    .post-upload-form__team-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      min-width: 0;

      > input {
        flex: 1;
        min-width: 0;
      }
    }

    .post-upload-form__market-container {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      > label {
        align-items: center;
        display: flex;
      }
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    margin-top: $SPACING_M;

    .post-upload-form__submit {
      align-self: center;
      background-color: $THEME_PINK;
      border: none;
      border-radius: $BORDER_S;
      color: $WHITE;
      font-size: $TEXT_L;
      font-weight: $MEDIUM;
      min-width: $FORM_MIN_WIDTH;
      padding: $SPACING_S;
    }
  }
}

.post-upload-form-container {
  border-radius: $BORDER_S;
  display: flex;
  flex-direction: column;
  padding: $SPACING_L $SPACING_XXL;
  text-align: center;
  width: 70%;

  @include themify($themes) {
    background-color: themed('colorBackground');
    color: themed('colorText');
  }

  @media (min-width: $SCREEN_SIZE_XS) {
    max-width: $WIDTH_L;
    padding: $SPACING_XXL $SPACING_4XL;
  }
}