@import "src/styles/Styles";

.related-projects-overview {
  flex-direction: column;
  padding-top: $SPACING_4XL;

  @media (min-width: $SCREEN_SIZE_M) {
    padding-top: $SPACING_5XL;
  }

  &__title {
    font-size: $TEXT_XL;
    font-style: normal;
    font-weight: $MEDIUM;
    margin: 0 0 $SPACING_M $SPACING_S;
  }

  &__container {
    flex-direction: column;
  }

  &__content-container {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(1, 1fr);
    height: 100%;
    padding-bottom: $SPACING_L;

    @media (min-width: $SCREEN_SIZE_XS) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $SCREEN_SIZE_M) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__arrow-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: $SPACING_M 0;
  }
}

.arrow-icon {
  color: $BLACK;

  height: 2rem;
  margin-bottom: $SPACING_M;
  width: 2rem;

  @include themify($themes) {
    cursor: themed('cursorPointerMegosu3');
  }
}