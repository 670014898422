@import "../../../styles/Styles";

.project-details-card {
  align-self: flex-start;
  display: block;
  min-width: 15rem;
  padding: $SPACING_M 0 $SPACING_XXL 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  @include themify($themes) {
    color: themed('colorText');
  }

  @media (min-width: $SCREEN_SIZE_M) {
    padding-top: $SPACING_XXL;
    width: 19rem;
  }

  @media (min-width: $SCREEN_SIZE_XL) {
    padding: $SPACING_5XL $SPACING_L $SPACING_L 0;
    width: 21rem;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $SCREEN_SIZE_M) {
      padding-top: $SPACING_XXL;
    }

    @media (min-width: $SCREEN_SIZE_L) {
      padding-top: $SPACING_4XL;
    }

    @media (min-width: $SCREEN_SIZE_XL) {
      padding-top: 0;
    }

    & > div {
      margin: $SPACING_S 0;
    }
  }

  &__info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--mobile {
      margin-top: 0.5rem;
    }
  }

  &__save-btn-container {
    align-items: flex-end;
    display: flex;
    height: 100%;
  }

  &__bookmark,
  &__bookmark--filled {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    width: 0.8rem;

    .bookmark-filled-icon {
      color: $THEME_PINK;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  &__save-count {
    font-weight: $REGULAR;
    margin-right: $SPACING_3XS;
  }

  &__project-info-container {
    flex-direction: column;

    .tag-element {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: $SPACING_XXS;

      button {
        color: $BLACK;
        font-size: $TEXT_S;
        font-style: normal;
        font-weight: $LIGHT;
        margin-bottom: $SPACING_XXS;
        margin-right: $SPACING_XXS;

        @include themify($themes) {
          background-color: themed('colorBackground');
          color: themed('colorText');
        }
      }
    }
  }

  &__header {
    display: flex;
  }

  &__title {
    @include themify($themes) {
      color: themed('colorText');
    }

    font-size: $TEXT_L;
    font-style: normal;
    font-weight: $MEDIUM;
    line-height: 1.1rem;
    word-break: break-word;

    .bookmark-icon {
      float: right;
    }
  }

  &__sub-title {
    @include themify($themes) {
      color: themed('colorText');
    }

    font-size: $TEXT_M;
    font-style: normal;
    font-weight: normal;
  }

  &__info-description {
    @include themify($themes) {
      color: themed('colorText');
    }

    font-size: $TEXT_S;
    font-style: normal;
    font-weight: $LIGHT;

    &--link {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      font-weight: $REGULAR;
    }
  }

  &__title {
    @include themify($themes) {
      color: themed('colorText');
    }

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    font-size: $TEXT_L;
    font-weight: $MEDIUM;
    margin-bottom: $SPACING_XXS;

    > span {
      font-size: $TEXT_M;
    }
  }

  &__boosted {
    background-color: $THEME_PINK;
    border-radius: $BORDER_S;
    color: $WHITE;

    font-size: $TEXT_XS;
    font-weight: bold;
    margin-bottom: auto;
    margin-left: $SPACING_XXS;
    margin-top: $SPACING_3XS;
    padding: $SPACING_3XS $SPACING_XXS;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    &:hover {
      opacity: 0.75;
    }
  }

  &__info-location {
    align-items: center;
    display: flex;
    display: flex;
    font-size: $TEXT_S;
    font-weight: $LIGHT;
    margin-top: $SPACING_3XS;

    svg {
      color: $GRAY_LIGHT;
      margin-right: $SPACING_3XS;
    }
  }

  &__button-container {
    display: flex;
    justify-content: space-between;

    @media (min-width: $SCREEN_SIZE_M) {
      flex-direction: column;
    }

    &--feedback {
      flex: 1;

      @media (max-width: $SCREEN_SIZE_M) {
        margin-right: $SPACING_S;
      }

      @media (min-width: $SCREEN_SIZE_M) {
        margin-bottom: $SPACING_S;
      }

      > button {
        width: 100%;

        @media (min-width: $SCREEN_SIZE_M) {
          margin-left: -$SPACING_3XS;
        }
      }
    }

    > button {
      flex: 1;
      margin-bottom: $SPACING_S;
    }

    > button:first-of-type {
      @media (min-width: $SCREEN_SIZE_M) {
        margin-bottom: $SPACING_S;
      }
    }

    > button:nth-of-type(2) {
      @media (max-width: $SCREEN_SIZE_M) {
        margin-left: $SPACING_S;
        margin-right: $SPACING_S;
      }
    }

    > a {
      font-size: $TEXT_M;
      font-weight: $REGULAR;
      height: 100%;
      width: 100%;
    }
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .project-details-card__list-item {
      margin: 0 $SPACING_XXS;

      a {
        color: $BLACK;

        svg {
          height: 1.6rem;
          width: 1.6rem;
        }

        @media (min-width: $SCREEN_SIZE_S) {
          svg {
            height: 1.4rem;
            width: 1.4rem;
          }
        }
      }
    }

    @media (max-width: $SCREEN_SIZE_XS) {
      justify-content: center;
    }

    @media (min-width: $SCREEN_SIZE_M) {
      justify-content: center;
    }
  }

  &__metrics {
    padding-right: $SPACING_L;
  }

  &__designer-info {
    justify-content: left;
  }

  &__designYear {
    font-size: $TEXT_L;
    font-weight: $LIGHT;
  }
}