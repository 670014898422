@import "../../../styles/Styles";

.company-card {
  display: flex;
  flex-direction: column;
  margin: 0;

  @include themify($themes) {
    color: themed('colorText');
  }

  @media (min-width: $SCREEN_SIZE_S) {
    align-self: center;
  }

  @media (max-width: $SCREEN_SIZE_XL) {
    margin: 0 $SPACING_L;
  }

  &__content-container {}

  &__info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: $SCREEN_SIZE_XXS) {
      margin-left: $SPACING_XS;
    }
  }

  &__logo-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 1.5rem;

    @media (max-width: $SCREEN_SIZE_XXS) {
      margin-right: $SPACING_XS;
    }
  }

  &__images {
    justify-content: center;
    text-align: center;

    @media (max-width: $SCREEN_SIZE_S) {
      flex-direction: column;
    }
  }

  &__image-container {
    @media (max-width: $SCREEN_SIZE_S) {
      margin: 0 auto;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      flex-direction: row;
    }
  }

  &__image-company-container {
    &-images {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: $SPACING_XL;
      margin-top: $SPACING_M;
      width: 100%;
    }
  }

  &__company-image-container {
    display: flex;
    justify-content: center;
    margin: 0 $SPACING_XS 0 $SPACING_XS;
    width: auto;

    @media (max-width: $SCREEN_SIZE_XS) {
      margin: 0;
    }

    figcaption {
      font-size: $TEXT_XS;
      font-weight: $LIGHT;
      padding-top: $SPACING_XXS;
      text-align: right;
    }
  }

  &__company-image-wrapper {
    @media (max-width: $SCREEN_SIZE_XXS) {
      margin-left: 0.5rem;
    }

    img {
      border-radius: $BORDER_L;

      color: transparent;
      height: 11rem;
      object-fit: cover;
      width: 9rem;

      @include themify($themes) {
        box-shadow: 0 0 $SPACING_M 0 themed('boxShadowColor');
      }

      @media (max-width: $SCREEN_SIZE_S) {
        aspect-ratio: 16/9;

        height: 15rem;
        width: 100%;

        @include themify($themes) {
          box-shadow: 0 0 $SPACING_XS 0 themed('boxShadowColor');
        }
      }

      @media (min-width: $SCREEN_SIZE_L) {
        height: 13rem;
        width: 11rem;
      }

      @media (min-width: $SCREEN_SIZE_XL) {
        height: 17rem;
        width: 14rem;
      }
    }
  }

  &__company-image-wrapper-logo {
    display: flex;

    img {
      aspect-ratio: 1;
      border-radius: $BORDER_L;

      color: transparent;
      height: 5rem;
      object-fit: cover;
      width: 7rem;

      @include themify($themes) {
        box-shadow: 0 0 $SPACING_XS 0 themed('boxShadowColor');
      }

      @media (max-width: $SCREEN_SIZE_S) {
        @include themify($themes) {
          box-shadow: 0 0 $SPACING_XS 0 themed('boxShadowColor');
        }

        height: auto;
        margin-top: 0;
      }

      @media (min-width: $SCREEN_SIZE_L) {
        height: 7rem;
        margin-top: 0;
        width: 8rem;
      }

      @media (min-width: $SCREEN_SIZE_XL) {
        height: 7rem;
        margin-top: 1.7rem;
        width: 7rem;
      }
    }
  }

  &__pitch-button-container {
    align-self: center;
  }

  &__pitch-button {
    font-size: $TEXT_S;
    font-weight: $REGULAR;
    margin: 0 $SPACING_XXS;
    min-width: 7rem;
    padding: $SPACING_XXS;

    @media (max-width: $SCREEN_SIZE_XXS) {
      min-width: 6rem;
      padding: 0.3rem;
    }
  }

  &__company-name {
    font-size: $TEXT_S;
    font-weight: $MEDIUM;
    line-height: $TEXT_L;
    margin: $SPACING_XS 0 0.3rem 0;
    max-width: 8rem;
    padding: 0;
    text-align: center;
    word-break: break-word;

    @media (max-width: $SCREEN_SIZE_XXS) {
      line-height: $TEXT_M;
      margin: $SPACING_XS 0 0.1rem 0;
    }

    @media (min-width: $SCREEN_SIZE_XL) {
      font-size: $TEXT_M;
      font-weight: $MEDIUM;
      line-height: $TEXT_XL;
    }
  }

  &__company-country {
    font-size: $TEXT_S;
    font-weight: $LIGHT;
    line-height: $TEXT_L;
    margin: 0.1rem 0 0.8rem 0;
    padding: 0;

    @media (max-width: $SCREEN_SIZE_XXS) {
      line-height: $TEXT_M;
      margin: 0.1rem 0 $SPACING_XS 0;
    }

    @media (min-width: $SCREEN_SIZE_XL) {
      font-size: $TEXT_M;
      font-weight: $LIGHT;
      line-height: $TEXT_XL;
    }
  }
}

.reveal.active {
  opacity: $OPACITY_HIGH;
  transform: translateY(0);
}