.error-info {
  display: flex;
  height: 100vh;
  padding: 0 !important;
  width: 100%;

  &__container {
    margin: auto;
    text-align: center;
  }

  &__btn-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
  }

  &__btn {
    padding: 0.625rem 2rem 0.625rem 2rem;
  }
}