@import "src/styles/Styles";

.comment-section {
  display: flex;
  flex-direction: column;
  margin-top: $SPACING_L;

  &__comments > li:nth-child(1) {
    margin-top: $SPACING_S;
  }

  &__comments-item {
    display: flex;
    margin: $SPACING_L 0;
    padding: 0 $SPACING_S;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__user-image {
    background-position: center center;
    background-size: cover;
    border: 1px solid $BLACK;
    border-radius: 100%;

    height: 2.4rem;
    margin: auto;
    margin-right: 0.75rem;
    margin-top: 0.4rem;
    width: 2.4rem;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }
  }

  &__designer-info {
    display: flex;
  }

  &__comment-header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    border-bottom: 1px solid $GRAY_LIGHTER;
    font-weight: $MEDIUM;
    margin-bottom: 0;
    margin-left: $SPACING_XS;
    padding-bottom: $SPACING_M;
  }

  &__comment-name {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    font-size: $TEXT_M;
    margin: 0;
  }

  &__comment-date {
    align-self: center;
    color: $GRAY_LIGHT;
  }

  &__comment-content {
    font-weight: $LIGHT;
    min-width: 100%;
    overflow-wrap: break-word;
    white-space: pre-wrap;

    @media (max-width: $SCREEN_SIZE_XS) {
      max-width: 10rem;
    }
  }

  &__no-comments {
    padding: $SPACING_M 0;

    &-text {
      font-size: $TEXT_L;
      margin: 0;
      margin-left: $SPACING_M;
    }
  }

  &__show-more-comments {
    color: $BLACK;
    font-weight: $MEDIUM;
    margin-bottom: $SPACING_L;
    margin-top: $SPACING_M;
    text-align: center;
    text-decoration: underline;
  }

  &__post-comment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 97%;

    .button {
      align-self: flex-end;

      margin-bottom: $SPACING_M;
      margin-top: $SPACING_M;
      max-width: 6rem;
      min-width: 4rem;
    }
  }

  &__message-input {
    height: 5rem;
  }

  &__delete-icon {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }
  }

  .input-error {
    border: 1px solid $RED;
  }

  .form-error {
    color: $RED;
    font-size: $TEXT_S;
    padding-bottom: $SPACING_XS;
    text-align: left;
  }
}