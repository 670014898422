@import "../../../styles/Styles";

.profile-card {
  display: flex;
  flex-direction: column;
  padding: $SPACING_M 0;
  width: auto;

  @media (min-width: $SCREEN_SIZE_M) {
    padding-top: $SPACING_4XL;
    width: 19rem;
  }

  @media (min-width: $SCREEN_SIZE_XL) {
    padding: $SPACING_5XL $SPACING_L 0 0;
    width: 21rem;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: $SPACING_S;

    @media (min-width: $SCREEN_SIZE_XL) {
      padding-top: 0;
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;

    @media (min-width: $SCREEN_SIZE_M) {
      padding-top: 0;
    }

    @media (min-width: $SCREEN_SIZE_L) {
      padding-top: $SPACING_M;
    }

    @media (min-width: $SCREEN_SIZE_XL) {
      padding-top: 0;
    }
  }

  &__name-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: $SPACING_XXS;
  }

  &__title {
    font-size: $TEXT_M;
    font-style: normal;
    font-weight: $REGULAR;
    line-height: 1.1rem;
    margin-bottom: $SPACING_XXS;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &__name {
    font-size: $TEXT_L;
    font-weight: $MEDIUM;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &__boosted {
    background-color: $THEME_PINK;
    border-radius: $BORDER_S;
    color: $WHITE;

    font-size: $TEXT_XS;
    font-weight: bold;
    margin-bottom: auto;
    margin-left: $SPACING_XXS;
    margin-top: $SPACING_3XS;
    padding: $SPACING_3XS $SPACING_XXS;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    &:hover {
      opacity: 0.75;
    }
  }

  &__location,
  &__biography,
  &__education,
  &__experience,
  &__job {
    align-items: center;
    display: flex;
    font-size: $TEXT_S;
    font-style: normal;
    font-weight: $LIGHT;
    line-height: 1.1rem;
    margin-bottom: $SPACING_M;
    word-break: break-word;

    @include themify($themes) {
      color: themed('colorText');
    }

    svg {
      color: $GRAY_LIGHT;
      margin-right: $SPACING_3XS;
    }

    a > svg {
      font-size: $TEXT_S;
    }
  }

  &__age {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
  }

  &__education-container,
  &__experience-container,
  &__job-container {
    display: flex;
    flex-direction: column;
  }

  &__img-wrapper {
    border-radius: $BORDER_S;
    margin-bottom: $SPACING_S;
    padding-bottom: 100%;
    position: relative;
  }

  &__img {
    background-color: $GRAY_LIGHTEST;
    border-radius: $BORDER_S;
    color: transparent;
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;

    @include themify($themes) {
      box-shadow: themed('boxShadowColor');
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;

    @media (min-width: $SCREEN_SIZE_M) {
      flex-direction: column;
    }

    > button {
      flex: 1;
    }

    > button:first-of-type {
      @media (min-width: $SCREEN_SIZE_M) {
        margin-bottom: $SPACING_S;
      }
    }

    > button:nth-of-type(2) {
      @media (max-width: $SCREEN_SIZE_M) {
        margin-left: $SPACING_S;
      }
    }

    > a {
      margin-left: $SPACING_3XS;

      @media (max-width: $SCREEN_SIZE_M) {
        margin-left: $SPACING_S;
        margin-top: $SPACING_XL;
      }
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    margin-top: $SPACING_M;
    width: 100%;

    .profile-card__list-item {
      margin: 0 $SPACING_XXS;

      a {
        @include themify($themes) {
          color: themed('colorText');
        }

        svg {
          height: 1.6rem;
          transition: none;
          width: 1.6rem;
        }

        @media (min-width: $SCREEN_SIZE_S) {
          svg {
            height: 1.4rem;
            width: 1.4rem;
          }
        }
      }
    }

    @media (min-width: $SCREEN_SIZE_M) {
      margin-top: $SPACING_M;
    }
  }

  &__add-socials-btn {
    display: flex;
    justify-content: center;
    margin-bottom: $SPACING_XXS;
    margin-top: $SPACING_S;
    text-decoration: underline;
    text-underline-offset: 6px;

    @include themify($themes) {
      color: themed('colorText');
    }

    &:hover {
      font-weight: $MEDIUM;
    }
  }
}