@import "../../styles/Styles";

.upload-project {
  background-attachment: fixed;
  background-image: url("../../assets/images/Ashley-Willard.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-grow: 1;

  .popup__container {
    z-index: 0;
  }
}

.uploaded-project {
  .popup__elements-container {
    padding: $SPACING_3XL $SPACING_XL $SPACING_3XL $SPACING_3XL;

    @media (min-width: $SCREEN_SIZE_XS) {
      max-width: $WIDTH_L;
    }
  }

  .form-popup--project-uploaded {
    @media (min-width: $SCREEN_SIZE_XS) {
      padding: $SPACING_M $SPACING_XL $SPACING_XL $SPACING_XL;
    }
  }
}
