@import "src/styles/Styles";

.label-set {
  display: flex;
  flex-direction: column;
  padding: $SPACING_S 0;

  &__title-container {
    display: flex;
    flex-direction: row;
    font-size: $TEXT_3XL;
    justify-content: space-between;
    margin-bottom: $SPACING_XXS;
    text-transform: capitalize;

    .label-set__subtitle {
      color: $GRAY_LIGHT;
      font-size: $TEXT_XS;
      text-transform: none;
    }
  }

  &__labels-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .label-set__label-container {
      margin-right: $SPACING_XXS;

      .label-set__label {
        align-items: center;
        display: flex;
        font-weight: $LIGHT;
        justify-content: center;
        padding: $SPACING_S $SPACING_M;
        position: relative;
        white-space: nowrap;

        > span {
          color: $GRAY_LIGHT;
          pointer-events: none;
          z-index: 1;
        }

        > input {
          appearance: none;
          border: solid 1px $GRAY_LIGHTEST;
          height: 100%;
          margin: 0 auto;
          position: absolute;
          width: 100%;

          @include themify($themes) {
            background-color: themed('labelColor');
          }

          &:hover {
            background-color: $GRAY_LIGHTER;
            border: solid 1px $GRAY_LIGHTER;
          }

          &:checked {
            background-color: $WHITE;
            border: solid 1px $BLACK;

            & + .label-set__label-name {
              color: $BLACK;
            }

            &:hover {
              background-color: $WHITE;
              border: solid 1px $BLACK;
            }
          }
        }
      }
    }
  }
}