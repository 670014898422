@import "styles/Styles";

.App {
  display: flex;
  flex-direction: column;

  > div:nth-of-type(2) {
    @media(max-width: $SCREEN_SIZE_XXL) {
      padding-bottom: $SPACING_4XL;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 6.375rem;
    transition: all 0.4s;

    @include themify($themes) {
      background-color: themed('colorBackground');
    }

    @media (max-width: $SCREEN_SIZE_S) {
      padding-top: 7.875rem;
    }

    &--no-bar {
      padding-top: 3.875rem;
    }
  }
}