@import "src/styles/Styles";

.setting-menu {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 5rem;
  margin-right: $SPACING_S;
  padding: 5rem 0 $SPACING_XXL 0;
  width: 20%;

  @media only screen and (max-width: $SCREEN_SIZE_M) {
    margin-left: 0rem;
  }

  @media only screen and (max-width: $SCREEN_SIZE_S) {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    font-size: $TEXT_XL;
    height: 100%;
    margin-left: 5rem;
    margin-top: $SPACING_XL;
    width: 70%;
  }

  @media (min-width: 2000px) {
    margin-left: 15rem;
    width: 25%;
  }

  &__inactive {
    visibility: hidden;
  }

  &__items {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    font-weight: $LIGHT;
    margin-top: $SPACING_XXS;

    &:hover {
      font-weight: $REGULAR;
    }
  }

  &__items-active {
    font-weight: $MEDIUM;
  }
}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 46rem;
  padding: $SPACING_XXL 0 $SPACING_XXL 0;

  @media only screen and (max-width: $SCREEN_SIZE_M) {
    margin-left: 1rem;
    min-width: 0rem;
    width: 70%;
  }

  @media only screen and (max-width: $SCREEN_SIZE_S) {
    align-items: center;
    margin-left: 1rem;
    margin-top: 2rem;
    min-width: 18rem;
    width: 90%;
  }

  @media (min-width: 2000px) {
    width: 30%;
  }

  &__account-setup {
    width: 100%;
  }

  &__paragraph {
    font-size: $TEXT_M;
    text-align: left;
  }

  &__social-menu-boosted {
    border-radius: $BORDER_S;
    color: $WHITE;
    display: inline-block;
    font-size: $TEXT_XS;
    font-weight: bold;
    margin-bottom: auto;
    margin-left: $SPACING_3XS;
    margin-top: $SPACING_3XS;
    padding: $SPACING_3XS $SPACING_XXS $SPACING_3XS $SPACING_XXS;
    text-align: left;
    transition: $TRANSITION_MED;

    @include themify($themes) {
      background-color: themed('colorBoostedLabel');
    }

    &:hover {
      background-color: $BLACK;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }
  }

  &__social-icon-boosted {
    border-radius: $BORDER_S;
    color: $WHITE;
    display: inline-block;
    font-size: $TEXT_XS;
    font-weight: bold;
    margin-bottom: auto;
    margin-top: $SPACING_3XS;
    padding: $SPACING_3XS $SPACING_XXS;
    text-align: center;
    transition: $TRANSITION_MED;
    width: 5%;

    @include themify($themes) {
      background-color: themed('colorBoostedLabel');
    }

    &:hover {
      background-color: $BLACK;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }
  }

  &__checked-input-container {
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: $SPACING_XXS;
    text-align: left;

    > span {
      color: $RED;
      font-size: $TEXT_S;
      margin-left: $SPACING_XS;
    }
  }

  &__title-container {
    margin-bottom: $SPACING_M;

    .form__title {
      font-size: $TEXT_3XL;
      font-weight: $MEDIUM;
      margin: 0;
    }
  }

  &__background {
    height: 100%;
    width: 100%;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__contentbox-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @media (min-width: $SCREEN_SIZE_S) {
      flex-direction: row;
    }

    > div:first-of-type {
      @media (min-width: $SCREEN_SIZE_S) {
        margin-right: $SPACING_M;
      }
    }

    > div:nth-of-type(2) {
      margin-right: $SPACING_XXS;
    }
  }

  &__content-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 16rem;

    > .form__input-container,
    .form__name-container {
      margin: $SPACING_S 0;

      @media (max-width: $SCREEN_SIZE_S) {
        margin: $SPACING_XXS 0;
      }
    }

    > .form__input-container:last-child {
      margin-bottom: $SPACING_XXS;

      @media (max-width: $SCREEN_SIZE_S) {
        margin-bottom: $SPACING_M;
      }
    }
  }

  &__date-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    min-width: 0;

    > * {
      display: flex;
      flex: 1;
      flex-direction: row;
      min-width: 0;
    }

    ::placeholder {
      text-transform: uppercase;
    }

    > div:first-child {
      margin-right: $SPACING_XXS;
    }

    > div:last-child {
      margin-left: $SPACING_XXS;
    }

    > div {
      > input:first-of-type {
        margin-right: $SPACING_3XS;
      }

      > input:nth-of-type(2) {
        margin: 0 $SPACING_3XS;
      }

      > input {
        flex: 1;
        min-width: 0;
        text-align: center;
      }
    }

    > input {
      margin: 0 $SPACING_3XS;
      text-align: center;

      @media (max-width: $SCREEN_SIZE_S) {
        width: 100%;
      }
    }
  }

  &__image-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    > .form__input-container {
      height: 100%;
    }

    .form__image-upload {
      background-color: pink;
      height: 100%;
      width: 100%;
    }
  }

  &__title {
    margin: 0;
  }

  &__title-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  &__registration {
    font-size: $TEXT_S;
  }

  &__button {
    background-color: $THEME_PINK;
    color: $WHITE;
  }

  &__label {
    padding: 0 $SPACING_XXS 0 0;
    text-align: left;
  }

  &__error {
    color: $RED;
    font-size: $TEXT_S;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: $SPACING_M;

    @media (min-width: $SCREEN_SIZE_S) {
      margin: $SPACING_XXS 0;
    }
  }

  &__input,
  textarea {
    font-weight: $LIGHT;

    &--error {
      border: 1px solid $RED;
    }

    &--email {
      font-size: $TEXT_M;
      font-weight: $LIGHT;
      margin: 0;
      text-align: left;
    }

    &:hover {
      background-color: $GRAY_LIGHTER;
      transition: all $TRANSITION_SHORT;
    }
  }

  &__label-container {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
    }
  }

  &__description-label {
    color: $GRAY;
    font-size: $TEXT_S;
    font-weight: $LIGHT;
  }

  &__university-container {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin: $SPACING_XXS 0;
    width: 100%;

    @media (max-width: $SCREEN_SIZE_S) {
      flex-direction: column;
    }

    > div {
      margin: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .form__input-container {
      width: 100%;

      @media (max-width: $SCREEN_SIZE_S) {
        margin-bottom: 0;
      }

      > div {
        align-items: flex-start;
        flex-direction: row;

        @media (max-width: $SCREEN_SIZE_S) {
          flex-direction: column;
        }
      }
    }

    .form__university-dropdown {
      display: block;
      width: 100%;
    }

    .form__university-dates {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: $SPACING_XXS;

      @media (max-width: $SCREEN_SIZE_S) {
        margin: $SPACING_S 0;
      }

      > div {
        margin-bottom: 0;
      }

      .form__input-container {
        margin-top: 0;
        width: unset;

        &:first-of-type {
          letter-spacing: 0;
        }

        > input {
          width: 8rem;
        }
      }
    }

    .checked-dropdown__container {
      align-items: center;
      flex-direction: row;
      margin-top: 0;

      @media (min-width: $SCREEN_SIZE_S) {
        &:first-of-type {
          margin-right: $SPACING_XXS;
        }
      }
    }
  }

  &__job-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $SCREEN_SIZE_XS) {
      flex-wrap: wrap;
    }

    .form__job-btn-container {
      align-items: center;
      display: flex;
      height: 2rem;
      justify-content: center;
      margin-top: $SPACING_XXS;

      @media (max-width: $SCREEN_SIZE_XS) {
        margin: $SPACING_XXS $SPACING_XXS $SPACING_XXS 0;
      }

      .form__job-btn {
        align-items: center;
        color: $GRAY_LIGHT;
        display: flex;
        font-weight: $LIGHT;
        justify-content: center;
        padding: $SPACING_S $SPACING_XXS;
        position: relative;
        width: 6rem;

        @media (max-width: $SCREEN_SIZE_S) {
          width: 4rem;
        }

        > span {
          pointer-events: none;
          z-index: 1;
        }

        > input {
          appearance: none;
          border: solid 1px $GRAY_LIGHTEST;
          height: 100%;
          margin: 0 auto;
          position: absolute;
          width: 100%;

          @include themify($themes) {
            background-color: themed('labelColor');
          }

          &:hover {
            background-color: $GRAY_LIGHTER;
            border: solid 1px $GRAY_LIGHTER;
          }

          &:checked {
            background-color: $WHITE;
            border: solid 1px $BLACK;

            & + .form__job-label {
              color: $BLACK;
            }

            &:hover {
              background-color: $WHITE;
              border: solid 1px $BLACK;
            }
          }
        }
      }
    }
  }

  &__name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 $SPACING_4XL;

    @media (min-width: $SCREEN_SIZE_S) {
      margin: $SPACING_XXS 0;
    }

    @media (max-width: $SCREEN_SIZE_XS) {
      flex-direction: column;
    }

    div {
      margin: 0;
      min-width: 0;
      width: 100%;

      @media (min-width: $SCREEN_SIZE_S) {
        margin: initial;
      }
    }

    > div:first-of-type {
      padding-right: $SPACING_XXS;

      @media (max-width: $SCREEN_SIZE_XS) {
        margin-bottom: $SPACING_M;
        padding-right: 0;
      }
    }

    > div:nth-of-type(2) {
      padding-left: $SPACING_XXS;

      @media (max-width: $SCREEN_SIZE_XS) {
        padding-left: 0;
      }
    }
  }

  &__terms {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: $SPACING_XXS $SPACING_4XL;

    @media (min-width: $SCREEN_SIZE_S) {
      margin: $SPACING_M 0;
    }

    div:first-of-type {
      margin-right: $SPACING_3XS;
    }

    :nth-child(2) {
      margin-left: $SPACING_3XS;
    }
  }

  &__terms-label {
    text-align: left;
  }

  &__company-input-container {
    display: flex;

    div:first-of-type {
      margin-right: $SPACING_XXS;

      @media (max-width: $SCREEN_SIZE_S) {
        margin-right: 0;
      }
    }

    div:last-of-type {
      margin-left: $SPACING_XXS;

      @media (max-width: $SCREEN_SIZE_S) {
        margin-left: 0;
      }
    }

    > * {
      flex: 1;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      flex-direction: column;
      margin-top: $SPACING_XXS;
    }
  }

  &__submit {
    align-self: center;
    border: none;
    border-radius: $SPACING_3XS;
    border-radius: $BORDER_S;
    font-style: normal;
    font-weight: $MEDIUM;
    min-width: $FORM_MIN_WIDTH;
    padding: $SPACING_M;
  }

  &__subtitle {
    font-size: $TEXT_XL;
    font-weight: $MEDIUM;
    margin: 0;
    padding-top: $SPACING_XXL;
    text-align: left;

    @include themify($themes) {
      color: themed('colorText');
    }

    &--optional {
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
    }
  }

  &__reset {
    margin-top: $SPACING_L;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    margin-top: $SPACING_XXL;

    &--actions {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: $SPACING_XXL;

      .form__action {
        @include themify($themes) {
          cursor: themed('cursorPointerMegosu3');
        }

        display: flex;
        font-weight: $LIGHT;
        margin-top: $SPACING_XXS;

        &:hover {
          font-weight: $REGULAR;
        }

        &--link {
          color: $BLACK;
          font-size: $TEXT_L;
          font-weight: $LIGHT;
          margin-top: $SPACING_XXS;

          &:hover {
            font-weight: $REGULAR;
          }
        }

        &:first-child {
          margin-left: $SPACING_XL;
        }
      }
    }
  }

  &__boosted {
    background-color: $GRAY_LIGHTER;
    border-radius: $BORDER_S;
    color: $WHITE;
    font-size: $TEXT_XXS;
    font-weight: bold;
    margin-bottom: auto;
    margin-left: $SPACING_XXS;
    margin-top: 0.22rem;
    padding: $SPACING_3XS $SPACING_XXS;
    transition: $TRANSITION_MED;

    &:hover {
      background-color: $BLACK;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }
  }
}

.form-container {
  border-radius: $BORDER_S;
  box-shadow: $SHADOW_HEAVY;
  display: flex;
  flex-direction: column;
  padding: $SPACING_L $SPACING_XXL;
  text-align: center;
  width: 80%;

  @include themify($themes) {
    background-color: themed('colorBackground');
    color: themed('colorText');
  }

  @media (min-width: $SCREEN_SIZE_S) {
    max-width: $WIDTH_L;
    padding: $SPACING_XXL $SPACING_4XL;
  }
}

.form__settings-button {
  margin: $SPACING_M $SPACING_XXS $SPACING_XXS $SPACING_XXS;
  position: absolute;
}