@import "../../../styles/Styles";

.admin-university {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__container {
    width: $WIDTH_L;
  }


  &__input-container {
    display: flex;
    flex-direction: column;
    margin-top: $SPACING_M;
    max-width: $WIDTH_L;
    width: 100%;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: $SPACING_L;

    > button {
      font-size: $TEXT_L;
      padding: $SPACING_XS $SPACING_4XL $SPACING_XS $SPACING_4XL;
    }
  }
}