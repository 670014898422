@import "../../styles/Styles";

.profile {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 90vh;
  padding: 0 $SPACING_M $SPACING_M $SPACING_M;

  @media (min-width: $SCREEN_SIZE_S) {
    padding: 0 $SPACING_L $SPACING_L $SPACING_L;
  }

  @media (min-width: $SCREEN_SIZE_M) {
    flex-direction: row;
    padding: 0 $SPACING_XXL $SPACING_XXL $SPACING_XXL;
  }

  @media (min-width: $SCREEN_SIZE_L) {
    padding: 0 $SPACING_XXL $SPACING_XXL $SPACING_XXL;
  }

  @media (min-width: $SCREEN_SIZE_XL) {
    padding: 0 $SPACING_5XL $SPACING_4XL $SPACING_5XL;
  }

  @media (min-width: $SCREEN_SIZE_XXL) {
    margin: 0 auto;
    width: 74rem;
  }

  &__project-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $SPACING_XXL 0 0 0;
    width: 100%;

    @media (min-width: $SCREEN_SIZE_M) {
      padding: 1.8rem 0 1.875rem 2.5rem;
    }

    @media (min-width: $SCREEN_SIZE_XL) {
      padding: $SPACING_4XL 0 $SPACING_L $SPACING_4XL;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__btn-container {
    padding-bottom: 127%;
    position: relative;
  }

  &__upload-btn {
    align-items: center;
    background-color: $WHITE;
    border: dashed 1px $GRAY_LIGHT;
    border-radius: $BORDER_S;
    color: $GRAY_LIGHT;

    display: flex;
    flex-direction: column;
    font-weight: $LIGHT;
    height: calc(100% - 2.5rem);
    justify-content: center;
    left: 0;
    object-fit: cover;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu2');
    }

    p {
      font-size: $TEXT_XL;
      line-height: inherit;
      max-width: 8rem;
    }
  }

  &__upload-btn-icon {
    color: $GRAY_LIGHTEST;
    font-size: $TEXT_4XL;

    @media (max-width: $SCREEN_SIZE_M) {
      font-size: $TEXT_3XL;
    }
  }

  &__content-container {
    padding-bottom: $SPACING_XL;

    &:nth-child(2) {
      margin-top: $SPACING_L;
    }
  }

  &__chat-container {
    align-items: center;
    display: flex;
  }

  &__title-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: $SPACING_XXS 0 $SPACING_M 0;

    @media (max-width: $SCREEN_SIZE_L) {
      margin-right: 0;
    }

    @media (min-width: $SCREEN_SIZE_XL) {
      margin: 0 0 $SPACING_M 0;
    }

    .profile__chat-notification {
      align-self: center;
      background: $THEME_PINK;
      border-radius: 50%;
      box-sizing: border-box;
      height: 0.6rem;
      margin-left: 0.4rem;
      margin-top: $SPACING_XXS;
      width: 0.6rem;

      @media (min-width: $SCREEN_SIZE_S) {
        padding-bottom: $SPACING_3XS;
      }

      & + button:nth-last-child(1) {
        padding-left: 0.25rem;
      }
    }

    & > *:nth-child(4) {
      margin-left: auto;
    }
  }

  &__card-container {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(1, 1fr);
    height: 100%;

    @media (min-width: $SCREEN_SIZE_L) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $SCREEN_SIZE_XL) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__bookmark-button-container {
    margin: $SPACING_M auto 0 auto;
    text-align: center;
  }

  &__title {
    background: transparent;
    border: none;
    font-size: $TEXT_L;
    font-weight: $LIGHT;
    padding: $SPACING_3XS 0.7rem;

    @include themify($themes) {
      color: themed('colorText');
    }

    &--first-element {
      padding: $SPACING_3XS 0.7rem $SPACING_3XS 0;
    }
  }

  &__projects-tab {
    margin-left: $SPACING_XS;
    padding-left: 0;
  }

  @media only screen and (min-width: $SCREEN_SIZE_S) {
    &__title {
      background: transparent;
      border: none;
      font-size: 1.1rem;
      font-weight: $LIGHT;
      padding: $SPACING_3XS 0.7rem;

      &--first-element {
        padding: $SPACING_3XS 0.7rem $SPACING_3XS 0;
      }
    }
  }

  &__title--active {
    font-weight: $MEDIUM;
  }

  &__link,
  &__error-message {
    font-size: $TEXT_XL;
    font-weight: $REGULAR;
  }

  &__error-message {
    margin: $SPACING_XXL $SPACING_M 0 $SPACING_XXS;
  }


  &__spinner-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }
}