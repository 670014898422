@import "../../styles/Styles";

.files {
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  width: 100%;

  @include themify($themes) {
    color: themed('colorText');
  }

  &__content {
    margin: 0 auto;
    max-width: 75vw;
    padding: 0 $SPACING_L;
    width: 100%;
  }

  &__title-container {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $SPACING_4XL;
  }

  &__title {
    font-size: $TEXT_3XL;
    font-weight: $BOLD;
    text-align: left;
  }

  &__subtitle {
    font-size: $TEXT_XXL;
    font-weight: $BOLD;
    margin: 0;
    text-align: left;
  }

  &__intro {
    font-size: $TEXT_XL;
    font-weight: $LIGHT;
    text-align: left;
  }

  &__line-break {
    @media (max-width: $SCREEN_SIZE_S) {
      display: none;
    }
  }

  &__container {
    margin: 0 auto;
    margin-top: $SPACING_3XL;
    max-width: 75vw;
    padding: 0 $SPACING_L;
    width: 100%;

    &-grid {
      align-items: center;
      display: grid;
      grid-gap: 1.25rem;
      grid-template-columns: repeat(2, 1fr);

      @media (min-width: $SCREEN_SIZE_L) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (min-width: $SCREEN_SIZE_XL) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  &__categories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: $SPACING_L;
    position: sticky;
    top: 4rem;
    transition: all $TRANSITION_SHORT;
    width: 100%;
    z-index: 9999;

    &--backdrop {
      backdrop-filter: blur(10px);

      @include themify($themes) {
        background-color: themed('colorBackground');
      }
    }

    &-set {
      display: flex;
      flex-direction: column;
      padding: $SPACING_S 12vw;
    }

    &-labels-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &-label-container {
      margin-right: $SPACING_XXS;
    }

    &-label {
      align-items: center;
      display: flex;
      font-weight: $LIGHT;
      justify-content: center;
      padding: $SPACING_S $SPACING_M;
      position: relative;
      white-space: nowrap;

      @media (max-width: $SCREEN_SIZE_S) {
        font-size: $TEXT_XS;
        font-weight: $LIGHT;
        padding: $SPACING_XXS;
      }

      > span {
        pointer-events: none;
        z-index: 1;

        @include themify($themes) {
          color: themed('colorText');
        }
      }

      > input {
        appearance: none;
        height: 100%;
        margin: 0 auto;
        position: absolute;
        width: 100%;
        transition: all $TRANSITION_SHORT;

        @include themify($themes) {
          background-color: themed('colorBackground');
          border: solid 1px themed('colorText');
          cursor: themed('cursorPointerMegosu2');
        }

        &:hover {
          background-color: $GRAY_LIGHTER;
          border: solid 1px $GRAY_LIGHTER;
        }

        &:checked {
          border: solid 1px $BLACK;

          @include themify($themes) {
            background-color: themed('colorText');
          }

          & + .files__categories-label-name {
            @include themify($themes) {
              color: themed('colorBackground');
            }
          }

          &:hover {
            background-color: $GRAY_LIGHTER;
            border: solid 1px $BLACK;
          }
        }
      }
    }
  }
}