@import "../../styles/Styles";

.account-setup {
  background-attachment: fixed;
  background-image: url("../../assets/images/Luiza-Guidi.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-grow: 1;
  width: 100%;
}
