// Media Queries
$SCREEN_SIZE_XXS: 415px;
$SCREEN_SIZE_XS: 515px;
$SCREEN_SIZE_S: 768px;
$SCREEN_SIZE_M: 992px;
$SCREEN_SIZE_L: 1024px;
$SCREEN_SIZE_XL: 1200px;
$SCREEN_SIZE_XXL: 1440px;
$SCREEN_SIZE_3XL: 1600px;
$SCREEN_SIZE_4XL: 2160px;

$BORDER_RADIUS_SIZE: 0.2rem;

// Border Radius Sizes
$BORDER_S: 0.25rem;
$BORDER_M: 0.375rem;
$BORDER_L: 0.625rem;
$BORDER_XL: 1rem;

// Font sizes
$TEXT_XXS: 0.5rem;
$TEXT_XS: 0.625rem;
$TEXT_S: 0.8125rem;
$TEXT_M: 0.9375rem;
$TEXT_L: 1rem;
$TEXT_XL: 1.25rem;
$TEXT_XXL: 1.5rem;
$TEXT_3XL: 1.875rem;
$TEXT_4XL: 3.75rem;

// Width for pages & components
$WIDTH_S: 10rem;
$WIDTH_M: 20rem;
$WIDTH_L: 30rem;

// Width for forms
$FORM_MIN_WIDTH: 7.5rem;
$FORM_MAX_WIDTH: 30rem;