@import "../../styles/Styles";

.partners {
  margin: 0 auto;
  padding: 0 $SPACING_L;

  @media (min-width: $SCREEN_SIZE_M) {
    max-width: 49rem;
    padding-bottom: $SPACING_4XL;
  }

  &__title {
    font-weight: $MEDIUM;
    margin-bottom: $SPACING_M;
    padding-top: $SPACING_4XL;
  }

  &__subtitle {
    font-weight: $MEDIUM;
    margin: $SPACING_L 0 0 0;
  }

  &__feature-image {
    border-radius: $BORDER_S;
    pointer-events: none;
    position: relative;
    width: 100%;
  }

  &__description-text {
    font-weight: $LIGHT;
    margin-bottom: $SPACING_M;
    margin-top: $SPACING_XXS;
  }
}