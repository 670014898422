@import "../../Styles.scss";

.login-form {
  margin: $SPACING_L $SPACING_XXL $SPACING_XXL $SPACING_XXL;

  @media (max-width: $SCREEN_SIZE_L) {
    margin: $SPACING_XXL $SPACING_3XS;
  }

  &__2fa {
    &-form {
      display: block;
    }

    &-group {
      margin: 10px 0 0;
    }

    &-pincode {
      display: block;
      width: 100%;
      margin: 10px auto 20px;
      padding: 0;

      > label {
        display: block;
        text-align: center;
        margin: 10px 0;
      }

      > input[type="number"] {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
      }

      > input {
        display: inline-block;
        float: left;
        width: 15%;
        height: 50px;
        line-height: 48px;
        text-align: center;
        font-size: 2em;
        color: #181819;
        border: 0;
        border-bottom: 2px solid rgba(0, 0, 0, 0.3);
        border-radius: 2px 2px 0 0;
        transition: background-color 0.3s, color 0.3s, opacity 0.3s;
        cursor: default;
        user-select: none;
        margin: 0;
        margin-top: 10px;
        margin-right: 2%;
        padding: 0;
      }

      > input:focus {
        outline: 0;
        box-shadow: none;
        border-color: #1486f0;
        animation: border-pulsate 1.5s infinite;
        -webkit-tap-highlight-color: transparent;
      }

      > input:last-child {
        margin-right: 0;
      }

      > input:disabled {
        background: #eee;
        opacity: 1;
      }
    }

    &-buttons {
      > Button {
        margin-top: $SPACING_M;
        font-size: 1rem;
        padding: $SPACING_S $SPACING_XXL $SPACING_S $SPACING_XXL;
      }
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__input {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    margin-bottom: $SPACING_S;

    &:hover {
      background-color: $GRAY_LIGHTER;
      transition: all 0.2s;
    }
  }

  &__label-container {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    align-self: flex-start;
    font-size: $TEXT_M;
  }

  &__log-in-btn {
    align-self: center;
    color: $WHITE;
    font-size: $TEXT_XL;
    font-style: normal;
    font-weight: $MEDIUM;
    min-width: $FORM_MIN_WIDTH;
    padding: $SPACING_M;
  }

  &__button-container {
    flex-direction: column;
    margin: $SPACING_L 0 0 0;

    > Button {
      margin-bottom: $SPACING_XXL;
      margin-top: $SPACING_XS;
      padding: $SPACING_XS 5rem $SPACING_XS 5rem;
    }
  }

  &__reset-link {
    > a {
      color: $GRAY_LIGHT;
      float: right;
    }
  }

  &__social-login {
    border-bottom: 1px solid $GRAY_LIGHT;
    justify-content: center;
    line-height: 0.1em;
    text-align: center;
    width: 100%;

    > span {
      font-size: $TEXT_S;
      font-weight: normal;
      padding: 0 1.25rem;

      @include themify($themes) {
        background: themed('colorBackground');
        color: themed('textGray');
      }
    }
  }

  &__social-grid-container {
    display: grid;
    grid-template-columns: $SPACING_3XL $SPACING_3XL $SPACING_3XL;
    justify-content: center;
    margin-top: 1.2rem;

    > a {
      > img {
        height: 1.7rem;
        width: 1.7rem;
      }
    }
  }

  .input-error {
    border: 1px solid $RED;
  }

  .form-error {
    color: $RED;
    font-size: $TEXT_S;
  }

  .invisible {
    display: none;
  }

  .error-message {
    background-color: rgb(180, 54, 53);
    border-radius: $BORDER_RADIUS_SIZE;
    color: $WHITE;
    margin-bottom: $SPACING_L;
    margin-top: $SPACING_L;
    padding: $SPACING_3XS;

    p {
      font-size: $TEXT_M;
      line-height: $SPACING_M;
    }
  }
}