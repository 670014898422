@import "../../../styles/Styles";

.chat-overview {
  flex-grow: 1;
  max-width: 25rem;
  min-width: $WIDTH_M;

  @media (max-width: $SCREEN_SIZE_S) {
    max-width: 100vw;
    min-width: 100vw;
  }

  &__title {
    font-size: $TEXT_XXL;
    font-weight: $MEDIUM;
    margin-bottom: 1rem;
  }

  &__container {
    background-color: white;
    border-radius: $BORDER_S;
    margin: 0 1rem;
    max-height: 54rem;
    padding: $SPACING_XL;

    @include themify($themes) {
      background-color: themed('colorBackground');
      color: themed('colorText');
    }

    @media (max-width: $SCREEN_SIZE_M) {
      max-width: 15rem;
    }

    @media (max-width: $SCREEN_SIZE_M) {
      max-height: initial;
      max-width: 90vw;
      padding: $SPACING_XL $SPACING_M;
    }

    @media (max-height: $SCREEN_SIZE_S) {
      margin: 0.25rem;
      padding: 1.5rem;
    }
  }

  &__user-info-container {
    border-top: 1px solid $GRAY_LIGHTER;
    display: flex;
    flex-direction: row;
    min-width: 95%;
    padding: 1.25rem $SPACING_XXS;

    @media (max-width: $SCREEN_SIZE_M) {
      min-width: initial;
    }
  }

  &__user-name {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    font-weight: $REGULAR;
  }

  &__user-message {
    font-weight: 200;
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: $SCREEN_SIZE_L) {
      max-width: $WIDTH_S;
    }
  }

  &__user-message-thick {
    font-weight: $REGULAR;
  }

  &__user-text-container {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  &__chat-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 15rem;

    @media (max-width: $SCREEN_SIZE_S) {
      width: 100%;
    }
  }

  &__message-date {
    color: $GRAY_LIGHT;
    font-size: $TEXT_S;
    margin-right: $SPACING_XS;
  }

  &__chats {
    max-height: 41rem;
    overflow: scroll;

    @media (max-width: $SCREEN_SIZE_S) {
      max-height: initial;
    }

    > li {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      &:hover {
        @include themify($themes) {
          background-color: themed('navBarBackground');
        }
      }
    }
  }

  &__chats::-webkit-scrollbar {
    display: none;
  }

  .header__user-image-container {
    margin-right: $SPACING_M;
    margin-top: 0.1rem;
    max-height: 2.2rem;
    max-width: 2.2rem;
    min-height: 2.2rem;
    min-width: 2.2rem;
    position: relative;
  }

  .header__user-image-notification {
    background-color: $THEME_PINK;
    border: 2px solid $WHITE;
    border-radius: 50%;
    height: 0.6rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 0.6rem;
  }

  h1 {
    @media (max-width: $SCREEN_SIZE_S) {
      font-weight: $REGULAR;
      margin-bottom: $SPACING_S;
    }
  }
}