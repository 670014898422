@import "src/styles/Styles";

.subscription-testimonials {
  padding-bottom: $SPACING_5XL;
  text-align: center;

  @media (max-width: $SCREEN_SIZE_L) {
    padding-bottom: $SPACING_4XL;
  }

  & > div {
    display: grid;
    grid-gap: $SPACING_L;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: $SCREEN_SIZE_L) {
      display: block;
    }
  }

  &__review-title {
    font-size: $TEXT_3XL;
    padding-bottom: $SPACING_M;
  }

  &__review-card {
    background-color: $WHITE;
    border-radius: $BORDER_L;

    padding: $SPACING_L $SPACING_M $SPACING_L $SPACING_M;

    @include themify($themes) {
      box-shadow: themed('heavyShadow');
    }

    @include themify($themes) {
      background-color: themed('overlayColor');
    }

    @media (max-width: $SCREEN_SIZE_L) {
      margin: 0 auto;
      width: $WIDTH_M;

      &:not(:first-of-type) {
        margin-top: $SPACING_L;
      }
    }

    @media screen and (max-width: $SCREEN_SIZE_XS) {
      width: auto;
    }

    & > div {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  &__review-text {
    font-weight: $REGULAR;
  }

  &__review-image {
    border: 2px solid #292929;
    border-radius: 100%;
    height: 3.5rem;
    object-fit: cover;
    width: 3.5rem;
  }

  &__review-job-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: $SPACING_M;
  }

  &__review-name {
    font-size: $TEXT_M;
    font-weight: $REGULAR;
    padding-bottom: $SPACING_3XS;
  }

  &__review-job-title {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    line-height: $SPACING_M;
    margin: 0;
  }
}