@import "src/styles/Styles";

.image-uploader {
  background-color: $GRAY_LIGHTEST;
  border-radius: $BORDER_M;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  @include themify($themes) {
    box-shadow: themed('componentShadow');
  }

  &__form {
    display: flex;
    height: 100%;
    width: 100%;
  }

  > svg {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    margin-top: -$SPACING_XXS;
    position: absolute;
    right: -$SPACING_XXS;
  }

  &--simplified {
    position: relative;

    > label > div > div {
      display: flex;
      font-size: $TEXT_3XL;
    }
  }

  &__input {
    appearance: none;
    display: none;
  }

  &__input-container {
    align-items: center;
    color: $GRAY;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    padding: 27% 0;
    position: relative;
    width: 100%;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    &--active {
      .image-uploader__placeholder-container {
        color: $WHITE;

        > * {
          color: transparent;
        }

        > h6 {
          color: white;
        }
      }
    }
  }

  &__input-container-aspect-ratio {
    padding: 36.5% 0;
  }

  &__placeholder-container {
    z-index: 1;
  }

  &__img {
    border-radius: $BORDER_M;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__img-container {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &__circle-container {
    background: rgba(0, 0, 0, 0.5);
    border-radius: $BORDER_M;
    bottom: 0;

    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    width: 100%;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }
  }

  &__circle-container-show {
    -webkit-animation: fadeIn ease 0.9s;
    -moz-animation: fadeIn ease 0.9s;
    animation: fadeIn ease 0.9s;
    visibility: visible;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: $OPACITY_HIGH;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: $OPACITY_HIGH;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: $OPACITY_HIGH;
    }
  }

  &__circle-container-cursor {
    background: none;
    visibility: visible;
  }

  &__circle {
    height: 1.5rem;
    left: 45%;
    position: absolute;
    top: 45%;
    width: 1.5rem;
  }

  &__input-text {
    color: $GRAY;
    font-weight: $LIGHT;
  }

  &__img-placeholder {
    color: $GRAY_LIGHTER;
    font-size: $TEXT_3XL;
    font-weight: $LIGHT;
  }

  &__boosted-img {
    background-color: $GRAY_LIGHTER;
    border-radius: $BORDER_S;
    color: white;
    color: $WHITE;
    font-size: $TEXT_XS;
    font-weight: bold;
    left: 0.25rem;
    padding: $SPACING_3XS $SPACING_XXS;
    position: absolute;
    top: 0.3rem;
    transition: $TRANSITION_MED;
    z-index: 1;

    &:hover {
      background-color: $BLACK;

      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }
    }
  }

  &__button-container {
    display: flex;
    height: 50%;
    justify-content: space-between;
    margin-left: 25%;
    min-height: 2.1rem;
    width: 50%;

    @media (min-width: $SCREEN_SIZE_M) {
      flex-direction: column;
    }
  }

  &__save-button,
  &__reset-button {
    border: 1px solid black;
    border-radius: $BORDER_M;
    flex: 1;
    font-size: $TEXT_M;
    margin-left: $SPACING_3XS;
    margin-top: $SPACING_XS;
    min-width: 7rem;
    padding: $SPACING_3XS $SPACING_XXS;
  }

  &__save-button {
    background-color: $BLACK;
    color: $WHITE;
  }

  &__reset-button {
    background-color: $WHITE;
    color: $BLACK;
  }

  &__placeholder-cursor {
    cursor: move;
    z-index: 0;
  }

  .reactEasyCrop_Container {
    align-items: center;
    border-radius: 0.375rem;
    bottom: 0;
    cursor: move;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: relative;
    right: 0;
    top: 0;
    touch-action: none;
    user-select: none;
    width: 100%;

    &:hover + .controls {
      opacity: $OPACITY_HIGH;
      visibility: visible;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      position: static;
    }
  }

  .reactEasyCrop_Contain {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;

    @media (max-width: $SCREEN_SIZE_S) {
      position: static;
    }
  }

  .reactEasyCrop_Cover_Horizontal {
    height: auto;
    width: 100%;
  }

  .reactEasyCrop_Cover_Vertical {
    height: 100%;
    width: auto;
  }

  .reactEasyCrop_CropArea {
    border-radius: 0.375rem;
    box-sizing: border-box;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .reactEasyCrop_CropAreaRound {
    border-radius: 50%;
  }

  .controls {
    align-items: center;
    background: #fcfcfc;
    border-radius: 0.125rem;
    display: flex;
    left: 50%;
    opacity: 0;
    padding: 0 0.125rem;
    position: absolute;
    top: 90%;
    transform: translate(-50%, -50%);
    transition: visibility 0.3s linear, opacity 0.3s linear;
    visibility: hidden;

    &:hover {
      opacity: $OPACITY_HIGH;
      visibility: visible;
    }
  }

  .controls-start {
    opacity: $OPACITY_HIGH;
    visibility: visible;
  }

  .controls input[type="range"] {
    -webkit-appearance: none !important;
    background: $GRAY_LIGHTEST;
    border: none;
    font-size: 0.6rem;
    height: 0.25rem;
    outline: none;
    padding: 0 0;
    width: $WIDTH_S;

    @media (max-width: $SCREEN_SIZE_S) {
      width: 15rem;
    }
  }

  .controls input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    background: $BLACK;
    border-radius: 50%;

    height: 0;
    height: 0.7rem;
    padding: 0 0;
    width: 0.7rem;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }
  }

  .plus,
  .minus {
    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    font-size: $TEXT_XL;
    font-weight: $LIGHT;
    padding-bottom: 0.125rem;
    user-select: none;
  }
}