@import "../../styles/Styles";

.brands {
  margin: 0 auto;
  padding: 0 $SPACING_L;

  @include themify($themes) {
    color: themed('colorText');
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $SPACING_4XL;
    text-align: center;
  }

  &__title {
    font-size: $TEXT_3XL;
    margin-bottom: 0;
  }

  &__subtitle {
    font-size: $TEXT_L;
    font-weight: $LIGHT;

    > a {
      font-size: $TEXT_L;
    }
  }

  &__logos-intros {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $SPACING_XXS;
    justify-content: center;
    margin-top: $SPACING_M;
    max-width: 100%;
  }

  &__brand-image-container {
    > img {
      border: 1px solid $BLACK;
      border-radius: 100%;
      height: $SPACING_4XL;
      image-rendering: crisp-edges;
      margin: auto $SPACING_XS auto 0.75rem;
      object-fit: cover;
      position: relative;
      width: $SPACING_4XL;
    }
  }

  &__banners {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $SPACING_3XL;
    margin-top: $SPACING_4XL;
  }

  &__banner-image {
    border-radius: 5px;
    image-rendering: -webkit-optimize-contrast;
    max-width: 100rem;
    width: 80%;

    @media (max-width: $SCREEN_SIZE_XL) {
      object-fit: contain;
      width: 100%;
    }
  }

  &__call-to-action {
    align-items: center;
    color: $BLACK;
    display: flex;
    flex-direction: column;
    gap: $SPACING_3XL;
    margin-bottom: $SPACING_4XL;
    margin-top: $SPACING_4XL;
    max-width: 100rem;
    position: relative;

    &-image {
      border-radius: 5px;
      width: 80%;

      &--tablet {
        width: 100%;
      }
    }
  }

  &__image-overlay-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 8rem;
    margin-top: $SPACING_4XL;
    position: absolute;
    width: 80%;

    &--tablet {
      align-items: center;
      justify-content: center;
      margin-left: 0rem;
      text-align: center;
      width: 100%;
    }
  }

  &__image-overlay-title {
    &--tablet {
      font-size: $TEXT_XXL;
    }
  }

  &__pitch-button {
    font-size: $TEXT_L;
    max-width: 12rem;
    padding: $SPACING_XS $SPACING_3XL $SPACING_XS $SPACING_3XL;
  }
}