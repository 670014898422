@import "../../../styles/Styles";

.project-filter {
  border: 1px solid $GRAY_LIGHT;
  border-radius: $SPACING_XXS;
  display: flex;

  @media (max-width: $SCREEN_SIZE_S) {
    flex-direction: column;
  }

  &__dropdown {
    border-radius: $BORDER_M;
    box-shadow: none;
    margin-top: 1px;
    min-width: 8.8rem;
    opacity: 0;
    padding: $SPACING_S 0;
    position: absolute;
    transform: translateY(-10px);
    transition: all $TRANSITION_SHORT;
    visibility: hidden;
    width: 9rem;
    z-index: 1;

    @include themify($themes) {
      background-color: themed('overlayColor');
    }

    &--btn-dropdown {
      @include themify($themes) {
        background-color: themed('overlayColor');
      }

      border-radius: $BORDER_M;
      box-shadow: none;
      margin-top: 0;
      min-width: 9.375rem;
      padding: $SPACING_S;
      position: relative;
      transition: all $TRANSITION_MED;
      width: auto;
      z-index: 1;
    }

    @media (max-width: $SCREEN_SIZE_L) {
      box-shadow: none;
      margin-top: 0;
      opacity: $OPACITY_HIGH;
      position: relative;
      visibility: visible;
      width: auto;
    }
  }

  &__dropdown-tag {
    @include themify($themes) {
      color: themed('colorText');
    }

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    display: flex;
    flex-direction: row;
    font-size: $TEXT_S;
    justify-content: space-between;
    padding: $SPACING_S $SPACING_M $SPACING_XXS $SPACING_M;

    @media (min-width: $SCREEN_SIZE_XL) {
      padding: $SPACING_S $SPACING_L $SPACING_XXS $SPACING_L;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      margin: 0 0 $SPACING_S 0;
      padding: $SPACING_S $SPACING_M $SPACING_3XS $SPACING_M;
    }

    &:hover {
      .project-filter__dropdown-tag-count {
        font-weight: $REGULAR !important;
      }

      > div {
        font-weight: $REGULAR !important;
      }
    }
  }

  &__dropdown-tag-count {
    font-size: $TEXT_S;

    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &__label-list {
    @include themify($themes) {
      color: themed('colorText');
    }

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    display: block;
    font-size: $TEXT_S;
    font-weight: $MEDIUM;
    margin: 0;
    padding: $SPACING_S $SPACING_M;

    @media (min-width: $SCREEN_SIZE_XL) {
      padding: $SPACING_S 1.5rem;
    }

    @media (min-width: $SCREEN_SIZE_XXL) {
      padding: $SPACING_S $SPACING_L;
    }

    @media (max-width: $SCREEN_SIZE_L) {
      padding: $SPACING_S $SPACING_M;
    }
  }

  &__categories {
    border-bottom: 1px solid $GRAY_LIGHT;
    display: flex;
    justify-content: space-between;
    margin: 0 $SPACING_S;
    overflow-x: scroll;
  }

  &__dropdown-content {
    @include themify($themes) {
      background-color: themed('overlayColor');
    }

    border-radius: $BORDER_M;

    display: block;
    opacity: 0;
    padding: $SPACING_S 0 0 0;
    position: absolute;
    top: 2.375rem;
    transition: $TRANSITION_MED;
    visibility: hidden;
    width: 100%;
    z-index: 1;

    @include themify($themes) {
      box-shadow: themed('heavyShadow');
    }

    @media (max-width: $SCREEN_SIZE_S) {
      &--active {
        opacity: $OPACITY_HIGH;
        visibility: visible;
      }
    }

    @media (max-width: $SCREEN_SIZE_M) {
      width: 100%;
    }

    @media (min-width: $SCREEN_SIZE_L) {
      display: none;
    }
  }

  &__btn {
    align-items: center;
    border: 1px solid $GRAY_LIGHT;
    border-radius: $BORDER_M;

    display: none;
    font-size: $TEXT_S;
    font-weight: $MEDIUM;
    order: 5;
    padding: $SPACING_S;
    text-align: center;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    @include themify($themes) {
      background-color: themed('colorBackground');
      color: themed('colorText');
    }

    @media (max-width: $SCREEN_SIZE_L) {
      display: block;
      flex: auto;
      width: auto;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      &--disabled {
        opacity: 0.2;
      }
    }
  }

  &__list-item {
    &:hover .project-filter__dropdown {
      @include themify($themes) {
        box-shadow: themed('heavyShadow');
      }

      opacity: $OPACITY_HIGH;
      padding: $SPACING_S 0;
      transform: translateY(0px);
      visibility: visible;
    }
  }

  &__btn:hover ~ &__dropdown-content,
  &__dropdown-content:hover {
    @media (min-width: $SCREEN_SIZE_S) {
      @include themify($themes) {
        box-shadow: themed('heavyShadow');
      }

      opacity: $OPACITY_HIGH;
      transform: translateY(0px);
      visibility: visible;
    }
  }

  &__bsx-icon {
    transition: all 0.3s;

    &--reversed {
      @media (min-width: $SCREEN_SIZE_S) {
        transform: rotate(180deg);
      }
    }

    &--reversed-mobile {
      @media (max-width: $SCREEN_SIZE_S) {
        transform: rotate(180deg);
      }
    }
  }
}