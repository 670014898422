@import "../../styles/Styles";

.reset-password__container {
  background-attachment: fixed;
  background-image: url("../../assets/images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;

  .reset-password {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 4rem);
    justify-content: center;
    width: 100%;

    &__form-container {
      background-color: $WHITE;
      border-radius: 5px;

      padding: $SPACING_XXL $SPACING_4XL;
      text-align: center;
      width: $WIDTH_L;

      @include themify($themes) {
        box-shadow: themed('heavyShadow');
      }

      @media (max-width: $SCREEN_SIZE_S) {
        max-width: $WIDTH_M;
        padding: $SPACING_L $SPACING_XXL;
      }
    }

    &__title {
      color: $BLACK;
      font-size: $TEXT_3XL;
      font-weight: $MEDIUM;
      margin-bottom: $SPACING_M;
      margin-top: $SPACING_M;
    }

    &__input-container {
      display: flex;
      flex-direction: column;
      margin-bottom: $SPACING_M;
    }

    &__error-container {
      display: flex;
      justify-content: space-between;

      > span {
        color: $RED;
      }
    }

    &__label {
      text-align: left;
    }

    &__input {
      padding-left: $SPACING_S;

      &--error {
        border: 1px solid $RED;
      }
    }

    &__button-container {
      margin-top: $SPACING_L;
    }
  }
}