@import "src/styles/Styles";

.success-story {
  @include themify($themes) {
    color: themed('colorText');
  }

  > div {
    border-radius: $BORDER_S;
    margin-top: 5rem;

    @media (max-width: $SCREEN_SIZE_S) {
      margin-top: $SPACING_3XL;
    }
  }

  > div:first-of-type {
    margin-top: 0;
  }

  &__header-image {
    background-position: center center;
    background-size: cover;
    border-radius: $BORDER_S;
    display: flex;
    height: 25rem;
    justify-content: center;
  }

  &__header-caption {
    font-size: $TEXT_XS;
    font-weight: $LIGHT;
    line-height: $SPACING_M;
    margin-right: $SPACING_XXS;
    margin-top: $SPACING_XXS;
    text-align: right;
  }

  &__name--bold {
    display: inline;
    font-size: $TEXT_XS;
    font-weight: $REGULAR;
  }

  &__title {
    font-size: $TEXT_XXL;
    font-weight: $LIGHT;
    margin: 0 auto;
    margin-top: $SPACING_XXL;
    max-width: 27rem;
    text-align: center;

    &--left {
      text-align: left;
    }

    &__link {
      font-size: $TEXT_XXL;
      font-weight: $LIGHT;
      text-align: center;
    }
  }

  &__question {
    font-size: $TEXT_3XL;
    font-weight: $MEDIUM;
    margin: 0 auto;
    margin-top: $SPACING_XXL;
    max-width: 22rem;
    text-align: center;
  }

  &__info {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__header {
      align-items: center;
      display: flex;

      &__text {
        font-size: $TEXT_XXL;
        font-weight: $BOLD;
        margin: 0;
      }

      &__image {
        align-self: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        height: 1.35rem;
        width: 7.8rem;

        @include themify($themes) {
          filter: themed('invertLogoColor');
        }

        &__megosu-logo {
          margin-top: $SPACING_XS;
        }

        &__kartent-logo {
          height: 1.6rem;
        }
      }

      &__x {
        font-size: $TEXT_L;
        margin: 0 $SPACING_M;
        margin-top: $SPACING_XXS;
      }
    }

    &__text {
      display: inline;
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      margin-top: $SPACING_XXL;
      max-width: 36rem;
      text-align: justify;

      &--bold {
        display: inline;
        font-size: $TEXT_XL;
        font-weight: $REGULAR;
      }

      &--cursive {
        display: inline;
        font-size: $TEXT_XL;
        font-style: italic;
      }

      @media (max-width: $SCREEN_SIZE_S) {
        text-align: left;
      }
    }
  }

  &__small-images-container {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: $SCREEN_SIZE_XL) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__small-image-and-caption {
    margin: $SPACING_XS;

    @media (min-width: $SCREEN_SIZE_XS) {
      margin: $SPACING_M;
    }
  }

  &__media-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    width: auto;

    figcaption {
      font-size: $TEXT_XS;
      font-weight: $LIGHT;
      padding-top: $SPACING_XXS;
      text-align: right;
    }
  }

  &__media-wrapper {

    &--small,
    &--regular {
      aspect-ratio: 1;
      border-radius: $BORDER_S;
    }

    &--small {
      height: auto;
      max-width: 100%;
    }

    &--regular {
      width: 100%;

      @media (min-width: $SCREEN_SIZE_M) {
        max-height: 25rem;
        max-width: 25rem;
        min-height: 21rem;
        min-width: 21rem;
      }
    }
  }

  &__info-header-image-wrapper {
    display: flex;
    height: $SPACING_XL;
  }

  &__regular-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    &:nth-child(odd) {
      flex-direction: row;
    }

    @media (max-width: $SCREEN_SIZE_M) {
      flex-direction: column;

      &:nth-child(odd) {
        flex-direction: column;
      }
    }

    &__text {
      align-self: center;
      display: inline;
      font-size: $TEXT_XL;
      font-weight: $LIGHT;
      margin: $SPACING_M 0;
      margin-top: $SPACING_XXL;
      text-align: justify;
      width: 25rem;

      &__link,
      &--bold,
      &--cursive {
        display: inline;
        font-size: $TEXT_XL;
      }

      &--bold {
        font-weight: $REGULAR;
      }

      &--cursive {
        font-style: italic;
      }

      &--big {
        font-size: $TEXT_XXL;
        margin-top: 0;
        text-align: left;

        @media (max-width: $SCREEN_SIZE_M) {
          margin: 0;
        }
      }

      @media (max-width: $SCREEN_SIZE_S) {
        max-width: $WIDTH_L;
        text-align: left;
        width: initial;
      }

      @media (max-width: $SCREEN_SIZE_M) {
        margin-bottom: 0;
        margin-top: $SPACING_3XL;
        min-width: 17rem;
      }
    }
  }

  &__button-container {
    margin: 0 auto;
    margin-top: $SPACING_L;
    text-align: center;

    > button,
    .success-story__or {
      display: inline;
    }

    > p {
      font-size: $TEXT_L;
      font-weight: $LIGHT;
      margin: 0 $SPACING_S;
    }
  }

  &__inspirational-text {
    font-weight: $LIGHT;
    margin: 0 auto;
    margin-top: $SPACING_L;
    text-align: center;
  }

  &__more-stories {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    margin-bottom: $SPACING_3XL;
    max-width: 60%;

    @media (max-width: $SCREEN_SIZE_S) {
      max-width: 80%;
    }
  }

  &__more-stories-title {
    font-weight: $MEDIUM;
    margin: 0 auto;
    margin-bottom: $SPACING_M;
    text-align: center;
  }

  &__other-story {
    background-color: $GRAY_LIGHTEST;
    border-radius: $BORDER_S;
    box-shadow: $SHADOW_LIGHT;

    @include themify($themes) {
      cursor: themed('cursorPointerMegosu3');
    }

    figcaption {
      color: $BLACK;
      font-size: $TEXT_S;
      margin-bottom: $SPACING_S;
      margin-right: $SPACING_S;
    }
  }
}