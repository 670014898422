@import "../../styles/Styles";

.not-found {
  display: flex;
  height: 100vh;
  padding: 0 !important;
  width: 100%;

  @include themify($themes) {
    color: themed('colorText');
  }

  &__container {
    margin: auto;
  }
}