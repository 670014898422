@import "src/styles/Styles";

.edit-project {
  background-attachment: fixed;
  background-image: url("../../assets/images/Kevin-Rouff.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-grow: 1;
}

.edit-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $SPACING_XXL 0;
  width: 100%;

  &__title {
    font-size: $TEXT_3XL;
    font-weight: $MEDIUM;
  }

  .edit-form-container {
    background-color: $WHITE;
    border-radius: $BORDER_S;
    display: flex;
    flex-direction: column;
    padding: $SPACING_L $SPACING_XXL;
    text-align: left;
    width: 70%;

    @media (min-width: $SCREEN_SIZE_XS) {
      max-width: $WIDTH_L;
      padding: $SPACING_XXL $SPACING_4XL;
    }
  }
}