@import "../../Styles.scss";

.registration-form {
  display: flex;
  flex-direction: column;
  margin: $SPACING_L $SPACING_XXL $SPACING_XXL $SPACING_XXL;

  @media (max-width: $SCREEN_SIZE_L) {
    margin: $SPACING_XXL $SPACING_3XS;
  }

  &__name-container {
    display: flex;

    @media (max-width: $SCREEN_SIZE_L) {
      flex-direction: column;
    }
  }

  &__label-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__label {
    margin-bottom: 0.2rem;
    margin-left: 0.2rem;
    text-align: left;
  }

  &__error-container {
    display: flex;
    justify-content: space-between;
  }

  &__button-container {
    > Button {
      margin: 1rem 0rem 0.5rem 0rem;
      padding: 0.5rem 5rem 0.5rem 5rem;
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
  }

  &__input-field {
    align-items: center;
    display: flex;
    position: relative;

    & .login-form__input {
      flex-grow: 1;
    }

    &-icon {
      @include themify($themes) {
        cursor: themed('cursorPointerMegosu3');
      }

      fill: $GRAY_LIGHT;
      height: 1.5rem;
      margin-bottom: $SPACING_S;
      margin-right: $SPACING_XS;
      position: absolute;
      right: 0;
      width: 1.5rem;

      &--hide {
        display: none;
      }
    }
  }

  &__input {
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    padding-left: $SPACING_S;

    &:hover {
      background-color: $GRAY_LIGHTER;
      transition: all 0.2s;
    }
  }

  &__policy-link {
    &:first-of-type {
      margin-right: $SPACING_XXS;
    }

    &:last-of-type {
      margin-left: $SPACING_XXS;
    }
  }

  &__terms {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin: $SPACING_XXS $SPACING_XXS $SPACING_S 0;

    .checkbox-error {
      display: flex;
      text-align: left;

      .form-error {
        margin-bottom: $SPACING_3XS;
        margin-left: $SPACING_3XS;
        text-align: left;
      }
    }
  }

  &__terms-container {
    display: flex;
    text-align: left;
  }

  &__terms-label {
    margin-left: $SPACING_XXS;
  }

  &__terms-text {
    font-size: $TEXT_M;
    line-height: inherit;
    margin: 0;
  }

  &__social-login {
    border-bottom: 1px solid $GRAY_LIGHT;
    justify-content: center;
    line-height: 0.1em;
    margin-top: 1.5rem;
    text-align: center;
    width: 100%;

    > span {
      @include themify($themes) {
        background: themed('colorBackground');
        color: themed('textGray');
      }

      font-size: $TEXT_S;
      font-weight: normal;
      padding: 0 20px;
    }
  }

  &__social-grid-container {
    display: grid;
    grid-template-columns: $SPACING_3XL $SPACING_3XL $SPACING_3XL;
    justify-content: center;
    margin-top: $SPACING_XXS;

    > a {
      > img {
        height: 1.7rem;
        width: 1.7rem;
      }
    }
  }

  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-o-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .checkbox {
    padding-left: 0.25rem;
    padding-right: 1.2rem;
    padding-top: 0.2rem;
  }

  .input-error {
    border: 1px solid $RED;
    flex-grow: 1;
  }

  .form-error {
    color: $RED;
    font-size: $TEXT_S;
    padding-bottom: $SPACING_XXS;
    text-align: left;

    &--flicker {
      -webkit-animation: flickerAnimation 1s infinite;
      -moz-animation: flickerAnimation 1s infinite;
      -o-animation: flickerAnimation 1s infinite;
      animation: flickerAnimation 1s infinite;
      animation-iteration-count: 2;
    }
  }

  .invisible {
    display: none;
  }
}

.App {
  > div:nth-of-type(2) {
    @media (max-width: $SCREEN_SIZE_XXL) {
      padding-bottom: 0rem;
    }
  }
}