@font-face {
  font-family: "Biotif";
  font-style: normal;
  font-weight: 300;
  src: url(../../assets/fonts/Biotif/Biotif-Light.woff2) format("woff2"),
    url(../../assets/fonts/Biotif/Biotif-Light.woff) format("woff");
}

@font-face {
  font-family: "Biotif";
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/fonts/Biotif/Biotif-Regular.woff2) format("woff2"),
    url(../../assets/fonts/Biotif/Biotif-Regular.woff) format("woff");
}

@font-face {
  font-family: "Biotif";
  font-style: normal;
  font-weight: 500;
  src: url(../../assets/fonts/Biotif/Biotif-Medium.woff2) format("woff2"),
    url(../../assets/fonts/Biotif/Biotif-Medium.woff) format("woff");
}

@font-face {
  font-family: "Biotif";
  font-style: normal;
  font-weight: 700;
  src: url(../../assets/fonts/Biotif/Biotif-Bold.woff2) format("woff2"),
    url(../../assets/fonts/Biotif/Biotif-Bold.woff) format("woff");
}

// Font Weights
$LIGHT: 300;
$REGULAR: 400;
$MEDIUM: 500;
$BOLD: 600;
$BOLDER: 900;
