@import "src/styles/Styles";

.button {
  border-radius: $BORDER_S;

  font-size: $TEXT_M;
  padding: $SPACING_XXS $SPACING_S;

  @include themify($themes) {
    cursor: themed('cursorPointerMegosu3');
  }

  &--pink {
    background-color: $THEME_PINK;
    border: 1px solid $THEME_PINK;
    color: $WHITE;
    transition: all 0.3s;
  }

  &--pink:hover {
    opacity: 0.75;
  }

  &--pink-form {
    background-color: $THEME_PINK;
    border: 1px solid $THEME_PINK;
    color: $WHITE;
    font-size: $TEXT_L;
    font-weight: $REGULAR;
    margin: 0 $SPACING_XXS;
    min-width: 7.5rem;
    padding: $SPACING_S;
    transition: all 0.3s;
  }

  &--pink-form:hover {
    opacity: 0.75;
  }

  &--black {
    transition: all $TRANSITION_SHORT;

    @include themify($themes) {
      background-color: themed('colorText');
      border: 1px solid themed('colorText');
      color: themed('colorHeaderFooter');
    }
  }

  &--black:hover {
    background-color: $WHITE;
    color: $BLACK;

    @include themify($themes) {
      background-color: themed('colorHeaderFooter');
      border: 1px solid themed('colorText');
      color: themed('colorText');
    }
  }

  &--black-form {
    font-size: $TEXT_L;
    font-weight: $REGULAR;
    margin: 0 $SPACING_XXS;
    min-width: 7.5rem;
    padding: $SPACING_S;
    transition: all $TRANSITION_SHORT;

    @include themify($themes) {
      background-color: themed('colorText');
      border: 1px solid themed('colorText');
      color: themed('colorHeaderFooter');
    }

    &:hover {
      transition: all $TRANSITION_SHORT;

      @include themify($themes) {
        background-color: themed('colorHeaderFooter');
        border: 1px solid themed('colorText');
        color: themed('colorText');
      }
    }
  }

  &--grey {
    align-items: center;
    background-color: $GRAY_LIGHTEST;
    border: 1px solid $GRAY_LIGHTEST;
    border-radius: $BORDER_M;
    color: $WHITE;
    display: flex;
    padding: $SPACING_S;
  }

  &--white {
    background-color: $WHITE;
    border: 1px solid $WHITE;
    transition: all 0.3s;

    @include themify($themes) {
      background-color: themed('colorHeaderFooter');
      border: 1px solid themed('colorText');
      color: themed('colorText');
    }
  }

  &--white:hover {
    background-color: $BLACK;
    color: $WHITE;

    @include themify($themes) {
      background-color: themed('colorText');
      border: 1px solid themed('colorText');
      color: themed('colorHeaderFooter');
    }
  }

  &--white-form {
    font-size: $TEXT_L;
    font-weight: $REGULAR;
    margin: 0 $SPACING_XXS;
    min-width: 7.5rem;
    padding: $SPACING_S;
    transition: all $TRANSITION_SHORT;

    @include themify($themes) {
      background-color: themed('colorHeaderFooter');
      border: 1px solid themed('colorText');
      color: themed('colorText');
    }

    &:hover {
      transition: all $TRANSITION_SHORT;

      @include themify($themes) {
        background-color: themed('colorText');
        border: 1px solid themed('colorText');
        color: themed('colorHeaderFooter');
      }
    }
  }

  &--white-pitch-header {
    background-color: $WHITE;

    transition: all 0.3s;
    width: 4.6rem;

    @include themify($themes) {
      border: 1px solid themed('colorText');
      color: themed('colorText');
    }
  }

  &--white-pitch-header:hover {
    background-color: $BLACK;
    color: $WHITE;
  }

  &--white-outline {
    background-color: $WHITE;
    border: 1px solid $BLACK;
  }

  &--white {
    background-color: transparent;
    border: 1px solid $BLACK;
    color: $BLACK;
  }

  &--inactive {
    border-color: $GRAY_LIGHT;
    color: $GRAY_LIGHT;
  }

  &--navbar-top {
    margin-bottom: $SPACING_S;
    width: 13rem;

    @media (max-width: $SCREEN_SIZE_S) {
      width: 100%;
    }
  }

  &--navbar-bottom {
    margin-bottom: 1.3rem;
    width: 13rem;

    @media (max-width: $SCREEN_SIZE_S) {
      width: 100%;
    }
  }
}