@import "src/styles/Styles";

.how-it-works {
  display: flex;
  flex-direction: column;
  margin: 0 12rem;

  @media (min-width: $SCREEN_SIZE_S) {
    align-self: center;
  }

  @media (min-width: $SCREEN_SIZE_XL) {
    max-width: 68rem;
  }

  @media (max-width: $SCREEN_SIZE_XL) {
    margin: 0 $SPACING_L;
  }

  > div {
    border-radius: $BORDER_S;
    margin-top: $SPACING_3XL;
  }

  > div:first-of-type {
    border-radius: $BORDER_S;
    margin-top: $SPACING_3XL;

    @media (max-width: $SCREEN_SIZE_M) {
      margin-top: 1.875rem;
    }
  }

  > div:nth-child(2) {
    margin-top: $SPACING_S;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__header-image {
    background-position: center center;
    background-size: cover;
    display: flex;
    height: 25rem;
    justify-content: center;

    @media (max-width: $SCREEN_SIZE_S) {
      background-position: left 454px top;
    }
  }

  &__business-header-image {
    height: 35rem;

    .button--black {
      margin-top: 19rem;
    }
  }

  &__business-navigation {
    align-items: flex-end;
    display: flex;
  }

  &__title {
    color: white;
    font-size: $TEXT_3XL;
    font-weight: $MEDIUM;
    margin-top: 9rem;
    position: absolute;
    text-align: center;

    @media (max-width: $SCREEN_SIZE_S) {
      margin-top: 4rem;
    }
  }

  &__business-title {
    line-height: 2.25rem;
    margin-top: 11rem;
    width: $WIDTH_M;
  }

  &__arrow-icon {
    height: 1rem;
    margin: auto 0;
    position: relative;
    width: 1rem;
  }

  &__navigation {
    color: white;
    justify-content: center;
    margin-top: 4rem;

    svg {
      height: 0.85rem;
      margin: auto 0;
      position: relative;
      width: 0.85rem;
    }

    @media (max-width: $SCREEN_SIZE_S) {
      margin-top: 0;
    }
  }

  &__navigation-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__item {
    color: white;
    font-weight: $MEDIUM;
    margin: 0.25rem;
    padding: 0;
  }

  &__for {
    height: 15rem;
    padding-top: $SPACING_XL;
    text-align: center;

    @media (max-width: $SCREEN_SIZE_S) {
      height: initial;
    }
  }

  &__for-title {
    margin-bottom: 0;
    margin-right: 25.75rem;

    @media (max-width: $SCREEN_SIZE_S) {
      margin-bottom: $SPACING_XS;
      margin-right: initial;
      margin-top: $SPACING_XL;
    }
  }

  &__for-brands {
    color: white;
    margin: auto 0.25rem;
    margin-bottom: 1rem;
  }

  &__brand-arrow {
    margin: auto 0rem;
    margin-bottom: 1.3rem;
    transform: rotate(90deg);
  }

  &__steps {
    justify-content: center;
    text-align: center;

    @media (max-width: $SCREEN_SIZE_S) {
      flex-direction: column;
    }
  }

  &__step-container {
    margin: $SPACING_XS;

    @media (min-width: $SCREEN_SIZE_XS) {
      margin: 1rem;
    }
  }

  &__step {
    font-weight: $BOLDER;
    margin: 0.8rem 0;
    text-align: left;

    @media (max-width: $SCREEN_SIZE_S) {
      text-align: center;
    }
  }

  &__step-align {
    margin: 0 4rem;
  }

  &__step-align > a {
    color: black;
  }

  &__product-text,
  &__market-step {
    font-weight: $LIGHT;
    line-height: 1.4rem;
    margin: 0;
  }

  &__inline-bold {
    display: inline;
    font-weight: $REGULAR;
  }

  &__image-text {
    align-self: flex-end;
    font-size: $TEXT_XS;
    font-weight: $LIGHT;
    line-height: 1rem;
    text-align: right;
  }

  &__moooi-text {
    width: 6rem;
  }

  &__kiddo-text {
    width: 11rem;
  }

  &__image-step-container {
    &-steps {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    @media (max-width: $SCREEN_SIZE_XL) {
      flex-direction: column;
    }
  }

  &__image-title {
    font-size: $TEXT_L;
    font-weight: $BOLD;
    margin-bottom: $SPACING_XS;
    min-width: $WIDTH_S;

    @media (min-width: $SCREEN_SIZE_S) and (max-width: $SCREEN_SIZE_M) {
      font-size: $TEXT_XL;
    }

    @media (max-height: 600px) {
      min-width: 5rem;
    }
  }

  &__step-image-text {
    align-self: flex-end;
    font-size: $TEXT_S;
    font-weight: $LIGHT;
    line-height: 1.25rem;
    text-align: right;
    width: 11rem;

    @media (max-width: $SCREEN_SIZE_S) {
      font-size: $TEXT_XS;
      text-align: initial;
      width: 9rem;
    }

    @media (min-width: $SCREEN_SIZE_M) {
      width: 13rem;
    }
  }

  &__step-title {
    font-weight: $BOLD;
    margin-top: 1rem;
  }

  &__step-text {
    font-weight: $LIGHT;
  }

  &__button-container {
    display: flex;
    margin-top: $SPACING_XL;

    > .how-it-works__link {
      align-self: center;
    }

    .button {
      width: 9rem;
    }

    &-step-2 {
      .button {
        width: $WIDTH_S;
      }
    }
  }

  &__button-text {
    align-self: center;
    font-size: $TEXT_M;
    font-weight: $LIGHT;
    margin: 0 1rem;
  }

  &__step-2-text,
  &__step-3-text,
  &__step-4-text {
    margin: 1rem 0;
    width: 25rem;

    @media (max-width: $SCREEN_SIZE_S) {
      max-width: $WIDTH_L;
      width: initial;
    }

    @media (max-width: $SCREEN_SIZE_M) {
      min-width: 17rem;
    }
  }

  &__first-step,
  &__step-3 {
    @media (max-width: $SCREEN_SIZE_M) {
      flex-direction: column;
    }
  }

  &__step-2,
  &__step-4 {
    @media (max-width: $SCREEN_SIZE_M) {
      flex-direction: column-reverse;
    }
  }

  &__first-step {
    &-text {
      align-self: center;
      margin-top: 0;
      width: 25rem;

      @media (max-width: $SCREEN_SIZE_M) {
        max-width: $WIDTH_L;
        min-width: 17rem;
        width: initial;
      }
    }

    &-business {
      .button--white {
        margin-top: $SPACING_XL;
      }
    }
  }

  &__step-2 {
    &-text {
      display: flex;
      flex-direction: column;
      margin-top: $SPACING_3XL;
    }

    &-designer-title {
      font-weight: $LIGHT;
      width: 12rem;

      @media (max-width: $SCREEN_SIZE_M) {
        margin-right: 12.5rem;
      }

      @media (max-width: $SCREEN_SIZE_S) {
        margin-right: 8rem;
      }
    }

    &-business-title {
      font-weight: $LIGHT;
      margin-bottom: 0;
      margin-top: 0;

      @media (max-width: $SCREEN_SIZE_S) {
        align-self: center;
        margin-bottom: 1rem;
        margin-right: 10rem;
        margin-top: initial;
      }
    }
  }

  &__step-3 {
    &-image-text {
      width: $WIDTH_S;
    }

    &-text {
      margin-top: 5rem;
    }

    .button--white {
      margin-top: $SPACING_XS;
    }
  }

  &__step-4 {
    &-text {
      align-self: center;
    }

    &-image-text {
      width: 7rem;
    }

    &-business-text {
      width: 9rem;
    }

    .button--white {
      margin-top: $SPACING_XS;
    }
  }

  &__image-container {
    @media (max-width: $SCREEN_SIZE_S) {
      margin: 0 auto;
    }
  }

  &__pick-a-plan {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: $SPACING_3XL;
    margin-bottom: $SPACING_3XL;
    max-width: 85rem;

    &-title {
      font-size: $TEXT_3XL;
      font-style: normal;
      font-weight: $BOLD;
      margin-bottom: 0;
      padding-top: $SPACING_4XL;
      text-align: center;

      @media (max-width: $SCREEN_SIZE_L) {
        padding-top: $SPACING_4XL;
      }
    }
  }

  &__small {
    @media (min-width: $SCREEN_SIZE_M) {
      width: 7rem;
    }
  }

  &__feature-image-container,
  &__step-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    width: auto;

    @media (min-width: $SCREEN_SIZE_M) {
      margin: 1rem;
    }

    figcaption {
      font-size: $TEXT_XS;
      font-weight: $LIGHT;
      padding-top: $SPACING_XXS;
      text-align: right;
    }
  }

  &__feature-image-wrapper {
    align-self: center;
    position: relative;
    top: 0;

    img {
      aspect-ratio: 1;
      border-radius: $BORDER_S;
      width: 100%;

      @media (min-width: $SCREEN_SIZE_M) {
        max-height: 25rem;
        max-width: 25rem;
        min-height: 21rem;
        min-width: 21rem;
      }
    }
  }

  &__step-image-wrapper {
    img {
      aspect-ratio: 1;
      border-radius: $BORDER_S;
      height: auto;
      max-width: 100%;
    }
  }

  .subscription-form {
    align-self: center;
    max-width: 75rem;

    h6 {
      padding-top: 1rem;
    }
  }
}